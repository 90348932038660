import * as reviewservice from '@/api/reviewservice';
import { Review, ReviewProgress } from '@/models/Review';
import { RootState } from '@/store';
import { Module } from 'vuex';
import { Publication } from '@/models/Details';

// State
export interface ReviewState {
  review: Review;
  progress: ReviewProgress;
  publications: Publication[];
  selectedPubId: number;
  error?: string;
}

enum ReviewAction {
  FETCH_REVIEW = 'FETCH_REVIEW',
  RESET_REVIEW = 'SET_REVIEW',
  UPDATE_REVIEW = 'UPDATE_REVIEW',
  CREATE_REVIEW = 'CREATE_REVIEW',
  DELETE_REVIEW = 'DELETE_REVIEW',
  GET_ALGORITHMS = 'GET_ALGORITHMS',
  SET_ALGOTIHMS = 'SET_ALGOTIHMS',

  // GET_ENTRIES = 'GET_ENTRIES', // GET
  // ADD_ENTRY = 'ADD_ENTRY', // POST
  // GET_ENTRY = 'GET_ENTRY', // GET
  // DELETE_ENTRY = 'DELETE_ENTRY' // DELETE
  // SEARCH_ENTRIES = 'SEARCH_ENTRIES', // GET
  LABEL_ENTRY = 'LABEL_ENTRY',
  GET_LEARNINGSTATE = 'GET_LEARNINGSTATE',
  START_LERNING = 'START_LERNING',
  FINISH_LERNING = 'FINISH_LERNING',
  GET_STATISTICS = 'GET_STATISTICS',
  SET_ERROR = 'SET_ERROR',
  FETCH_PROGRESS = 'FETCH_PROGRESS',
}

export const review: Module<ReviewState, RootState> = {
  namespaced: true,
  state: {
    review: <Review>{
      id: 'id',
      date: 'unknown',
      creatorId: 0,
      creatorName: 'unknown',
      name: 'unknown2',
      contributors: [],
    },
    progress: <ReviewProgress>{
      n_excluded: 0,
      n_included: 0,
      n_papers: 0,
      n_pool: 0,
      n_since_last_inclusion: 0,
    },
    publications: [],
    selectedPubId: -1,
    error: undefined,
  },
  getters: {
    currentreview(state: ReviewState): Review {
      return state.review;
    },
    name(state: ReviewState): string {
      return state.review ? state.review.name : 'review is undefined';
    },
    publications(state: ReviewState): Publication[] {
      return state.publications;
    },
    selectedPubId(state: ReviewState): number {
      return state.selectedPubId;
    },
  },
  actions: {
    async fetch({ commit }, { getReview, projectId }: { getReview: boolean; projectId: string }) {
      // commit(ReviewAction.RESET_REVIEW);
      try {
        if (getReview) {
          const project = await reviewservice.getReview(projectId);
          commit(ReviewAction.FETCH_REVIEW, project);
        } else {
          const progress = await reviewservice.getProgress(projectId);
          commit(ReviewAction.FETCH_PROGRESS, progress);
        }
      } catch (e) {
        commit(ReviewAction.SET_ERROR, 'Error while fetching review!');
      }
    },
    async create({ commit }, { title, description }: { title: string; description: string }) {
      try {
        // TODO get user name and set as authors name
        const projectId = await reviewservice.createReview(title, 'Me', description);
        commit(ReviewAction.CREATE_REVIEW, projectId);
      } catch (e) {
        commit(ReviewAction.SET_ERROR, 'Error while fetching review!');
      }
    },
  },
  mutations: {
    [ReviewAction.RESET_REVIEW](state: ReviewState) {
      state.error = undefined;
      state.review.id = 'undefined';
    },
    [ReviewAction.FETCH_REVIEW](state: ReviewState, payload?: Review) {
      state.error = undefined;
      state.review = payload as Review;
    },
    [ReviewAction.FETCH_PROGRESS](state: ReviewState, payload?: ReviewProgress) {
      state.error = undefined;
      state.progress = payload as ReviewProgress;
    },
    [ReviewAction.UPDATE_REVIEW](state: ReviewState) {
      state.error = undefined;
    },
    [ReviewAction.GET_ALGORITHMS](state: ReviewState) {
      state.error = undefined;
    },
    [ReviewAction.CREATE_REVIEW](state: ReviewState, payload: Review) {
      state.error = undefined;
      state.review = payload;
    },
    [ReviewAction.SET_ERROR](state: ReviewState, payload: string) {
      state.error = payload;
    },
  },
};
