<template>
  <div class="h-full w-full">
    <AppBar :query="query" class="absolute top-0 w-screen h-16" />
    <div class="absolute top-16 w-full h-full calculated-height" ref="body">
      <div class="absolute w-3/4 left-0 h-full inline-block">
        <slot name="graph" />
      </div>
      <div class="absolute w-1/4 right-0 border-l border-grey bg-white h-full inline-block">
        <slot name="results" />
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, ref, toRefs, watch } from 'vue';
import AppBar from '@/components/Skeleton/AppBar.vue';
import AppFooter from '@/components/Skeleton/AppFooter.vue';
import { PluginApi as Loading } from 'vue-loading-overlay';

export default defineComponent({
  name: 'AppSkeletonSearch',
  components: {
    AppBar,
    AppFooter,
  },
  props: {
    query: {
      type: String,
      value: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { loading } = toRefs(props);
    const body = ref<HTMLElement | null>(null);

    const app = getCurrentInstance();
    const vloading = app?.appContext.config.globalProperties.$loading as Loading;
    const loader = ref<null | { hide: () => void }>(null);

    watch([loading, body], ([newLoading, newBody]) => {
      if (newLoading && newBody) {
        loader.value = vloading.show({ isFullPage: true, container: newBody, zIndex: 10 });
      } else {
        loader.value?.hide();
      }
    });

    return { body };
  },
});
</script>

<style scoped>
.calculated-height {
  height: calc(theme('height.full') - theme('height.16') - theme('height.10'));
}
</style>
