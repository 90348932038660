
import { defineComponent } from 'vue';
import { Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue';
import { ExclamationIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'ErrorModal',
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    ExclamationIcon,
  },
  props: {
    open: Boolean,
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
    },
    button: {
      type: String,
      required: false,
    },
  },
  emits: ['update:open'],
});
