
import { AnalysisResponse, ClusterMapping, SearchResponse } from '@/models/Search';
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeUpdate,
  PropType,
  Ref,
  ref,
  toRefs,
  watch,
} from 'vue';
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
} from '@headlessui/vue';
import { ChevronDownIcon, CheckIcon } from '@heroicons/vue/outline';
import { getClusterColors } from './utils/graphConfiguration';
import { PluginApi as Loading } from 'vue-loading-overlay';

export default defineComponent({
  name: 'NodeSelectionDialog',
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    ChevronDownIcon,
    CheckIcon,
  },
  props: {
    entities: {
      type: Object as PropType<SearchResponse[]>,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    analysis: {
      type: Object as PropType<AnalysisResponse>,
      required: false,
    },
    clusters: {
      type: Object as PropType<ClusterMapping>,
      required: false,
    },
  },
  emits: ['update:isOpen', 'select'],
  setup(props, { emit }) {
    const { isOpen, clusters, entities, analysis } = toRefs(props);

    const dialogContainer = ref<HTMLDivElement | null>(null);
    const listContainer = ref<HTMLDivElement | null>(null);

    const app = getCurrentInstance();
    const loading = app?.appContext.config.globalProperties.$loading as Loading;

    const enrichedEntitiesLoader = ref(false);
    const enrichedEntities = computed(() => {
      const loader = listContainer.value
        ? loading.show({
            isFullPage: false,
            container: listContainer.value,
          })
        : undefined;
      try {
        const result = entities.value.reduce((prev, cur) => {
          if (cur) {
            const { entity } = cur;
            const entityAnalysis = analysis.value?.entities.find(
              (e) => parseInt(e.id) === entity.id
            );

            return prev.concat([
              {
                ...cur,
                cluster: entityAnalysis?.properties.communityId,
              },
            ]);
          }
          return prev;
        }, [] as (SearchResponse & { cluster?: number })[]);
        loader?.hide();
        return result;
      } catch (e) {
        console.error(e);
        return [];
      }
    });

    const freeTextFilter = ref('');
    const filteredEntities = computed(() =>
      freeTextFilter.value.length === 0
        ? enrichedEntities.value
        : enrichedEntities.value.filter(({ entity }) => {
            const regex = RegExp(freeTextFilter.value, 'i');
            return (
              entity.title.match(regex) ||
              entity.year.toString().match(regex) ||
              entity.publisher?.match(regex) ||
              entity.authors.find((author) => author.name.match(regex))
            );
          })
    );

    const checkboxRefs = ref<{ [id: string]: Ref<boolean> }>({});

    const sortedClusters = computed(() =>
      Object.entries(clusters.value || {}).sort(
        (clusterA, clusterB) => clusterB[1].size - clusterA[1].size
      )
    );

    watch(
      () => entities.value,
      (newEntities) => {
        checkboxRefs.value = {};
        newEntities.forEach((e) => (checkboxRefs.value[e.entity.id] = false));
      }
    );

    onBeforeUpdate(() => {
      checkboxRefs.value = {};
      entities.value.forEach((e) => (checkboxRefs.value[e.entity.id] = false));
      freeTextFilter.value = '';
    });

    const setIsOpen = (confirm: boolean) => {
      emit('update:isOpen', !isOpen.value);
      if (confirm) {
        const selectedEntities = Object.keys(checkboxRefs.value)
          .filter((id) => checkboxRefs.value[id])
          .map((id) => parseInt(id));

        emit('select', selectedEntities);
      }
    };

    const selectAll = () =>
      Object.keys(checkboxRefs.value).forEach((entityId) => (checkboxRefs.value[entityId] = true));
    const deselectAll = () =>
      Object.keys(checkboxRefs.value).forEach((entityId) => (checkboxRefs.value[entityId] = false));

    const isClusterSelected = computed(() => {
      const result = entities.value.reduce((prev, { entity }) => {
        const entityAnalysis = analysis.value?.entities.find((e) => parseInt(e.id) === entity.id);
        const checkbox = checkboxRefs.value[entity.id.toString()];
        const clusterId = clusters.value?.[entityAnalysis?.properties.communityId ?? -1]
          ? entityAnalysis?.properties.communityId ?? -1
          : -1;

        if (!prev[clusterId]) prev[clusterId] = true;
        if (!checkbox) prev[clusterId] = false;
        return prev;
      }, {} as { [key: string]: boolean });
      return result;
    });

    const selectCluster = (id: string) => {
      const isSelected = isClusterSelected.value[id];

      Object.keys(checkboxRefs.value).forEach((entityId) => {
        const entityAnalysis = analysis.value?.entities.find((e) => {
          return parseInt(e.id) === parseInt(entityId);
        });

        const clusterId = clusters.value?.[entityAnalysis?.properties.communityId ?? -1]
          ? entityAnalysis?.properties.communityId ?? -1
          : -1;
        if (parseInt(id) === clusterId) {
          checkboxRefs.value[entityId] = !isSelected;
        }
      });
    };

    const clusterColors = computed(() =>
      getClusterColors(analysis.value?.analysisType, clusters.value)
    );

    return {
      setIsOpen,
      selectAll,
      deselectAll,
      checkboxRefs,
      sortedClusters,
      isClusterSelected,
      selectCluster,
      freeTextFilter,
      filteredEntities,
      clusterColors,
      enrichedEntitiesLoader,
      dialogContainer,
      listContainer,
    };
  },
});
