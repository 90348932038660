
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'CollectionList',
  setup() {
    const collections = ref([
      { id: 0, title: 'Collection0', content: [{ id: 3006354146, title: 'Publication' }] },
      { id: 1, title: 'Collection1', content: [{ id: 3006354146, title: 'Publication' }] },
      { id: 2, title: 'Collection2', content: [{ id: 3006354146, title: 'Publication' }] },
    ]);
    return { collections };
  },
});
