
import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/vue/solid';
import OpenDoi from '@/components/Buttons/OpenDoi.vue';
import CitationPopUp from '@/components/PopUps/CitationPopUp.vue';
import Cite from '@/components/Buttons/Cite.vue';
import { SearchResponse } from '@/models/Search';
import { Publication } from '@/models/Details';
import PublicationList from '@/components/Details/PublicationList.vue';
import Skeleton from 'primevue/skeleton';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'PublicationPage',
  components: {
    OpenDoi,
    Cite,
    CitationPopUp,
    PublicationList,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    Skeleton,
  },
  props: {
    searchres: {
      type: Object as PropType<SearchResponse>,
      required: false,
    },
    id: {
      type: Number,
      required: false, // if id exists, the fetch will fetch the entire publication
    },
  },
  setup(props, { emit }) {
    // constants reactive to consider a possible change of the displayed publication
    const statisticsVisible = ref(false);
    const citationPopUpVisible = ref(false);
    const publication = ref<Publication>();
    const loading = ref(false);
    const isAbstractReading: Ref<boolean> = ref(false);
    const store = useStore();
    const abstractReading = computed(() => store.getters['auth/isAbstractReading']);
    watch(abstractReading, (abstractReading) => {
      isAbstractReading.value = abstractReading;
    });
    // fetches a publication either by using the prop SearchResult (selecting element of the ResultList) or by id (selecting an element of the PublicationLists)
    // TODO: fetch publication using existing axios
    const fetchPublication = function (id?: number) {
      loading.value = true;
      if (id || props.id) {
        let reqId: number;
        if (id) reqId = id;
        else if (props.id) reqId = props.id;
        else {
          emit('fetchFailed');
          return;
        }
        emit('loading');
        fetch('https://api.ambalytics.cloud/entities', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Content-Length': '142' },
          body: `{"query":"query getPublication($id: ID!) { publication(id: $id) {id, type, doi, abstract, pubDate, publisher, citationCount, title, normalizedTitle, year, citations{ id, title}, refs{id, title}, authors{id, name}, fieldsOfStudy{id, name }}}" ,"variables":{"id":"${reqId}"}}`,
        })
          .then((res) => res.json())
          .then((res) => {
            if (!res.data.publication) return; //TODO: reaction to publication = null
            publication.value = { ...res.data.publication };
            loading.value = false;
            emit('stopLoading');
          })
          .catch((reason) => {
            emit('fetchFailed');
            console.error('fetch failed because ' + reason);
          });
      } else {
        emit('loading');
        if (!props.searchres) {
          emit('fetchFailed');
          return;
        }
        const searchres: SearchResponse = props.searchres;
        fetch('https://api.ambalytics.cloud/entities', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Content-Length': '142' },
          body: `{"query":"query getPublication($id: ID!) { publication(id: $id) {citations{ id, title}, rank, fieldsOfStudy{id, name }, refs{id, title}}}" ,"variables":{"id":"${searchres.entity.id}"}}`,
        })
          .then((res) => res.json())
          .then((res) => {
            if (!res.data.publication) return; //TODO: reaction to publication = null

            publication.value = {
              id: searchres.entity.id,
              type: searchres.entity.type,
              doi: searchres.entity.doi,
              abstract: searchres.entity.abstract,
              pubDate: searchres.entity.pubDate,
              publisher: searchres.entity.publisher,
              citationCount: searchres.entity.citationCount,
              title: searchres.entity.title,
              normalizedTitle: searchres.entity.normalizedTitle,
              authors: searchres.entity.authors,
              year: -1,
              citations: res.data.publication.citations,
              refs: res.data.publication.refs,
              fieldsOfStudy: res.data.publication.fieldsOfStudy,
            };
            loading.value = false;
            emit('stopLoading');
          })
          .catch((reason) => {
            emit('fetchFailed');
            console.error('fetch failed because ' + reason);
          });
      }
    };

    // watches for changes of the props
    watch(
      () => props.searchres,
      (newVal, oldVal) => {
        if (newVal != oldVal) {
          if (newVal && newVal.entity) {
            publication.value = {
              id: newVal.entity.id,
              type: newVal.entity.type,
              doi: newVal.entity.doi,
              abstract: newVal.entity.abstract,
              pubDate: newVal.entity.pubDate,
              publisher: newVal.entity.publisher,
              citationCount: newVal.entity.citationCount,
              title: newVal.entity.title,
              normalizedTitle: newVal.entity.normalizedTitle,
              authors: newVal.entity.authors,
              year: -1,
              citations: [],
              refs: [],
              fieldsOfStudy: [],
            };
          }
          fetchPublication();
        }
      }
    );

    onMounted(() => {
      if (props.searchres) {
        publication.value = {
          id: props.searchres.entity.id,
          type: props.searchres.entity.type,
          doi: props.searchres.entity.doi,
          abstract: props.searchres.entity.abstract,
          pubDate: props.searchres.entity.pubDate,
          publisher: props.searchres.entity.publisher,
          citationCount: props.searchres.entity.citationCount,
          title: props.searchres.entity.title,
          normalizedTitle: props.searchres.entity.normalizedTitle,
          authors: props.searchres.entity.authors,
          year: -1,
          citations: [],
          refs: [],
          fieldsOfStudy: [],
        };
      }
      fetchPublication();
    });

    // view a Publication of the resultLists
    const viewNewPublication = (id: number) => {
      fetchPublication(id);
      // changes selected entity id from store if the new Element is not already selected
      // TODO: maybe delete previous selection?
      // var arr: number[] = store.getters['search/selectedEntityIds'];
      // if(arr.includes(id)){
      //   store.commit('search/SET_SELECTED_ENTITY_IDS', arr.push(id));
      // }
    };

    const viewAuthor = () => {
      //todo: enable to make authorpages accessable
      // emit('viewNewDetail', id, DetailType.AUTHOR);
    };

    const viewFieldOfStudy = () => {
      //todo: enable to make fieldOfStudypages accessable
      // emit('viewNewDetail', id, DetailType.FIELDOFSTUDY);
    };

    // page Content functions
    const closeStatistics = () => {
      statisticsVisible.value = false;
      document.getElementById('main-content')?.classList.add('col-span-3');
      document.getElementById('main-content')?.classList.remove('col-span-2');
    };
    const openStatistics = () => {
      // ToDo: enable as soon as statistics has content + add hover:text-yellow-400 to main-content
      // statisticsVisible.value = true;
      // document.getElementById('main-content')?.classList.remove('col-span-3');
      // document.getElementById('main-content')?.classList.add('col-span-2');
    };
    const openCitationPopUp = () => {
      // todo: enable as soon as cite-functionality is working + hover:text-yellow-400 in Cite
      //citationPopUpVisible.value = true;
    };
    const closeCitationPopUp = () => {
      citationPopUpVisible.value = false;
    };

    return {
      statisticsVisible,
      citationPopUpVisible,
      isAbstractReading,
      publication,
      loading,
      viewNewPublication,
      viewAuthor,
      viewFieldOfStudy,
      closeStatistics,
      openStatistics,
      closeCitationPopUp,
      openCitationPopUp,
    };
  },
});
