
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { KeyIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'LoginButton',
  components: {
    KeyIcon,
  },
  props: {
    redirect: Boolean,
  },
  setup(props) {
    const store = useStore();

    const handleLogin = () => {
      if (props.redirect) store.dispatch('auth/login', true);
      else store.dispatch('auth/login', false);
    };
    return { handleLogin };
  },
});
