import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Welcome from '../views/Welcome.vue';
import Search from '../views/Search.vue';
import Explore from '../views/Explore.vue';
import Collections from '../views/Collections.vue';
import Collection from '../views/Collection.vue';
import Reports from '../views/Reports.vue';
import Reviews from '../views/Reviews.vue';
import Review from '../views/Review.vue';
import MyLibrary from '../views/MyLibrary.vue';
import Home from '../views/Home.vue';
import Details from '../views/Details.vue';
import Profile from '../views/Profile.vue';
import Logout from '../views/Logout.vue';
import Login from '../views/Login.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: Reviews,
  },
  {
    path: '/collection/:collectionId',
    name: 'Collection',
    component: Collection,
    props: true,
  },
  {
    path: '/details/:detailtype/:detailId',
    name: 'Details',
    component: Details,
    props: true,
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
  },
  {
    path: '/myLibrary',
    name: 'MyLibrary',
    component: MyLibrary,
  },
  {
    path: '/collections',
    name: 'Collections',
    component: Collections,
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/review/:projectId',
    name: 'Review',
    component: Review,
    props: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/explore',
    name: 'Explore',
    component: Explore,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
