<template>
  <button
    class="p-2 mt-1 font-semibold md:text-base sm:text-sm text-gray-200 focus:outline-none"
    @click="cite()"
  >
    <CodeIcon class="h-5 w-5 inline" />{{ this.text }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { CodeIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'Cite',
  components: { CodeIcon },
  props: {
    publicationid: Number,
    text: String,
  },
  setup() {
    const cite = () => {
      return; // todo
    };
    return { cite };
  },
});
</script>
