import {
  AnalysisType,
  PublicationAnalysisType,
  AuthorAnalysisType,
  ClusterMapping,
  GraphDrawingLayout,
} from '@/models/Search';
import { Layout } from '@ambalytics/webgraph';
import { schemePaired, schemePastel1, schemeSet3, schemeTableau10 } from 'd3-scale-chromatic';
import fruchtermanReingold, {
  FruchtermanReingoldLayoutOptions,
} from '@ambalytics/graphology-layout-fruchtermanreingold/worker';
import { circlepack, random, circular } from 'graphology-layout';
import FA2Layout, { FA2LayoutSupervisorParameters } from 'graphology-layout-forceatlas2/worker';
import Graph from 'graphology';
import { modularity } from 'graphology-metrics';
import TypedEmitter from 'typed-emitter';

// One approach to implement a more accurate setTimeout. See: https://thecodersblog.com/increase-javascript-timoeout-accuracy/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setExactTimeout = (callback: (...args: any[]) => void, ms: number, resolution: number) => {
  const start = Date.now();
  const timeout = setInterval(function () {
    if (Date.now() - start > ms) {
      callback();
      clearInterval(timeout);
    }
  }, resolution);

  return timeout;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const clearExactTimeout = (timeout: NodeJS.Timeout) => {
  clearInterval(timeout);
};

const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const percentageToHexColor = (x: number) => hslToHex(x * 360, 33, 66);

export interface InternalAnalysisMapping {
  disableBackdrop?: boolean;
  disableEdges?: boolean;
  colorMap?: readonly string[];
}

interface InternalLayoutMapping {
  layout: Layout;
  options?: unknown;
}

export const analysisConfigurations: { [key in AnalysisType]: InternalAnalysisMapping } = {
  [PublicationAnalysisType.CITATIONNETWORK]: {
    disableBackdrop: true,
  },
  [PublicationAnalysisType.KNOWLEDGEBASES]: {
    // See: https://colorbrewer2.org/#type=qualitative&scheme=Pastel1&n=9
    colorMap: schemePastel1,
  },
  [PublicationAnalysisType.RESEARCHFRONTS]: {
    // See: https://colorbrewer2.org/#type=qualitative&scheme=Paired&n=9
    colorMap: schemePaired,
  },
  [PublicationAnalysisType.HYBRIDFRONTS]: {
    disableEdges: true,
    // See: https://colorbrewer2.org/#type=qualitative&scheme=Set3&n=9
    colorMap: schemeSet3,
  },
  [AuthorAnalysisType.COAUTHORNETWORK]: {
    colorMap: schemeTableau10,
  },
};

export const getClusterColors = (
  analysisType: AnalysisType | undefined,
  clusters: ClusterMapping | undefined
): Record<number, string> => {
  const colorMap = analysisType ? analysisConfigurations[analysisType].colorMap : undefined;

  if (!colorMap || !clusters) {
    return { [-1]: '#ffffff' } as Record<number, string>;
  } else {
    // If there are more clusters than colors in the color map -> generate colormap with hsl
    // else use colormap
    const getColor = (clusterId: number, length: number) =>
      length > colorMap.length ? percentageToHexColor(clusterId / length) : colorMap[clusterId];

    return Object.keys(clusters).reduce(
      (prev, id, i, arr) => {
        const parsedId = parseInt(id);
        // Do not add color for cluster with negative id (nodes with no cluster)
        if (parsedId >= 0) {
          prev[parsedId] = getColor(i, arr.length);
        }
        return prev;
      },
      { [-1]: '#ffffff' } as Record<number, string> // ? Using negative id for not existing clusters
    );
  }
};

export const layoutConfigurations: { [key in GraphDrawingLayout]: InternalLayoutMapping } = {
  [GraphDrawingLayout.FRUCHTERMANREINGOLD]: {
    layout: fruchtermanReingold,
    options: {
      iterations: 100,
      skipUpdates: 49,
      // edgeWeightInfluence: 20,
      C: 5,
      speed: 4,
      // gravity: 10,
    } as Partial<FruchtermanReingoldLayoutOptions>,
  },
  [GraphDrawingLayout.CIRCLEPACK]: {
    layout: circlepack,
    options: {
      hierarchyAttributes: ['cluster'],
    },
  },
  [GraphDrawingLayout.CIRCULAR]: {
    layout: circular,
  },
  [GraphDrawingLayout.RANDOM]: {
    layout: random,
  },
  [GraphDrawingLayout.FORCEATLAS2]: {
    layout: (layoutGraph: Graph, options: FA2LayoutSupervisorParameters) => {
      // first apply another layout where nodes of each cluster are grouped
      // (better results for fa2)
      const clusterCount = layoutGraph.nodes().reduce((prev, node) => {
        prev.add(layoutGraph.getNodeAttribute(node, 'cluster'));
        return prev;
      }, new Set<number>()).size;

      layoutGraph.forEachNode((node) => {
        const cluster = layoutGraph.getNodeAttribute(node, 'cluster');

        const interval = 1 / clusterCount;
        const angle = ((cluster + 1) * interval + Math.random() * interval) * 360;

        const pos = {
          x: Math.sin(angle * (Math.PI / 180)),
          y: Math.cos(angle * (Math.PI / 180)),
        };

        layoutGraph.mergeNode(node, pos);
      });

      const fa2Worker = new FA2Layout(layoutGraph, options);

      // ! The modularity function throws on graphs without edges.
      // In this case we use a modularity = 1
      const graphModularity =
        layoutGraph.size === 0
          ? 1
          : modularity(layoutGraph, {
              attributes: { community: 'cluster' },
              weighted: true,
            });

      fa2Worker.start();

      // ! Set timeout for stopping worker after first update (first iteration)
      layoutGraph.once('eachNodeAttributesUpdated', () => {
        // TODO better calculation of duration based on metrics like modularity
        // ! This timeout function has a delay when rendering the initial layout than when called later.
        // ! I assume the issue here is that other computations are running during the initial run,
        // ! so there are some other functions being processed in the event loop before the timeout callback is called.
        // ! On later tuns there are less other computations -> the event loop has less functions to process before this one.
        // TODO Evaluate if the consistency between the initial and other layout runs is more important than the parallelism.
        setExactTimeout(
          () => {
            fa2Worker.stop();
            fa2Worker.kill();
          },
          Math.min(5000, (1 / graphModularity > 0 ? graphModularity : 1) * 2000),
          10
        );
      });
    },
    options: {
      settings: {
        adjustSizes: true,
        barnesHutOptimize: true,
        edgeWeightInfluence: 2,
        gravity: 5,
      },
    } as FA2LayoutSupervisorParameters,
  },
};

interface GraphViewEvents {
  zoomIn: () => void;
  reset: () => void;
  zoomOut: () => void;
}

export type GraphViewEmitter = TypedEmitter<GraphViewEvents>;
