<template>
  <router-link
    :to="{ name: 'Welcome' }"
    class="text-yellow-400 font-bold inline-block align-text-bottom m-1"
  >
    <standard-logo />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardLogo from '@/components/Logos/StandardLogo.vue';

export default defineComponent({
  name: 'WelcomeButton',
  components: {
    StandardLogo,
  },
});
</script>
