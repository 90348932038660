
import { computed, defineComponent, PropType, ref, toRefs } from 'vue';
import ClusterLegendItem from '@/components/Graphs/ClusterLegendItem.vue';
import { ClusterMapping } from '@/models/Search';

export default defineComponent({
  name: 'ClusterLegend',
  props: {
    activeCluster: {
      type: String,
      required: false,
    },
    clusters: {
      type: Object as PropType<ClusterMapping>,
      required: true,
    },
    clusterColors: {
      type: Object as PropType<Record<number, string>>,
      required: true,
    },
  },
  emits: ['hover', 'click'],
  components: {
    ClusterLegendItem,
  },
  setup(props) {
    const { clusters } = toRefs(props);
    const items = ref<{ [id: string]: HTMLElement }>({});

    const sortedClusters = computed(() =>
      Object.entries(clusters.value).sort(
        (clusterA, clusterB) => clusterB[1].size - clusterA[1].size
      )
    );

    return { sortedClusters, items };
  },
});
