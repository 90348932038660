
import { defineComponent, getCurrentInstance, ref, toRefs, watch } from 'vue';
import AppBar from '@/components/Skeleton/AppBar.vue';
import AppFooter from '@/components/Skeleton/AppFooter.vue';
import { PluginApi as Loading } from 'vue-loading-overlay';

export default defineComponent({
  name: 'AppSkeletonSearch',
  components: {
    AppBar,
    AppFooter,
  },
  props: {
    query: {
      type: String,
      value: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { loading } = toRefs(props);
    const body = ref<HTMLElement | null>(null);

    const app = getCurrentInstance();
    const vloading = app?.appContext.config.globalProperties.$loading as Loading;
    const loader = ref<null | { hide: () => void }>(null);

    watch([loading, body], ([newLoading, newBody]) => {
      if (newLoading && newBody) {
        loader.value = vloading.show({ isFullPage: true, container: newBody, zIndex: 10 });
      } else {
        loader.value?.hide();
      }
    });

    return { body };
  },
});
