import { SearchResponse } from '@/models/Search';
import axios from 'axios';

export interface ResponseWrapper<T> {
  data: T;
}

const core = axios.create({
  baseURL: `${process.env.VUE_APP_AMBA_CORE_API_URL || 'http://localhost:3000/'}entities`,
});

export const GET_PUBLICATIONS = `
query getPublication ($ids: [ID!]!) {
  publications(id: $ids) {
    id
    type
    doi
    pubDate
    publisher
    rank
    citationCount
    title
    normalizedTitle
    year
    refs { id }
    citations { id }
    authors {
      id
      name
      affiliation {
        id
        name
      }
    }
    abstract
  }
}
`;

export const publications = async (ids: number[]): Promise<SearchResponse[]> => {
  const MAX_CHUNK_SIZE = 500;

  const chunkCount = Math.ceil(ids.length / MAX_CHUNK_SIZE);

  const chunks = [] as number[][];

  for (let i = 0; i < chunkCount; i++) {
    chunks.push(ids.slice(MAX_CHUNK_SIZE * i, MAX_CHUNK_SIZE * (i + 1) - 1));
  }

  const requests = chunks.map((chunk) =>
    core.post('', {
      query: GET_PUBLICATIONS,
      variables: { ids: chunk.map((id) => parseInt(id.toString())) },
    })
  );

  const responses = await Promise.all(requests);

  const allParsed = responses.reduce((prev, response) => {
    const data = response.data.data.publications ?? [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsed: SearchResponse[] = data.map((entity: any) => {
      return {
        score: entity?.rank,
        entity: {
          id: parseInt(entity.id.toString()),
          type: entity.type,
          doi: entity.doi,
          pubDate: entity.pubDate,
          publisher: entity.publisher,
          citationCount: entity.citationCount,
          title: entity.title,
          normalizedTitle: entity.normalizedTitle,
          year: entity.year,
          refs: entity.refs.map(({ id }: { id: string }) => parseInt(id)),
          authors: entity.authors,
          abstract: entity.abstract,
          citations: entity.citations.map(({ id }: { id: string }) => parseInt(id)),
        },
      };
    });

    return prev.concat(parsed);
  }, [] as SearchResponse[]);

  return allParsed;
};
