<template>
  <router-link
    :to="{ name: 'Reviews' }"
    class="rounded px-2 py-1 font-semibold inline-block m-1 hover:underline"
  >
    <span>
      <ArrowCircleLeftIcon class="w-5 h-5 mr-1 bg-white rounded-full inline" />
      Back to Overview
    </span>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ArrowCircleLeftIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'ReviewButton',
  components: {
    ArrowCircleLeftIcon,
  },
});
</script>
