<template>
  <button
    v-if="doi"
    class="
      p-2
      mt-1
      md:text-base
      sm:text-sm
      font-semibold
      text-gray-500
      focus:outline-none
      hover:text-yellow-400
    "
    @click="openDoi()"
  >
    <ExternalLinkIcon class="h-5 w-5 inline" />
    {{ this.text }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ExternalLinkIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'OpenDoi',
  components: { ExternalLinkIcon },
  props: {
    doi: String,
    text: String,
  },
  setup(props) {
    const openDoi = () => {
      var href = 'https://doi.org/';
      href += props.doi;
      window.open(href);
    };
    return { openDoi };
  },
});
</script>
