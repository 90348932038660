<template>
  <div class="absolute top-0 w-screen h-16 border-gray-300 border-b flex items-center">
    <div class="absolute w-1/8 grid place-items-center">
      <div class="w-full md:no-flex">
        <div class="px-1 inline-block"><MenuButton @toggle="toggleMenu" /></div>
        <div class="px-1 inline-block"><WelcomeButton class="text-xl" /></div>
        <Menu v-if="menuVisible" @toggle="toggleMenu" />
      </div>
    </div>
    <div class="mx-auto xl:w-full lg:max-w-2xl">
      <div class="h-full w-full flex content-center justify-center items-center">
        <form
          @submit="search"
          class="
            border border-gray-300
            rounded-md
            overflow-hidden
            sm:align-middle sm:max-w-lg sm:w-full
          "
        >
          <AmbaInput
            v-model="searchInput"
            placeholder="Search..."
            class="ring-1 ring-gray-400 focus:ring-0 focus:outline-none focus:bg-gray-200"
          >
            <SearchIcon />
          </AmbaInput>
        </form>
      </div>
    </div>
    <div class="w-1/8">
      <div v-if="!loggedIn && isSearchView">
        <LoginButton class="mx-4 float-right" v-bind:redirect="true" />
      </div>
      <div v-if="loggedIn"><ProfileButton class="mx-4 float-right" /></div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import AmbaInput from '@/components/Input/AmbaInput.vue';
import { SearchIcon } from '@heroicons/vue/solid';
import { useRouter } from 'vue-router';
import Menu from '@/components/Navigation/Menu.vue';
import WelcomeButton from '@/components/Buttons/WelcomeButton.vue';
import MenuButton from '@/components/Buttons/MenuButton.vue';
import LoginButton from '@/components/Buttons/LoginButton.vue';
import ProfileButton from '@/components/Buttons/ProfileButton.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AppBar',
  components: {
    AmbaInput,
    SearchIcon,
    Menu,
    WelcomeButton,
    MenuButton,
    LoginButton,
    ProfileButton,
  },
  props: {
    query: String,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const searchInput = ref<string>();
    const menuVisible = ref(false);
    //router doesn't need to be watched since the component is destroyed and remounted everytime the route changes
    const isSearchView = ref(router.currentRoute.value.name === 'Search');

    const loggedIn = computed(() => store.getters['auth/isLoggedIn']);

    const search = (e: Event) => {
      e.preventDefault();
      if (searchInput.value === '') return;
      router.push({ name: 'Search', query: { q: searchInput.value } });
    };
    const toggleMenu = () => {
      menuVisible.value = !menuVisible.value;
    };

    onMounted(() => {
      searchInput.value = props.query;
    });

    return { searchInput, search, toggleMenu, menuVisible, loggedIn, isSearchView };
  },
});
</script>
