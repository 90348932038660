<template>
  <div class="fixed top-16 left-0 w-1/8 h-auto rounded z-10">
    <div class="grid place-items-center">
      <div class="grid place-items-center"></div>
      <div class="static w-full col-span-1">
        <PanelMenu :model="items" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import PanelMenu from 'primevue/panelmenu';
import { PrimeIcons } from 'primevue/api';

import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'Menu',
  components: {
    PanelMenu,
  },
  setup() {
    const items = ref([
      {
        label: 'Home',
        icon: PrimeIcons.HOME,
        to: { name: 'Home' },
      },
      {
        label: 'Explore',
        icon: PrimeIcons.SEARCH,
      },
      {
        label: 'Library',
        items: [
          {
            label: 'My Library',
            icon: PrimeIcons.LIST,
            to: { name: 'MyLibrary' },
          },
          {
            label: 'Collections',
            icon: PrimeIcons.TAGS,
            to: { name: 'Collections' },
          },
        ],
      },
      {
        label: 'Reviews',
        icon: PrimeIcons.BOOK,
        to: { name: 'Reviews' },
      },
      {
        label: 'Reports',
        icon: PrimeIcons.CHART_BAR,
        to: { name: 'Reports' },
      },
    ]);
    return { items };
  },
});
</script>

<style></style>
