<template>
  <li
    class="
      block
      cursor-pointer
      py-2
      hover:bg-gray-100 hover:bg-opacity-50
      rounded-sm
      w-full
      border-separate border-b border-gray-200
    "
    :class="{ 'border border-b border-yellow-300 bg-yellow-50': active }"
  >
    <Disclosure>
      <div class="flex items-center w-full">
        <div
          class="h-4 w-4 mx-2 inline-block float-left"
          :style="{ backgroundColor: clusterColor }"
        />
        <div class="inline-block text-black text-left float-left">
          <div class="text-sm">
            <span class="font-semibold">{{ name }}</span>
            <span class="font-normal italic"> - {{ size }} nodes</span>
          </div>
          <div class="text-xs italic" :class="{ 'text-transparent': isOpen }">
            {{ keywords.slice(0, 5).join(', ') }}{{ keywords.length > 5 ? ', more...' : '' }}
          </div>
        </div>
        <DisclosureButton
          class="
            float-right
            rounded
            p-0
            bg-gray-200 bg-opacity-50
            ring-1 ring-gray-400
            hover:bg-gray-300
            mx-2
            ml-auto
          "
          v-if="keywords.length > 5"
          @click="
            (e) => {
              setOpen(!isOpen);
              e.stopPropagation();
            }
          "
        >
          <ChevronUpIcon
            :class="isOpen ? 'transition-all transform rotate-180' : ''"
            class="w-4 h-4 text-gray-600"
          />
        </DisclosureButton>
      </div>
      <div v-show="isOpen">
        <DisclosurePanel class="text-black text-left text-sm m-2 mt-0 italic" static>
          <template v-if="keywords.length > 0">
            {{ keywords.join(', ') }}
          </template>
          <template v-else> no keywords </template>
        </DisclosurePanel>
      </div>
    </Disclosure>
  </li>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, toRefs, watch } from 'vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/outline';

export default defineComponent({
  name: 'ClusterLegendItem',
  props: {
    id: Number,
    name: String,
    keywords: Array as PropType<string[]>,
    clusterColor: String,
    size: Number,
    active: Boolean,
  },
  emits: ['hover'],
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronUpIcon,
  },
  setup(props) {
    const { active } = toRefs(props);

    const isOpen = ref(active.value);

    watch(
      () => active.value,
      (value) => (isOpen.value = value)
    );

    const setOpen = (value: boolean) => {
      isOpen.value = value;
    };

    return { isOpen, setOpen };
  },
});
</script>

<style></style>
