<template>
  <div
    id="LoginPopUp"
    class="absolute top-16 bottom-10 left-0 w-full backdrop-filter backdrop-blur-sm"
  >
    <div
      class="
        absolute
        items-center
        top-1/4
        left-1/3
        w-1/3
        h-auto
        bg-white
        border border-gray-300
        rounded
      "
    >
      <div class="m-3 text-gray-800 font-light text-xl">
        To access this page, please login into your ambalytics account or register yourself.
      </div>
      <div class="items-baseline">
        <LoginButton class="p-2 m-3 float-right" v-bind:redirect="false" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoginButton from '@/components/Buttons/LoginButton.vue';

export default defineComponent({
  name: 'LoginPopUp',
  components: {
    LoginButton,
  },
});
</script>
