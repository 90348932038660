
import { defineComponent, onMounted, ref } from 'vue';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/vue/solid';
import { Affiliation } from '@/models/Details';

export default defineComponent({
  name: 'AffiliationPage',
  components: {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    // constants reactive to consider a possible change of the displayed publication
    const statisticsVisible = ref(false);
    const affiliation = ref(Object as unknown as Affiliation);

    // TODO: fetch author using existing axios
    const fetchAffiliation = (id?: number) => {
      if (id || props.id) {
        let reqId: number;
        if (id) reqId = id;
        else if (props.id) reqId = props.id;
        else {
          emit('fetchFailed');
          return;
        }
        emit('loading');
        fetch('https://api.ambalytics.cloud/entities', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Content-Length': '142' },
          body: `{"query":"query getAffiliation($id: ID!) { affiliation(id: $id) {id, name, normalizedName, pubCount, latitude, longitude, officialPageUrl, wikiPageUrl }}" ,"variables":{"id":"${reqId}"}}`,
        })
          .then((res) => res.json())
          .then((res) => {
            if (!res.data.affiliation) return; //TODO: reaction to author == null
            affiliation.value = { ...res.data.affiliation };
            emit('stopLoading');
          })
          .catch((reason) => {
            emit('fetchFailed');
            console.error('fetch failed because ' + reason);
          });
      } else {
        emit('fetchFailed');
      }
    };

    onMounted(() => {
      fetchAffiliation();
    });

    const closeStatistics = () => {
      statisticsVisible.value = false;
      document.getElementById('main-content')?.classList.add('col-span-3');
      document.getElementById('main-content')?.classList.remove('col-span-2');
    };
    const openStatistics = () => {
      // ToDo: enable as soon as statistics has content + add hover:text-yellow-400 to main-content
      // statisticsVisible.value = true;
      // document.getElementById('main-content')?.classList.remove('col-span-3');
      // document.getElementById('main-content')?.classList.add('col-span-2');
    };

    return {
      statisticsVisible,
      closeStatistics,
      openStatistics,
      affiliation,
    };
  },
});
