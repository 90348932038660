
import { defineComponent, computed, getCurrentInstance, ref, watch } from 'vue';
import { useStore } from 'vuex';
import AppSkeletonSearch from '@/components/Skeleton/AppSkeletonSearch.vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import SearchGraph from '@/components/Graphs/SearchGraph/SearchGraph.vue';
import ResultList from '@/components/Search/ResultList.vue';
import EventEmitter from 'events';
import {
  AnalysisResponse,
  AnalysisType,
  AuthorAnalysisType,
  GraphDrawingLayout,
  SearchResponse,
} from '@/models/Search';
import { ActiveLoader, PluginApi as Loading } from 'vue-loading-overlay';
import { getClusters } from '@/components/Graphs/utils/data';
import {
  GraphViewEmitter,
  analysisConfigurations,
} from '@/components/Graphs/utils/graphConfiguration';
import GraphControls from '@/components/Graphs/SearchGraph/GraphControls.vue';
import { SerializedNode } from 'graphology-types';

interface ContextMenu {
  x: number;
  y: number;
  id: number;
  label: string;
}

export default defineComponent({
  name: 'Search',
  components: {
    AppSkeletonSearch,
    GraphControls,
    SearchGraph,
    ResultList,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const searchContainer = ref<HTMLElement | null>(null);

    const app = getCurrentInstance();
    const loading = app?.appContext.config.globalProperties.$loading as Loading;
    const getLoader = () =>
      searchContainer.value
        ? loading.show({
            isFullPage: false,
            container: searchContainer.value,
          })
        : undefined;

    const query = computed(() => route.query.q);
    const entities = computed(() => store.getters['search/entities'] as SearchResponse[]);
    const analysis = computed(() => store.getters['search/analysis']() as AnalysisResponse);
    const isLoading = computed(() => store.getters['search/analysis']() as AnalysisResponse);
    const loader = ref<ActiveLoader | undefined>(undefined);
    watch(
      () => isLoading.value,
      (newValue) => {
        if (newValue) {
          if (!loader.value) loader.value = getLoader();
        } else {
          loader.value?.hide();
        }
      }
    );

    const selectedEntityIds = computed(() => store.getters['search/selectedEntityIds'] as number[]);
    const filterEntityIds = computed(() => store.getters['search/filterEntityIds'] as number[]);

    const currentAnalysis = computed(() => store.getters['search/currentAnalysis'] as AnalysisType);
    const clusters = computed(() =>
      analysis.value
        ? getClusters(analysis.value, entities.value.length)
        : {
            [-1]: { name: 'Unassigned', keywords: [], size: entities.value.length },
          }
    );

    const backdropActive = ref(true);
    const allEdges = ref(false);
    const activeCluster = ref<string | undefined>(undefined);
    const layout = ref(GraphDrawingLayout.FORCEATLAS2);

    const contextMenu = ref<ContextMenu | null>(null);

    const viewEventEmitter = new EventEmitter() as GraphViewEmitter;

    const manipulateView = (action: 'zoomIn' | 'reset' | 'zoomOut') => {
      switch (action) {
        case 'zoomIn':
          viewEventEmitter.emit('zoomIn');
          break;
        case 'reset':
          viewEventEmitter.emit('reset');
          break;
        case 'zoomOut':
          viewEventEmitter.emit('zoomOut');
          break;
      }
    };

    const switchAnalysis = async (newAnalysis: AnalysisType) => {
      await store.dispatch('search/switchAnalysis', { type: newAnalysis });
    };

    const clickNode = async ({
      node,
      event,
    }: {
      node: SerializedNode;
      event: MouseEvent & { original: MouseEvent };
    }) => {
      if (event.original.button === 2) {
        if (currentAnalysis.value === AuthorAnalysisType.COAUTHORNETWORK) return;

        event.original.stopPropagation();
        event.original.preventDefault();

        contextMenu.value = {
          x: event?.x - 5,
          y: event?.y + 5,
          id: parseInt(node.key.toString()),
          label: node.attributes?.label ?? '',
        };
      } else {
        store.dispatch('search/selectEntities', node ? { ids: [node.key] } : undefined);
      }
    };

    const filterNodes = async (nodes: number[]) => {
      store.dispatch('search/filterEntities', { ids: nodes });
    };

    watch(
      () => selectedEntityIds.value,
      ([newId]) => {
        if (newId) {
          const cluster = analysis.value?.entities.find((el) => parseInt(el.id) === newId)
            ?.properties.communityId;
          activeCluster.value = cluster ? cluster.toString() : undefined;
        }
      }
    );

    onBeforeRouteUpdate((to, from) => {
      if (to.query.q !== from.query.q) {
        store.dispatch('search/fetch', { query: to.query.q });
      }
    });

    const startExplore = async (entityIds: number[]) => {
      const loader = getLoader();
      await store.dispatch('explore/reset');
      await store.dispatch('explore/setEntities', { ids: entityIds });
      router.push({ name: 'Explore' });
      loader?.hide();
    };

    return {
      query,
      entities,
      selectedEntityIds,
      filterEntityIds,
      analysis,
      currentAnalysis,
      clusters,
      backdropActive,
      allEdges,
      activeCluster,
      layout,
      manipulateView,
      switchAnalysis,
      analysisConfigurations,
      viewEventEmitter,
      clickNode,
      filterNodes,
      startExplore,
      contextMenu,
      searchContainer,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.$store.dispatch('search/fetch', { query: to.query.q }));
  },
});
