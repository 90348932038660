import {
  cloneVNode,
  defineComponent,
  h,
  inject,
  InjectionKey,
  nextTick,
  onMounted,
  onUnmounted,
  provide,
  Ref,
  ref,
  toRefs,
  watch,
  watchEffect,
} from 'vue';
import { v4 } from 'uuid';
import { computed } from '@vue/reactivity';

type SideMenuItemDataRef = Ref<{ textValue: string; disabled: boolean }>;

interface SideMenuState {
  // State
  isOpen: Ref<boolean>;
  buttonRef: Ref<HTMLButtonElement | null>;
  itemsRef: Ref<HTMLDivElement | null>;
  items: Ref<{ id: string; dataRef: SideMenuItemDataRef }[]>;
  activeItemIndex: Ref<number | null>;
  size: Ref<number | null>;

  // State mutators
  closeMenu(): void;
  openMenu(): void;
  // goToItem(focus: Focus, id?: string): void
  registerItem(id: string, dataRef: SideMenuItemDataRef): void;
  unregisterItem(id: string): void;
  resize(size: number): void;
}

const SideMenuContext = Symbol('SideMenuContext') as InjectionKey<SideMenuState>;

function nextFrame(cb: () => void) {
  requestAnimationFrame(() => requestAnimationFrame(cb));
}

export const SideMenu = defineComponent({
  name: 'SideMenu',
  props: {
    as: { type: [Object, String], default: 'div' },
    open: { type: Boolean, default: false },
  },
  emits: ['close', 'open'],
  setup(props, { emit }) {
    const { open } = toRefs(props);

    const menuRef = ref<HTMLElement | null>(null);

    const api: SideMenuState = {
      isOpen: open,
      buttonRef: ref(null),
      itemsRef: ref(null),
      items: ref([]),
      activeItemIndex: ref(null),
      size: ref(null),
      // Mutators
      closeMenu: function () {
        emit('close');
        this.activeItemIndex.value = null;
      },
      openMenu: function () {
        emit('open');
      },
      registerItem: function (id: string, dataRef: SideMenuItemDataRef) {
        this.items.value.push({ id, dataRef });
      },
      unregisterItem: function (id: string) {
        const nextItems = this.items.value.slice();
        const currentActiveItem =
          this.activeItemIndex.value !== null ? nextItems[this.activeItemIndex.value] : null;
        const idx = nextItems.findIndex((a) => a.id === id);
        if (idx !== -1) nextItems.splice(idx, 1);
        this.items.value = nextItems;
        this.activeItemIndex.value = (() => {
          if (idx === this.activeItemIndex.value) return null;
          if (currentActiveItem === null) return null;

          // If we removed the item before the actual active index, then it would be out of sync. To
          // fix this, we will find the correct (new) index position.
          return nextItems.indexOf(currentActiveItem);
        })();
      },
      resize: function (size: number) {
        this.size.value = size;
      },
    };

    provide(SideMenuContext, api);

    watch(
      () => api.isOpen.value,
      (newValue) => {
        if (!api.itemsRef.value || newValue) {
          menuRef.value?.style.removeProperty('--tw-translate-x');
        } else {
          const width = `-${api.itemsRef.value.offsetWidth}px`;
          menuRef.value?.style.setProperty('--tw-translate-x', width);
        }
      }
    );

    return {
      api,
      el: menuRef,
    };
  },
  render() {
    const { as, ...passThroughProps } = {
      ...this.$props,
      class: [
        'absolute',
        'h-full',
        'block',
        'top-0',
        'left-0',
        'transform',
        'ease-in-out',
        'delay-150',
        'transition-transform',
      ],
      ref: 'el',
    };

    const children = this.$slots.default?.({ open: this.api.isOpen.value });

    if (as === 'template' && children) {
      if (Object.keys(passThroughProps).length > 0 || Object.keys(this.$attrs).length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return cloneVNode(children[0], passThroughProps as Record<string, any>);
      }

      if (Array.isArray(children) && children.length === 1) {
        return children[0];
      }

      return children;
    }

    return h(as, passThroughProps, children);
  },
});

export const SideMenuButton = defineComponent({
  name: 'SideMenuButton',
  props: {
    disabled: { type: Boolean, default: false },
    static: { type: Boolean, default: false },
    as: { type: [Object, String], default: 'button' },
  },
  setup(props) {
    const api = inject(SideMenuContext, null);
    const id = `sidemenu-${v4()}`;

    function handleClick(event: Event) {
      if (props.disabled) return;
      if (api?.isOpen.value) {
        api?.closeMenu();
        nextTick(() => api.buttonRef.value?.focus({ preventScroll: true }));
      } else {
        event.preventDefault();
        event.stopPropagation();
        api?.openMenu();
        nextFrame(() => api?.itemsRef.value?.focus({ preventScroll: true }));
      }
    }

    const visible = computed(() => props.static || api?.isOpen.value);

    return {
      id,
      el: api?.buttonRef,
      handleClick,
      api,
      visible,
    };
  },
  render() {
    const propsWeControl = {
      ref: 'el',
      id: this.id,
      type: 'button',
      'aria-haspopup': true,
      'aria-controls': this.api?.itemsRef.value?.id,
      'aria-expanded': this.$props.disabled ? undefined : this.api?.isOpen.value,
      onClick: this.handleClick,
    };
    const { as, ...passThroughProps } = {
      ...this.$props,
      ...propsWeControl,
      class: ['relative', 'top-8', 'inline-block', 'float-right'],
    };

    const children = this.$slots.default?.({ open: this.api?.isOpen.value });

    if (as === 'template' && children) {
      if (Object.keys(passThroughProps).length > 0 || Object.keys(this.$attrs).length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return cloneVNode(children[0], passThroughProps as Record<string, any>);
      }

      if (Array.isArray(children) && children.length === 1) {
        return children[0];
      }

      return children;
    }

    return h(as, passThroughProps, children);
  },
});

export const SideMenuItems = defineComponent({
  name: 'SideMenuItems',
  props: {
    as: { type: [Object, String], default: 'div' },
    static: { type: Boolean, default: false },
  },
  setup(props) {
    const api = inject(SideMenuContext, null);
    const id = `sidemenu-items-${v4()}`;

    const visible = computed(() => props.static || api?.isOpen.value);
    const size = computed(() => {
      return api?.size.value && api?.size.value > 0 ? api?.size.value : undefined;
    });

    return { id, el: api?.itemsRef, api, visible, size };
  },
  render() {
    const propsWeControl = {
      'aria-activedescendant':
        this.api?.activeItemIndex.value === null
          ? undefined
          : this.api?.items.value[this.api?.activeItemIndex.value]?.id,
      'aria-labelledby': this.api?.buttonRef?.value?.id,
      id: this.id,
      role: 'menu',
      tabIndex: 0,
      ref: 'el',
      style: this.size
        ? {
            width: `${this.size}px`,
          }
        : undefined,
    };
    const { as, ...passThroughProps } = {
      ...this.$props,
      ...propsWeControl,
      class: ['h-full', 'relative', 'inline-block', 'float-left', 'overflow-y-auto'],
    };

    const children = this.$slots.default?.({ open: this.api?.isOpen.value });

    if (as === 'template' && children) {
      if (Object.keys(passThroughProps).length > 0 || Object.keys(this.$attrs).length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return cloneVNode(children[0], passThroughProps as Record<string, any>);
      }

      if (Array.isArray(children) && children.length === 1) {
        return children[0];
      }

      return children;
    }

    return h(as, passThroughProps, children);
  },
});

export const SideMenuResize = defineComponent({
  name: 'SideMenuResize',
  setup() {
    const api = inject(SideMenuContext, null);
    const id = `sidemenu-resize-${v4()}`;

    const visible = computed(() => api?.isOpen.value);

    const handleMouseMove = (e: MouseEvent) => {
      api?.resize(e.pageX);
      e.preventDefault();
    };

    const handleMouseUp = (e: MouseEvent) => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      api?.resize(e.pageX);
      e.preventDefault();
    };

    const handleMouseDown = (e: MouseEvent) => {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      e.preventDefault();
    };

    const marginRight = computed(() => api?.buttonRef.value?.clientWidth || 0);

    return { id, api, visible, handleMouseDown, marginRight };
  },
  render() {
    const props = {
      ...this.$props,
      class: 'absolute inline-block w-1 h-full right-0 z-10',
      style: {
        cursor: 'ew-resize',
        marginRight: this.marginRight + 'px',
      },
      onmousedown: this.handleMouseDown,
    };

    if (!this.visible) return null;

    return h('div', props);
  },
});

export const SideMenuItem = defineComponent({
  name: 'SideMenuItem',
  props: {
    as: { type: [Object, String], default: 'template' },
    disabled: { type: Boolean, default: false },
  },
  setup(props) {
    const api = inject(SideMenuContext, null);
    const id = `sidemenu-item-${v4()}`;

    const active = computed(() => {
      return api?.activeItemIndex.value !== null
        ? api?.items.value[api.activeItemIndex.value].id === id
        : false;
    });

    const dataRef = ref({ disabled: props.disabled, textValue: '' });
    onMounted(() => {
      const textValue = document.getElementById(id)?.textContent?.toLowerCase().trim();
      if (textValue !== undefined) dataRef.value.textValue = textValue;
    });
    onMounted(() => api?.registerItem(id, dataRef));
    onUnmounted(() => api?.unregisterItem(id));

    watchEffect(() => {
      if (api?.isOpen.value) return;
      if (!active.value) return;
      nextTick(() => document.getElementById(id)?.scrollIntoView?.({ block: 'nearest' }));
    });

    return {
      id,
      api,
      active,
      dataRef,
    };
  },
  render() {
    const propsWeControl = {
      id: this.id,
      role: 'menuitem',
      tabIndex: this.$props.disabled === true ? undefined : -1,
      'aria-disabled': this.$props.disabled === true ? true : undefined,
    };
    const { as, ...passThroughProps } = { ...this.$props, ...propsWeControl };

    const children = this.$slots.default?.({ active: this.active, disabled: this.$props.disabled });

    if (as === 'template' && children) {
      if (Object.keys(passThroughProps).length > 0 || Object.keys(this.$attrs).length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return cloneVNode(children[0], passThroughProps as Record<string, any>);
      }

      if (Array.isArray(children) && children.length === 1) {
        return children[0];
      }

      return children;
    }

    return h(as, passThroughProps, children);
  },
});
