
import { defineComponent } from 'vue';
import Timeline from 'primevue/timeline';
import { CheckCircleIcon, DotsCircleHorizontalIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'ProgressBar',
  components: {
    Timeline,
    CheckCircleIcon,
    DotsCircleHorizontalIcon,
  },
  props: {
    steps: [],
    project: {},
  },
});
