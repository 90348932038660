<template>
  <button
    @click="handleLogin"
    class="
      p-1
      text-yellow-400
      font-semibold
      hover:text-yellow-500
      border border-yellow-400
      rounded-md
    "
  >
    <KeyIcon class="h-5 w-5 inline" />
    Login
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { KeyIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'LoginButton',
  components: {
    KeyIcon,
  },
  props: {
    redirect: Boolean,
  },
  setup(props) {
    const store = useStore();

    const handleLogin = () => {
      if (props.redirect) store.dispatch('auth/login', true);
      else store.dispatch('auth/login', false);
    };
    return { handleLogin };
  },
});
</script>
