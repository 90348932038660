<template>
  <div class="w-5/6 mt-10 mx-auto border-solid border border-yellow-400 rounded-md px-10">
    <Timeline :value="steps" layout="horizontal" class="customized-timeline">
      <template #marker="slotProps">
        <span class="custom-marker p-shadow-2">
          <DotsCircleHorizontalIcon
            v-if="!this.project[slotProps.item.propName]"
            class="h-5 w-5 inline"
          />
          <CheckCircleIcon v-if="this.project[slotProps.item.propName]" class="h-5 w-5 inline" />
        </span>
      </template>
      <template #content="slotProps">
        {{ slotProps.item.name.toString() }}
      </template>
    </Timeline>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Timeline from 'primevue/timeline';
import { CheckCircleIcon, DotsCircleHorizontalIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'ProgressBar',
  components: {
    Timeline,
    CheckCircleIcon,
    DotsCircleHorizontalIcon,
  },
  props: {
    steps: [],
    project: {},
  },
});
</script>

<style lang="scss" scoped></style>
