<template>
  <div>
    <AppSkeletonMenu>
      <template v-slot>
        <div class="w-full h-full bg-gray-50">
          <div
            class="absolute inset-x-1/3 top-16 w-1/3 h-auto bg-white border border-gray-300 rounded"
          >
            <div class="m-6">
              <div class="pb-6 text-2xl text-gray-900">
                You logged out of your ambalytics account.
              </div>
              <div class="text-xl font-light text-gray-500">
                Do you want to login again?
                <LoginButton class="ml-4" v-bind:redirect="true" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppSkeletonMenu>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import AppSkeletonMenu from '@/components/Skeleton/AppSkeletonMenu.vue';
import LoginButton from '@/components/Buttons/LoginButton.vue';

export default defineComponent({
  name: 'Logout',
  components: {
    AppSkeletonMenu,
    LoginButton,
  },
});
</script>
