<template>
  <div id="main-content" class="grid grid-cols-3 p-3 text-gray-900">
    <button
      class="absolute right-1/3 text-gray-400 rounded focus:outline-none hover:text-yellow-400"
      @click="closeStatistics"
      v-if="statisticsVisible"
    >
      <span class="font-semibold">Hide Analysis</span>
      <ChevronDoubleRightIcon class="h-5 w-5 inline" />
    </button>
    <button
      class="absolute right-0 mr-12 text-gray-200 rounded focus:outline-none"
      @click="openStatistics"
      v-if="!statisticsVisible"
    >
      <span class="font-semibold">View Analysis</span>
      <ChevronDoubleLeftIcon class="h-5 w-5 inline" />
    </button>
    <div id="main-content" class="col-span-3 mt-9">
      <div
        v-if="publication.title"
        class="lg:text-2xl md:text-xl sm:text-base p-2 font-semibold bg-gray-300 rounded"
      >
        {{ publication.title }}
      </div>
      <div class="static float-right border-gray-300 border-2 rounded">
        <OpenDoi class="block" v-bind:doi="publication.doi" />
        <Cite
          class="block mb-2"
          :publicationid="parseFloat(publication.id)"
          @click="openCitationPopUp()"
        />
      </div>
      <div class="p-2 md:text-base sm:text-sm text-gray-600">
        <div v-if="publication.publisher">
          <span class="font-semibold">Publisher:</span>{{ publication.publisher }}
        </div>
        <div v-if="publication.authors">
          <span class="font-semibold">Authors:</span>
          <span
            v-for="author in publication.authors"
            :key="author.id"
            @click="viewAuthor(author.id)"
          >
            {{ author.name }},
          </span>
        </div>
        <div v-if="publication.pubDate">
          <span class="font-semibold">Publication Date:</span> {{ publication.pubDate }}
        </div>
        <div>
          <span class="font-semibold">Fields of Study:</span>
          <span v-if="!loading">
            <span
              v-for="field in publication.fieldsOfStudy"
              :key="field.id"
              @click="viewFieldOfStudy(field.id)"
            >
              {{ field.name }},
            </span>
          </span>
          <Skeleton v-else height="1rem" class="p-3 my-3 rounded w-full" />
        </div>
        <div v-if="publication.doi">
          <span class="font-semibold">DOI:</span> {{ publication.doi }}
        </div>
      </div>
      <div
        v-if="publication.abstract && isAbstractReading"
        class="p-3 border md:text-base sm:text-sm border-gray-300 rounded"
      >
        {{ publication.abstract }}
      </div>

      <div v-if="publication.refs && !loading">
        <PublicationList
          v-on:selectPublication="viewNewPublication"
          name="References"
          :publicationList="publication.refs"
        />
      </div>
      <Skeleton v-else height="6rem" class="p-3 my-3 rounded w-full" />
      <div v-if="publication.citations && !loading">
        <PublicationList
          v-on:selectPublication="viewNewPublication"
          name="Citations"
          :publicationList="publication.citations"
        />
      </div>
      <Skeleton v-else height="6rem" class="p-3 my-3 rounded w-full" />
    </div>

    <div v-if="statisticsVisible" class="col-span-1 mt-6 p-3">
      <div class="border border-gray-300 rounded h-1/3">Keywords</div>
    </div>
    <div><CitationPopUp v-if="citationPopUpVisible" v-on:close="closeCitationPopUp()" /></div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/vue/solid';
import OpenDoi from '@/components/Buttons/OpenDoi.vue';
import CitationPopUp from '@/components/PopUps/CitationPopUp.vue';
import Cite from '@/components/Buttons/Cite.vue';
import { SearchResponse } from '@/models/Search';
import { Publication } from '@/models/Details';
import PublicationList from '@/components/Details/PublicationList.vue';
import Skeleton from 'primevue/skeleton';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'PublicationPage',
  components: {
    OpenDoi,
    Cite,
    CitationPopUp,
    PublicationList,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    Skeleton,
  },
  props: {
    searchres: {
      type: Object as PropType<SearchResponse>,
      required: false,
    },
    id: {
      type: Number,
      required: false, // if id exists, the fetch will fetch the entire publication
    },
  },
  setup(props, { emit }) {
    // constants reactive to consider a possible change of the displayed publication
    const statisticsVisible = ref(false);
    const citationPopUpVisible = ref(false);
    const publication = ref<Publication>();
    const loading = ref(false);
    const isAbstractReading: Ref<boolean> = ref(false);
    const store = useStore();
    const abstractReading = computed(() => store.getters['auth/isAbstractReading']);
    watch(abstractReading, (abstractReading) => {
      isAbstractReading.value = abstractReading;
    });
    // fetches a publication either by using the prop SearchResult (selecting element of the ResultList) or by id (selecting an element of the PublicationLists)
    // TODO: fetch publication using existing axios
    const fetchPublication = function (id?: number) {
      loading.value = true;
      if (id || props.id) {
        let reqId: number;
        if (id) reqId = id;
        else if (props.id) reqId = props.id;
        else {
          emit('fetchFailed');
          return;
        }
        emit('loading');
        fetch('https://api.ambalytics.cloud/entities', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Content-Length': '142' },
          body: `{"query":"query getPublication($id: ID!) { publication(id: $id) {id, type, doi, abstract, pubDate, publisher, citationCount, title, normalizedTitle, year, citations{ id, title}, refs{id, title}, authors{id, name}, fieldsOfStudy{id, name }}}" ,"variables":{"id":"${reqId}"}}`,
        })
          .then((res) => res.json())
          .then((res) => {
            if (!res.data.publication) return; //TODO: reaction to publication = null
            publication.value = { ...res.data.publication };
            loading.value = false;
            emit('stopLoading');
          })
          .catch((reason) => {
            emit('fetchFailed');
            console.error('fetch failed because ' + reason);
          });
      } else {
        emit('loading');
        if (!props.searchres) {
          emit('fetchFailed');
          return;
        }
        const searchres: SearchResponse = props.searchres;
        fetch('https://api.ambalytics.cloud/entities', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Content-Length': '142' },
          body: `{"query":"query getPublication($id: ID!) { publication(id: $id) {citations{ id, title}, rank, fieldsOfStudy{id, name }, refs{id, title}}}" ,"variables":{"id":"${searchres.entity.id}"}}`,
        })
          .then((res) => res.json())
          .then((res) => {
            if (!res.data.publication) return; //TODO: reaction to publication = null

            publication.value = {
              id: searchres.entity.id,
              type: searchres.entity.type,
              doi: searchres.entity.doi,
              abstract: searchres.entity.abstract,
              pubDate: searchres.entity.pubDate,
              publisher: searchres.entity.publisher,
              citationCount: searchres.entity.citationCount,
              title: searchres.entity.title,
              normalizedTitle: searchres.entity.normalizedTitle,
              authors: searchres.entity.authors,
              year: -1,
              citations: res.data.publication.citations,
              refs: res.data.publication.refs,
              fieldsOfStudy: res.data.publication.fieldsOfStudy,
            };
            loading.value = false;
            emit('stopLoading');
          })
          .catch((reason) => {
            emit('fetchFailed');
            console.error('fetch failed because ' + reason);
          });
      }
    };

    // watches for changes of the props
    watch(
      () => props.searchres,
      (newVal, oldVal) => {
        if (newVal != oldVal) {
          if (newVal && newVal.entity) {
            publication.value = {
              id: newVal.entity.id,
              type: newVal.entity.type,
              doi: newVal.entity.doi,
              abstract: newVal.entity.abstract,
              pubDate: newVal.entity.pubDate,
              publisher: newVal.entity.publisher,
              citationCount: newVal.entity.citationCount,
              title: newVal.entity.title,
              normalizedTitle: newVal.entity.normalizedTitle,
              authors: newVal.entity.authors,
              year: -1,
              citations: [],
              refs: [],
              fieldsOfStudy: [],
            };
          }
          fetchPublication();
        }
      }
    );

    onMounted(() => {
      if (props.searchres) {
        publication.value = {
          id: props.searchres.entity.id,
          type: props.searchres.entity.type,
          doi: props.searchres.entity.doi,
          abstract: props.searchres.entity.abstract,
          pubDate: props.searchres.entity.pubDate,
          publisher: props.searchres.entity.publisher,
          citationCount: props.searchres.entity.citationCount,
          title: props.searchres.entity.title,
          normalizedTitle: props.searchres.entity.normalizedTitle,
          authors: props.searchres.entity.authors,
          year: -1,
          citations: [],
          refs: [],
          fieldsOfStudy: [],
        };
      }
      fetchPublication();
    });

    // view a Publication of the resultLists
    const viewNewPublication = (id: number) => {
      fetchPublication(id);
      // changes selected entity id from store if the new Element is not already selected
      // TODO: maybe delete previous selection?
      // var arr: number[] = store.getters['search/selectedEntityIds'];
      // if(arr.includes(id)){
      //   store.commit('search/SET_SELECTED_ENTITY_IDS', arr.push(id));
      // }
    };

    const viewAuthor = () => {
      //todo: enable to make authorpages accessable
      // emit('viewNewDetail', id, DetailType.AUTHOR);
    };

    const viewFieldOfStudy = () => {
      //todo: enable to make fieldOfStudypages accessable
      // emit('viewNewDetail', id, DetailType.FIELDOFSTUDY);
    };

    // page Content functions
    const closeStatistics = () => {
      statisticsVisible.value = false;
      document.getElementById('main-content')?.classList.add('col-span-3');
      document.getElementById('main-content')?.classList.remove('col-span-2');
    };
    const openStatistics = () => {
      // ToDo: enable as soon as statistics has content + add hover:text-yellow-400 to main-content
      // statisticsVisible.value = true;
      // document.getElementById('main-content')?.classList.remove('col-span-3');
      // document.getElementById('main-content')?.classList.add('col-span-2');
    };
    const openCitationPopUp = () => {
      // todo: enable as soon as cite-functionality is working + hover:text-yellow-400 in Cite
      //citationPopUpVisible.value = true;
    };
    const closeCitationPopUp = () => {
      citationPopUpVisible.value = false;
    };

    return {
      statisticsVisible,
      citationPopUpVisible,
      isAbstractReading,
      publication,
      loading,
      viewNewPublication,
      viewAuthor,
      viewFieldOfStudy,
      closeStatistics,
      openStatistics,
      closeCitationPopUp,
      openCitationPopUp,
    };
  },
});
</script>
