
import { defineComponent } from 'vue';
import AmbaInput from '@/components/Input/AmbaInput.vue';
import Checkbox from 'primevue/checkbox';
import { WorkflowStep } from '@/models/Review/index';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'NewReview',
  components: {
    AmbaInput,
    Checkbox,
    Dialog,
    Button,
  },
  props: {
    addingProject: Boolean,
  },
  data() {
    return {
      newRewTitle: '',
      newRewDescr: '',
      steps: [
        { key: 'AS', value: 'Abstract Screening' },
        { key: 'FTR', value: 'Full Text Review' },
        { key: 'QA', value: 'Quality Assessment' },
      ],
      selectedCategories: ['Abstract Screening', 'Full Text Review'],
    };
  },
  methods: {
    createNewReview() {
      console.log('New Review');

      const store = useStore();
      store.dispatch('review/create', { title: this.newRewTitle, description: this.newRewDescr });
    },
  },
  setup() {
    // get array of enum Workflowstep
    const workflowsteps = Object.values(WorkflowStep);

    return { workflowsteps };
  },
});
