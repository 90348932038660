<template>
  <div
    class="relative rounded-lg border-l-4 inline-block items-center pr-4 pl-2 py-2 max-w-2xl"
    :class="[`bg-${color}-200`, `border-${color}-500`]"
  >
    <span class="absolute top-1/2 bottom-1/2 h-8 w-8 inline-block my-auto left-2">
      <CheckCircleIcon v-if="toast.severity === 'SUCCESS'" class="text-green-500" />
      <ExclamationIcon v-else-if="toast.severity === 'WARNING'" class="text-yellow-500" />
      <ExclamationCircleIcon v-else-if="toast.severity === 'ERROR'" class="text-red-500" />
      <InformationCircleIcon v-else class="text-blue-500" />
    </span>
    <p class="pl-10 text-left" :class="[`text-${color}-500`]">
      {{ toast.message }}
    </p>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import { Toast, ToastSeverity } from '@/models/Toaster';
import {
  ExclamationIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';

export default defineComponent({
  name: 'Toast',
  props: {
    toast: Object as PropType<Toast>,
  },
  components: {
    ExclamationIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    CheckCircleIcon,
  },
  setup(props) {
    const store = useStore();
    const toast = ref();

    const color = computed(() => {
      switch (toast.value.severity) {
        case ToastSeverity.SUCCESS:
          return 'green';
        case ToastSeverity.WARNING:
          return 'yellow';
        case ToastSeverity.ERROR:
          return 'red';
        default:
          return 'blue';
      }
    });

    const removeToast = () => {
      if (toast.value.timeout) {
        setTimeout(() => {
          store.dispatch('toaster/popToast', toast.value.timestamp);
        }, toast.value.timeout);
      }
    };

    onMounted(() => {
      toast.value = props.toast;
      removeToast();
    });

    return { removeToast, color };
  },
});
</script>
