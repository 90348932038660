<template>
  <div id="main-content" class="absolute w-full top-16">
    <CollectionList />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import CollectionList from '@/components/Collections/CollectionList.vue';

export default defineComponent({
  name: 'CollectionsContainer',
  components: {
    CollectionList,
  },
});
</script>
