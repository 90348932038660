<template>
  <div class="container fixed bottom-4 mx-auto">
    <ToastComponent v-for="toast in toasts" :toast="toast" :key="toast.timestamp" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import ToastComponent from '@/components/Toaster/Toast.vue';
import { Toast } from '@/models/Toaster';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ToastsContainer',
  components: { ToastComponent },
  setup() {
    const store = useStore();
    const toasts = computed(() => store.getters['toaster/toasts'] as Toast[]);

    return { store, toasts };
  },
});
</script>
