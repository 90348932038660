
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AmbaInput',
  props: {
    modelValue: String,
    placeholder: String,
    hideClear: Boolean,
    list: String,
  },
});
