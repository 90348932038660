
import { defineComponent } from 'vue';
import AppBar from '@/components/Skeleton/AppBar.vue';
import AppFooter from '@/components/Skeleton/AppFooter.vue';

export default defineComponent({
  name: 'AppSkeleton',
  components: {
    AppBar,
    AppFooter,
  },
  props: {
    query: {
      type: String,
      value: '',
    },
  },
});
