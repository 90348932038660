
import { defineComponent, PropType, ref, toRefs, watch } from 'vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/outline';

export default defineComponent({
  name: 'ClusterLegendItem',
  props: {
    id: Number,
    name: String,
    keywords: Array as PropType<string[]>,
    clusterColor: String,
    size: Number,
    active: Boolean,
  },
  emits: ['hover'],
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronUpIcon,
  },
  setup(props) {
    const { active } = toRefs(props);

    const isOpen = ref(active.value);

    watch(
      () => active.value,
      (value) => (isOpen.value = value)
    );

    const setOpen = (value: boolean) => {
      isOpen.value = value;
    };

    return { isOpen, setOpen };
  },
});
