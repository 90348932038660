
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { LogoutIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'LoginButton',
  components: {
    LogoutIcon,
  },
  setup() {
    const store = useStore();

    const handleLogout = () => {
      store.dispatch('auth/logout');
    };
    return { handleLogout };
  },
});
