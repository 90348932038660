import { AnalysisResponse, AnalysisResponseEntity, AnalysisResponseLink } from '@/models/Search';
import { SerializedEdge, SerializedNode } from 'graphology-types';
import WebpackWorker from 'worker-loader!*';

// *-------------------------------------------------------------------------------------
// *                  MODELS/INTERFACES TO USE IN CLIENT AND WORKER
// *-------------------------------------------------------------------------------------

export enum WorkerAction {
  DATA = 'DATA',
}

export interface ClientDataMessage {
  action: WorkerAction.DATA;
  data: {
    data: AnalysisResponse;
  };
}

export type ClientMessage = ClientDataMessage;

export enum MessageType {
  ERROR = 'ERROR',
  DATA = 'DATA',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
}

export interface WorkerErrorMessage {
  type: MessageType.ERROR;
  data: string;
}

export interface WorkerDataMessage {
  type: MessageType.DATA;
  data:
    | {
        type: 'nodes';
        data: {
          parsed: SerializedNode;
          original: AnalysisResponseEntity;
        }[];
      }
    | {
        type: 'edges';
        data: {
          parsed: SerializedEdge;
          original: AnalysisResponseLink;
        }[];
      };
}

export interface WorkerRunningMessage {
  type: MessageType.RUNNING;
}

export interface WorkerFinishedMessage {
  type: MessageType.FINISHED;
}

export type WorkerMessage =
  | WorkerErrorMessage
  | WorkerDataMessage
  | WorkerRunningMessage
  | WorkerFinishedMessage;

// *                                    END
// *-------------------------------------------------------------------------------------

// *-------------------------------------------------------------------------------------
// *                            WORKER IMPLEMENTATION
// *-------------------------------------------------------------------------------------
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ctx: WebpackWorker<ClientMessage, WorkerMessage> = self as any;

const CHUNK_SIZE = 100;

const process = (result: AnalysisResponse) => {
  ctx.postMessage({
    type: MessageType.RUNNING,
  });

  result.entities.reduce((prev, enitity, i) => {
    const node = {
      key: enitity.id.toString(),
      attributes: {
        cluster:
          result.communities[enitity.properties.communityId].properties.size > 1
            ? enitity.properties.communityId
            : -1,
      },
    };
    prev.push({
      parsed: node,
      original: enitity,
    });
    if (prev.length >= CHUNK_SIZE || i === result.entities.length - 1) {
      ctx.postMessage({
        type: MessageType.DATA,
        data: {
          type: 'nodes',
          data: prev,
        },
      });
      return [];
    }
    return prev;
  }, [] as { parsed: SerializedNode; original: AnalysisResponseEntity }[]);

  result.links.reduce(
    (prev, link, i) => {
      prev.push({
        parsed: {
          source: link.sourceId.toString(),
          target: link.targetId.toString(),
          attributes: {
            weight: link.properties.weight,
            color: '#8c8c8c',
          },
        },
        original: link,
      });

      if (prev.length >= CHUNK_SIZE || i === result.links.length - 1) {
        ctx.postMessage({
          type: MessageType.DATA,
          data: {
            type: 'edges',
            data: prev,
          },
        });
        return [];
      }
      return prev;
    },
    [] as {
      parsed: SerializedEdge;
      original: AnalysisResponseLink;
    }[]
  );

  ctx.postMessage({
    type: MessageType.FINISHED,
  });
};

ctx.addEventListener('message', (event) => {
  if (event.data.action === WorkerAction.DATA) {
    process(event.data.data.data);
  }
});
// *                                    END
// *-------------------------------------------------------------------------------------
