
import { defineComponent, PropType } from 'vue';
import {
  ZoomInIcon,
  ZoomOutIcon,
  HomeIcon,
  PhotographIcon,
  SunIcon,
  CubeIcon,
  CubeTransparentIcon,
  VariableIcon,
  ArrowLeftIcon,
} from '@heroicons/vue/outline';
import ControlButtonGroup from '@/components/Graphs/GraphControls/ControlButtonGroup.vue';
import ControlMenuButton from '@/components/Graphs/GraphControls/ControlMenuButton.vue';
import ControlButton from '@/components/Graphs/GraphControls/ControlButton.vue';
import {
  AnalysisResponse,
  AnalysisType,
  PublicationAnalysisType,
  ClusterMapping,
  SearchResponse,
} from '@/models/Search';

interface ButtonOptions {
  disabled: boolean;
  active: boolean;
}

export default defineComponent({
  name: 'GraphControls',
  props: {
    currentLayout: {
      type: String,
      required: false,
    },
    currentAnalysis: String as PropType<AnalysisType>,
    backdrop: {
      type: Object as PropType<ButtonOptions>,
    },
    allEdges: {
      type: Object as PropType<ButtonOptions>,
    },
    disableAnalysis: {
      type: Boolean,
      default: false,
    },
    entities: {
      type: Object as PropType<SearchResponse[]>,
      required: true,
    },
    analysis: {
      type: Object as PropType<AnalysisResponse>,
      required: false,
    },
    clusters: {
      type: Object as PropType<ClusterMapping>,
      required: false,
    },
  },
  components: {
    ControlButtonGroup,
    ControlMenuButton,
    ControlButton,
  },
  emits: [
    'changeAnalysis',
    'toggleAllEdges',
    'manipulateView',
    'changeLayout',
    'toggleBackdrop',
    'back',
  ],
  setup() {
    const viewControls = [
      {
        key: 'zoomIn',
        description: 'Zoom in',
        icon: ZoomInIcon,
      },
      {
        key: 'reset',
        description: 'Reset view',
        icon: HomeIcon,
      },
      {
        key: 'zoomOut',
        description: 'Zoom out',
        icon: ZoomOutIcon,
      },
    ];

    const layoutControls = [
      {
        key: 'forceAtlas2',
        description: 'Forceatlas2',
      },
      {
        key: 'fruchtermanReingold',
        description: 'Fruchterman-Reingold',
      },
      {
        key: 'circlepack',
        description: 'Circlepack',
      },
      {
        key: 'circular',
        description: 'Circular',
      },
      {
        key: 'random',
        description: 'Random',
      },
    ];

    const analysisControls = [
      {
        key: PublicationAnalysisType.HYBRIDFRONTS,
        description: 'Hybrid fronts',
      },
      {
        key: PublicationAnalysisType.CITATIONNETWORK,
        description: 'Citation network',
      },
      {
        key: PublicationAnalysisType.KNOWLEDGEBASES,
        description: 'Knowledge bases',
      },
      {
        key: PublicationAnalysisType.RESEARCHFRONTS,
        description: 'Research fronts',
      },
    ];

    return {
      viewControls,
      layoutControls,
      analysisControls,
      PhotographIcon,
      SunIcon,
      CubeIcon,
      CubeTransparentIcon,
      VariableIcon,
      ArrowLeftIcon,
    };
  },
});
