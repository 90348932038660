<template>
  <div class="h-full w-full">
    <AppBar :query="query" />
    <div class="absolute top-16 w-full h-full calculated-height">
      <slot />
    </div>
    <AppFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppBar from '@/components/Skeleton/AppBar.vue';
import AppFooter from '@/components/Skeleton/AppFooter.vue';

export default defineComponent({
  name: 'AppSkeleton',
  components: {
    AppBar,
    AppFooter,
  },
  props: {
    query: {
      type: String,
      value: '',
    },
  },
});
</script>

<style scoped>
.calculated-height {
  height: calc(theme('height.full') - theme('height.16') - theme('height.10'));
}
</style>
