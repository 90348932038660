<template>
  <Dialog :open="isOpen" @close="setIsOpen(false)" class="fixed inset-0 z-10">
    <div class="flex items-center justify-center h-screen">
      <DialogOverlay class="fixed inset-0 bg-black opacity-30" />
      <div
        class="relative max-w-3xl max-h-2/3 mx-auto bg-white rounded-2xl p-8 flex flex-col"
        ref="dialogContainer"
      >
        <DialogTitle class="font-bold text-xl flex-initial">Select publications</DialogTitle>
        <div class="flex-initial mb-2 mt-1">
          <button
            class="p-1 px-2 text-white font-medium text-sm bg-gray-400 rounded-md mx-2"
            @click="selectAll"
          >
            Select all
          </button>
          <button
            class="p-1 px-2 text-white font-medium text-sm bg-gray-400 rounded-md"
            @click="deselectAll"
          >
            Deselect all
          </button>
          <Menu v-if="clusters" as="div" class="relative inline-block mx-2">
            <MenuButton class="p-1 px-2 text-white font-medium text-sm bg-gray-400 rounded-md">
              Select by cluster
              <ChevronDownIcon class="inline-block h-4" />
            </MenuButton>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="
                  absolute
                  mt-1
                  origin-top-right
                  bg-white
                  divide-y divide-gray-100
                  rounded-md
                  shadow-lg
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none
                  w-64
                "
              >
                <MenuItem
                  v-for="[id, cluster] in sortedClusters"
                  :key="id"
                  as="button"
                  class="
                    w-full
                    group
                    rounded-md
                    px-2
                    py-2
                    text-sm text-left text-gray-900
                    hover:bg-gray-100
                    flex
                    items-center
                    border-r-4
                  "
                  :style="{
                    'border-right-color': clusterColors[id],
                  }"
                  @click="selectCluster(id)"
                >
                  <div class="w-5 h-5">
                    <CheckIcon aria-hidden="true" v-if="isClusterSelected[id]" />
                  </div>
                  <div class="ml-1">
                    <div class="text-sm">
                      <span class="font-semibold">{{ cluster.name }}</span>
                      <span class="font-normal italic"> - {{ cluster.size }} nodes</span>
                    </div>
                    <div class="text-xs italic">
                      {{ cluster.keywords.join(', ') }}
                    </div>
                  </div>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
          <input
            type="filter"
            name="filter"
            class="
              p-1
              text-sm text-gray-600
              bg-white
              rounded-md
              ring-1 ring-gray-400
              focus:ring-0 focus:outline-none focus:bg-gray-200
            "
            placeholder="Free text"
            autocomplete="on"
            v-model="freeTextFilter"
          />
        </div>
        <ul
          class="divide-y divide-gray-500 divide-dashed flex-auto overflow-y-auto"
          ref="listContainer"
          v-if="!enrichedEntitiesLoader"
        >
          <li
            v-for="entity in filteredEntities"
            :key="entity.entity.id"
            class="flex items-center w-full my-2 border-r-4"
            :style="{
              'border-right-style': 'solid',
              'border-right-color':
                entity.cluster && clusterColors[entity.cluster]
                  ? clusterColors[entity.cluster]
                  : 'white',
            }"
          >
            <input
              type="checkbox"
              class="inline-block float-left mr-2"
              :id="entity.entity.id"
              :name="entity.entity.title"
              :checked="checkboxRefs[entity.entity.id]"
              @change="checkboxRefs[entity.entity.id] = !checkboxRefs[entity.entity.id]"
            />
            <label :for="entity.entity.id" class="inline-block float-left">
              <h3 class="font-bold text-base">{{ entity.entity.title }}</h3>
              <p class="text-sm">
                <span> {{ entity.entity.publisher }} ({{ entity.entity.year }})</span> -
                <span class="italic">
                  {{
                    5 > entity.entity.authors.length
                      ? entity.entity.authors.map((a) => a.name).join(', ')
                      : entity.entity.authors
                          .slice(0, 5)
                          .map((a) => a.name)
                          .concat('...')
                          .join(', ')
                  }}</span
                >
              </p>
              <p class="text-sm">
                <span>
                  {{ entity.entity.citationCount }} Citations |
                  {{ entity.entity.refs.length || 0 }} References
                </span>
              </p>
            </label>
          </li>
        </ul>

        <div>
          <span class="text-sm italic font-normal">
            {{ Object.values(checkboxRefs).filter((cb) => cb).length }} selected
          </span>
          |
          <span class="text-sm italic font-normal"> {{ entities.length }} total </span>
          <template v-if="freeTextFilter.length > 0">
            |
            <span class="text-sm italic font-normal"> {{ filteredEntities.length }} filtered </span>
          </template>
        </div>

        <div>
          <button
            @click="setIsOpen(true)"
            class="
              inline-block
              text-white
              font-medium
              bg-blue-700
              p-1.5
              px-3
              rounded-xl
              float-right
              border border-blue-700
            "
          >
            Select
          </button>
          <button
            @click="setIsOpen(false)"
            class="inline-block border border-gray-400 p-1.5 px-3 rounded-xl mx-4 float-right"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { AnalysisResponse, ClusterMapping, SearchResponse } from '@/models/Search';
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeUpdate,
  PropType,
  Ref,
  ref,
  toRefs,
  watch,
} from 'vue';
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
} from '@headlessui/vue';
import { ChevronDownIcon, CheckIcon } from '@heroicons/vue/outline';
import { getClusterColors } from './utils/graphConfiguration';
import { PluginApi as Loading } from 'vue-loading-overlay';

export default defineComponent({
  name: 'NodeSelectionDialog',
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    ChevronDownIcon,
    CheckIcon,
  },
  props: {
    entities: {
      type: Object as PropType<SearchResponse[]>,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    analysis: {
      type: Object as PropType<AnalysisResponse>,
      required: false,
    },
    clusters: {
      type: Object as PropType<ClusterMapping>,
      required: false,
    },
  },
  emits: ['update:isOpen', 'select'],
  setup(props, { emit }) {
    const { isOpen, clusters, entities, analysis } = toRefs(props);

    const dialogContainer = ref<HTMLDivElement | null>(null);
    const listContainer = ref<HTMLDivElement | null>(null);

    const app = getCurrentInstance();
    const loading = app?.appContext.config.globalProperties.$loading as Loading;

    const enrichedEntitiesLoader = ref(false);
    const enrichedEntities = computed(() => {
      const loader = listContainer.value
        ? loading.show({
            isFullPage: false,
            container: listContainer.value,
          })
        : undefined;
      try {
        const result = entities.value.reduce((prev, cur) => {
          if (cur) {
            const { entity } = cur;
            const entityAnalysis = analysis.value?.entities.find(
              (e) => parseInt(e.id) === entity.id
            );

            return prev.concat([
              {
                ...cur,
                cluster: entityAnalysis?.properties.communityId,
              },
            ]);
          }
          return prev;
        }, [] as (SearchResponse & { cluster?: number })[]);
        loader?.hide();
        return result;
      } catch (e) {
        console.error(e);
        return [];
      }
    });

    const freeTextFilter = ref('');
    const filteredEntities = computed(() =>
      freeTextFilter.value.length === 0
        ? enrichedEntities.value
        : enrichedEntities.value.filter(({ entity }) => {
            const regex = RegExp(freeTextFilter.value, 'i');
            return (
              entity.title.match(regex) ||
              entity.year.toString().match(regex) ||
              entity.publisher?.match(regex) ||
              entity.authors.find((author) => author.name.match(regex))
            );
          })
    );

    const checkboxRefs = ref<{ [id: string]: Ref<boolean> }>({});

    const sortedClusters = computed(() =>
      Object.entries(clusters.value || {}).sort(
        (clusterA, clusterB) => clusterB[1].size - clusterA[1].size
      )
    );

    watch(
      () => entities.value,
      (newEntities) => {
        checkboxRefs.value = {};
        newEntities.forEach((e) => (checkboxRefs.value[e.entity.id] = false));
      }
    );

    onBeforeUpdate(() => {
      checkboxRefs.value = {};
      entities.value.forEach((e) => (checkboxRefs.value[e.entity.id] = false));
      freeTextFilter.value = '';
    });

    const setIsOpen = (confirm: boolean) => {
      emit('update:isOpen', !isOpen.value);
      if (confirm) {
        const selectedEntities = Object.keys(checkboxRefs.value)
          .filter((id) => checkboxRefs.value[id])
          .map((id) => parseInt(id));

        emit('select', selectedEntities);
      }
    };

    const selectAll = () =>
      Object.keys(checkboxRefs.value).forEach((entityId) => (checkboxRefs.value[entityId] = true));
    const deselectAll = () =>
      Object.keys(checkboxRefs.value).forEach((entityId) => (checkboxRefs.value[entityId] = false));

    const isClusterSelected = computed(() => {
      const result = entities.value.reduce((prev, { entity }) => {
        const entityAnalysis = analysis.value?.entities.find((e) => parseInt(e.id) === entity.id);
        const checkbox = checkboxRefs.value[entity.id.toString()];
        const clusterId = clusters.value?.[entityAnalysis?.properties.communityId ?? -1]
          ? entityAnalysis?.properties.communityId ?? -1
          : -1;

        if (!prev[clusterId]) prev[clusterId] = true;
        if (!checkbox) prev[clusterId] = false;
        return prev;
      }, {} as { [key: string]: boolean });
      return result;
    });

    const selectCluster = (id: string) => {
      const isSelected = isClusterSelected.value[id];

      Object.keys(checkboxRefs.value).forEach((entityId) => {
        const entityAnalysis = analysis.value?.entities.find((e) => {
          return parseInt(e.id) === parseInt(entityId);
        });

        const clusterId = clusters.value?.[entityAnalysis?.properties.communityId ?? -1]
          ? entityAnalysis?.properties.communityId ?? -1
          : -1;
        if (parseInt(id) === clusterId) {
          checkboxRefs.value[entityId] = !isSelected;
        }
      });
    };

    const clusterColors = computed(() =>
      getClusterColors(analysis.value?.analysisType, clusters.value)
    );

    return {
      setIsOpen,
      selectAll,
      deselectAll,
      checkboxRefs,
      sortedClusters,
      isClusterSelected,
      selectCluster,
      freeTextFilter,
      filteredEntities,
      clusterColors,
      enrichedEntitiesLoader,
      dialogContainer,
      listContainer,
    };
  },
});
</script>

<style></style>
