<template>
  <button class="inline-block m-2 text-gray-900 hover:text-yellow-400" @click="$emit('toggle')">
    <MenuIcon class="h-5 w-5 align-middle" />
  </button>
</template>

<script>
import { defineComponent } from 'vue';
import { MenuIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'MenuButton',
  components: {
    MenuIcon,
  },
});
</script>
