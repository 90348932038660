<template>
  <div class="m-3">
    <ul class="p-3 font-semibold bg-gray-300 rounded">
      <div>Collections</div>
    </ul>
    <div class="p-2 overflow-auto">
      <span v-if="collections.length === 0">no elements</span>
      <li
        class="list-none list-inside border mb-1 p-1 border-gray-300"
        v-for="el in collections"
        :key="el.id"
      >
        <router-link
          :to="{ path: '/collection/' + el.id }"
          class="md:text-base sm:text-sm focus:outline-none text-left hover:text-yellow-400"
        >
          <span></span> {{ el.title }}
          <span>{{ el.content.length }}</span>
        </router-link>
      </li>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'CollectionList',
  setup() {
    const collections = ref([
      { id: 0, title: 'Collection0', content: [{ id: 3006354146, title: 'Publication' }] },
      { id: 1, title: 'Collection1', content: [{ id: 3006354146, title: 'Publication' }] },
      { id: 2, title: 'Collection2', content: [{ id: 3006354146, title: 'Publication' }] },
    ]);
    return { collections };
  },
});
</script>
