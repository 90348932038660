
import { computed, defineComponent, onBeforeMount, Ref, ref, watch } from 'vue';
import AppSkeletonMenu from '@/components/Skeleton/AppSkeletonMenu.vue';
import LogoutButton from '@/components/Buttons/LogoutButton.vue';
import LoginPopUp from '@/components/PopUps/LoginPopUp.vue';
import { useStore } from 'vuex';
import { KeycloakProfile } from 'keycloak-js';

export default defineComponent({
  name: 'Profile',
  components: {
    AppSkeletonMenu,
    LogoutButton,
    LoginPopUp,
  },
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
    const profileInfo = computed(() => store.getters['auth/getUser'] as KeycloakProfile);
    const displayedInfo: Ref<KeycloakProfile | null> = ref(profileInfo.value);
    onBeforeMount(() => {
      if (isLoggedIn.value) store.dispatch('auth/loadUser');
    });
    watch(isLoggedIn, (newIsLoggedIn, oldIsLoggedIn) => {
      if (newIsLoggedIn && !oldIsLoggedIn) store.dispatch('auth/loadUser');
    });
    watch(
      profileInfo,
      (newProfileInfo: KeycloakProfile) => {
        displayedInfo.value = newProfileInfo;
      },
      { immediate: true }
    );
    return { isLoggedIn, displayedInfo };
  },
});
