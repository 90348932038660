<template>
  <div class="h-screen w-screen">
    <router-view />
    <ToastsContainer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ToastsContainer from '@/components/Toaster/ToastsContainer.vue';

export default defineComponent({
  name: 'App',
  components: {
    ToastsContainer,
  },
});
</script>

<style lang="scss">
#app {
  @apply font-sans;
  @apply text-center;
  @apply text-blue-900;
}
</style>
