<template>
  <div class="relative w-full">
    <Menu v-slot="{ open }">
      <MenuButton as="div">
        <ControlButton
          :showTooltip="!open"
          :description="menuTitle"
          :icon="menuIcon"
          :disabled="disabled"
        />
      </MenuButton>
      <MenuItems
        class="
          absolute
          right-9
          top-0
          py-2
          px-1
          rounded-lg
          bg-gray-50
          shadow-md
          text-sm
          bg-opacity-80
          backdrop-filter backdrop-blur-sm
          whitespace-nowrap
        "
      >
        <p v-if="menuTitle" class="font-semibold cursor-default mx-1">{{ menuTitle }}</p>
        <hr v-if="menuTitle" class="border-gray-500 mb-1 mx-1" />
        <MenuItem v-for="(item, index) in menuItems" :key="item.key">
          <button
            @click="$emit('menuCick', item.key)"
            class="
              py-1
              px-2
              font-semibold
              w-full
              text-left
              rounded-lg
              flex
              items-center
              border
              hover:border-yellow-400
              border-transparent
            "
            :class="{
              'bg-yellow-200': selectedIndex === index,
            }"
          >
            <span class="h-4 w-4 inline-block mr-1" v-if="hasAtleastOneItemWithIcon">
              <component :is="item.icon" v-if="item.icon" />
            </span>
            <span>{{ item.description }}</span>
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, VNode } from 'vue';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import ControlButton from '@/components/Graphs/GraphControls/ControlButton.vue';

interface Item {
  key: string;
  description: string;
  icon?: VNode;
}

export default defineComponent({
  name: 'ControlMenuButton',
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    ControlButton,
  },
  props: {
    menuTitle: {
      type: String,
    },
    menuIcon: {
      // ? Unfortunately the only type annotation that works for VNodes
      type: Function as PropType<() => VNode>,
      required: true,
    },
    menuItems: {
      type: Array as PropType<Array<Item>>,
      required: true,
    },
    selected: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const hasAtleastOneItemWithIcon = computed(() => props.menuItems.some((item) => item.icon));
    const selectedIndex = computed(() =>
      props.menuItems.findIndex((item) => item.key === props.selected)
    );

    return {
      selectedIndex,
      hasAtleastOneItemWithIcon,
    };
  },
});
</script>

<style lang="scss"></style>
