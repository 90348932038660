<template>
  <div ref="resultListContainer" class="w-full overflow-y-auto h-full text-gray-900">
    <!-- Filter and Sort Options of the result list -->
    <div class="px-3 grid grid-cols-6 gap-y-3">
      <!-- Filter Results: -->
      <div class="col-span-6">
        <div class="mb-2 md:text-2xl sm:text-xl semifont-bold text-gray-900">Filter results</div>
        <AmbaInput
          autocomplete="off"
          v-on:enter="filterList"
          list="filterRecommendations"
          v-model="filterTmp"
          placeholder="Title, Year, Author, Publisher, Keywords..."
        >
          <FilterIcon />
        </AmbaInput>
        <datalist id="filterRecommendations">
          <div v-for="rec in fullRecs" :key="rec.content">
            <option :value="rec.content"></option>
          </div>
        </datalist>
      </div>
      <!-- Sort Resultlist -->
      <div class="md:col-span-3 sm:col-span-6">
        <SortByButton
          @sortByYear="sortByYear"
          @sortByTitle="sortByTitle"
          @sortByPublisher="sortByPublisher"
          @sortByCitationCount="sortByCitationCount"
          @reverseResultList="reverseResultList"
        />
      </div>
      <div class="md:col-span-3 sm:col-span-6">
        <button
          class="
            lg:float-right
            md:clear-both
            block
            px-2
            py-1
            lg:text-base
            md:text-sm
            sm:text-xs
            font-medium
            text-gray-900
            bg-gray-300
            rounded-md
            hover:text-white
            focus:outline-none
            focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
          "
          @click="removeFilter"
        >
          Remove&nbsp;filter
        </button>
      </div>
    </div>

    <!-- Result List:  -->

    <ul ref="resultList">
      <li
        v-for="result in filteredResults"
        :key="result.entity.id"
        :id="result.entity.id"
        :ref="(el) => (resultListElements[result.entity.id] = el)"
        class="m-3 p-3 border border-gray-200 rounded"
        :class="{
          'border-yellow-300': isActive[result.entity.id],
          'bg-yellow-50': isActive[result.entity.id],
        }"
        @click="selectResult(result.entity.id)"
      >
        <div class="text-gray-500 md:text-sm sm:text-xs">
          {{ result.entity.publisher }} ({{ result.entity.year }})
        </div>
        <div class="lg:text-xl md:text-base sm:text-sm font-semibold text-gray-900">
          <h2>{{ result.entity.title }}</h2>
        </div>
        <div class="text-gray-500 md:text-sm sm:text-xs">
          {{ authorsShort(result) }}
        </div>
        <div class="text-gray-500 md:text-sm sm:text-xs">
          {{ citAndRefs(result.entity.citationCount, result.entity.refs) }}
        </div>
        <!-- <div >{{ abstract(result) }}</div> -->

        <button
          class="
            py-2
            mr-2
            mt-1
            md:text-base
            sm:text-sm
            font-semibold
            text-gray-500
            focus:outline-none
            hover:text-yellow-400
          "
          @click="expandResult(result)"
        >
          more information
        </button>
        <OpenDoi text=" go to source" :doi="result.entity.doi" />
      </li>
    </ul>
    <!-- Scroll to Top:  -->

    <div class="absolute bottom-0 right-0">
      <ChevronUpIcon
        class="
          rounded-full
          mb-4
          mr-8
          lg:p-3 lg:h-12 lg:w-12
          md:h-10 md:w-10 md:p-2
          sm:w-8 sm:h-8 sm:p-1
          bg-yellow-400
          text-white
          focus:outline-none
          hover:bg-yellow-300
        "
        @click="scrollToTop"
      />
    </div>

    <!-- Publication Page -->
    <!-- The top-16 and bottom-10 are 'reserved' for the AppBar and AppFooter since the fixed element can fill the entire screen -->
    <div class="fixed left-0 bottom-10 top-16 w-3/4" v-if="publicationVisible">
      <!-- <PublicationPage :searchres="publication" v-on:close="closePublication" /> -->
      <DetailContainer :searchres="publication" v-on:close="closePublication" />
    </div>

    <!-- Detail Page: -->
    <div class="fixed" v-if="detailsVisible">
      <div
        class="
          fixed
          md:w-1/4
          sm:w-1/3
          grid grid-cols-12
          top-16
          bottom-0
          right-1/4
          border-l border-gray-300
          bg-white
          overflow-y-auto
        "
      >
        <!-- Open fully extended view -->
        <div class="col-span-1">
          <button
            @click="openPublication(details)"
            class="
              absolute
              bottom-1/3
              h-1/3
              font-semibold
              bg-gray-300
              focus:outline-none
              hover:text-white hover:bg-gray-400
            "
          >
            <ChevronLeftIcon class="h-8 w-5 align-middle inline-block" />
          </button>
        </div>
        <!-- Details: -->

        <div class="col-span-10">
          <div class="pt-5 grid grid-cols-12">
            <div class="text-left p-2 col-span-full">
              <div class="lg:text-2xl md:text-xl sm:text-base font-bold mb-5 text-gray-900">
                <h2>{{ details.entity.title }}</h2>
              </div>
              <div class="text-gray-500 text-sm">
                {{ details.entity.publisher }} ({{ details.entity.year }})
              </div>

              <div class="text-gray-500 text-sm">
                {{ authorsLong(details) }}
              </div>
              <div class="text-gray-500 text-sm">
                {{ citAndRefs(details.entity.citationCount, details.entity.refs) }}
              </div>
              <div v-if="isAbstractReading" class="text-justify lg:text-base md:text-sm sm:text-sm">
                {{ details.entity.abstract }}
              </div>
            </div>
            <OpenDoi text="go&nbsp;to&nbsp;source" v-bind:doi="details.entity.doi" />
          </div>
        </div>

        <!-- Close extension -->

        <div class="col-span-1">
          <button
            @click="retractResult"
            class="
              font-semibold
              rounded
              float-right
              p-3
              mr-3
              focus:outline-none
              hover:text-yellow-400
            "
          >
            <XIcon class="h-5 w-5 align-middle inline-block" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { SearchResponse } from '@/models/Search';
import DetailContainer from '@/components/Details/DetailContainer.vue';
import { computed, defineComponent, onBeforeUnmount, PropType, Ref, ref, watch } from 'vue';
import OpenDoi from '@/components/Buttons/OpenDoi.vue';
import AmbaInput from '@/components/Input/AmbaInput.vue';
import SortByButton from '@/components/Buttons/SortByButton.vue';
import { FilterIcon, ChevronUpIcon, ChevronLeftIcon, XIcon } from '@heroicons/vue/solid';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ResultList',
  components: {
    OpenDoi,
    AmbaInput,
    FilterIcon,
    ChevronUpIcon,
    ChevronLeftIcon,
    XIcon,
    SortByButton,
    DetailContainer,
  },
  props: {
    results: {
      type: Object as PropType<SearchResponse[]>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const selectedEntityIds = computed(() => store.getters['search/selectedEntityIds']);
    const filterEntityIds = computed(() => store.getters['search/filterEntityIds']);
    const filter = ref('');
    const resultListContainer: Ref<HTMLElement | null> = ref(null);
    const resultListElements: Ref<(HTMLElement | null)[]> = ref([]);
    const isActive: Ref<boolean[]> = ref([]);

    const isAbstractReading: Ref<boolean> = ref(false);
    const abstractReading = computed(() => store.getters['auth/isAbstractReading']);
    watch(abstractReading, (abstractReading) => {
      isAbstractReading.value = abstractReading;
    });

    // details for extended view
    const detailsVisible = ref(false);
    const details = ref(props.results[0]);

    // publication for fully extended view
    const publicationVisible = ref(false);
    const publication = ref(Object as unknown as SearchResponse);
    const filterTmp = ref('');
    // resultList starts empty and is populated when props change
    const filteredResults: Ref<SearchResponse[]> = ref([]);

    // watch store
    watch(selectedEntityIds, (ids) => {
      filteredResults.value.forEach((result) => {
        if (isActive.value[result.entity.id]) isActive.value[result.entity.id] = false;
      });
      ids.forEach((id: number) => {
        const element: HTMLElement | null = resultListElements.value[id];
        if (!resultListContainer.value || !element) {
          return;
        }

        isActive.value[id] = true;
        if (!isInViewport(element)) {
          resultListContainer.value.scrollTo({
            top: element.offsetTop,
            left: 0,
            behavior: 'smooth',
          });
        }
      });
    });
    // helper function (reference: https://www.javascripttutorial.net/dom/css/check-if-an-element-is-visible-in-the-viewport/)
    const isInViewport = (el: HTMLElement) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      );
    };
    watch(filterEntityIds, (ids) => {
      if (ids && ids.length != filteredResults.value.length) {
        filter.value = '';
        filteredResults.value = [];
        props.results.forEach((result) => {
          if (ids.includes(result.entity.id)) {
            filteredResults.value.push(result);
          }
        });
      }
    });

    // watches changes in props.results
    watch(
      () => props.results,
      (newVal, oldVal) => mount(newVal, oldVal)
    );

    const mount = async (newVal: SearchResponse[], oldVal: SearchResponse[]) => {
      if (!newVal || newVal === oldVal) return;
      if (filterEntityIds.value == []) {
        const ids: number[] = [];
        newVal.forEach((res) => {
          ids.push(res.entity.id);
        });
      }
      // filteredResults is populated with props.results (SearchResponse[])
      filteredResults.value = [...newVal];
      // [...arr] instead of arr to prevent a vue warn: unhandled error in execution callback
      // Error is possibly (probably) because with newArr the store's entities are referenced to filteredResults
      //and thus mutations (sorting by year for example) will cause an error
    };

    // filter Recommendations

    // List of all possible Recommendations (for the filter auto completion function)
    const fullRecs = computed(() => {
      const possibleRecommendations: { type: RecommendationType; content: string }[] = [];
      const allPublishers = getAllPublishers();
      allPublishers.forEach((publisher) => {
        possibleRecommendations.push({ type: RecommendationType.PUBLISHER, content: publisher });
      });
      const allAuthors = getAllAuthors();
      allAuthors.forEach((author) => {
        possibleRecommendations.push({ type: RecommendationType.AUTHOR, content: author });
      });
      const allTitles = getAllTitles();
      allTitles.forEach((title) => {
        possibleRecommendations.push({ type: RecommendationType.TITLE, content: title });
      });
      const allYears = getAllYears();
      allYears.forEach((year) => {
        possibleRecommendations.push({ type: RecommendationType.YEAR, content: String(year) });
      });

      return possibleRecommendations;
    });

    // RecommendationType for future use
    enum RecommendationType {
      TITLE = 'TITLE',
      YEAR = 'YEAR',
      AUTHOR = 'AUTHOR',
      PUBLISHER = 'PUBLISHER',
    }

    // exposed function, 'activates' filter
    const filterList = () => {
      filter.value = filterTmp.value;
      filterResults();
    };

    // returns all authors of all publications
    const getAllAuthors = (): string[] => {
      const authors: string[] = [];
      for (let i = 0; i < props.results.length; i++) {
        authors.push(...getAuthorNameArr(props.results[i]));
      }

      return authors.reduce((prev, current) => {
        if (!prev.includes(current)) {
          prev.push(current);
        }
        return prev;
      }, [] as string[]);
    };

    // returns every publication year
    const getAllYears = (): number[] => {
      return props.results
        .map((res) => res.entity.year)
        .reduce((prev, current) => {
          if (!prev.includes(current) && current) {
            prev.push(current);
          }
          return prev;
        }, [] as number[]);
    };

    const getAllTitles = (): string[] => {
      return props.results
        .map((res) => res.entity.title)
        .reduce((prev, current) => {
          if (!prev.includes(current) && current) {
            prev.push(current);
          }
          return prev;
        }, [] as string[]);
    };

    const getAllPublishers = (): string[] => {
      return props.results
        .map((res) => res.entity.publisher)
        .reduce((prev, current) => {
          if (!prev.includes(current) && current) {
            prev.push(current);
          }
          return prev;
        }, [] as string[]);
    };
    // returns the names of all authors of one result as an array
    const getAuthorNameArr = (result: SearchResponse) => {
      let arr = [];
      for (let i = 0; i < result.entity.authors.length; i++) {
        arr.push(result.entity.authors[i].name);
      }
      return arr;
    };

    // callable function that filters the resultlist (in filteredResults) according to the filter value
    const filterResults = () => {
      var resultlist: SearchResponse[] = filteredResults.value;
      // Filter resultlist
      if (filter.value == '') return;
      const value: string = filter.value;
      const year = parseFloat(value);

      if (getAllYears().includes(year)) {
        resultlist = resultlist.filter((result) => year === result.entity.year);
      } else if (getAllAuthors().includes(value)) {
        resultlist = resultlist.filter((result) => getAuthorNameArr(result).includes(value));
      } else if (getAllPublishers().includes(value)) {
        resultlist = resultlist.filter((result) => {
          if (result.entity.publisher) return result.entity.publisher.includes(value);
          else return false;
        });
      } else {
        resultlist = props.results.filter(
          (result) => result.entity.abstract.includes(value) || result.entity.title.includes(value)
        );
      }
      const ids: number[] = [];
      resultlist.forEach((el) => ids.push(el.entity.id));
      // commits new filteredResults to store to trigger the graph filter
      store.dispatch('search/filterEntities', { ids });

      filteredResults.value = resultlist;
    };
    // Sort - exposed functions
    const sortByYear = () => {
      filteredResults.value.sort((a, b) => b.entity.year - a.entity.year);
    };
    const sortByTitle = () => {
      filteredResults.value.sort((a, b) => {
        const titlea = a.entity.normalizedTitle;
        const titleb = b.entity.normalizedTitle;
        if (titlea > titleb) return 1;
        else if (titlea < titleb) return -1;
        else {
          return 0;
        }
      });
    };
    const sortByPublisher = () => {
      filteredResults.value.sort((a, b) => {
        const publishera = a.entity.publisher;
        const publisherb = b.entity.publisher;
        if (!publishera && !publisherb) {
          return b.entity.year - a.entity.year;
        } else if (publishera > publisherb || !publishera) return 1;
        else if (publishera < publisherb || !publisherb) return -1;
        else {
          return b.entity.year - a.entity.year;
        }
      });
    };
    const sortByCitationCount = () => {
      filteredResults.value.sort((a, b) => b.entity.citationCount - a.entity.citationCount);
    };
    const reverseResultList = () => {
      filteredResults.value.reverse();
    };

    // Removes ANY filter from the search page - including filters on (and from) the graph
    const removeFilter = () => {
      filter.value = '';
      filterTmp.value = '';
      const ids: number[] = [];
      props.results.forEach((el) => ids.push(el.entity.id));
      store.dispatch('search/filterEntities', { ids });
    };
    // exposed
    const scrollToTop = () => {
      if (!resultListContainer.value) return;
      resultListContainer.value.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    // exposed functions for result display

    const citAndRefs = (cit: number, refs: []) => {
      let res = '';

      // citations
      res += cit;
      if (cit == 1) res += ' Citation';
      else res += ' Citations';

      // divider
      res += ' | ';

      // references
      let refsCount = 0;
      if (refs != null) refsCount = refs.length;
      res += refsCount;
      if (refsCount == 1) res += ' Reference';
      else res += ' References';

      return res;
    };

    const expandResult = (result: SearchResponse) => {
      if (publicationVisible.value) {
        openPublication(result);
      } else {
        details.value = result;
        detailsVisible.value = true;
      }
    };

    const selectResult = (id: number) => {
      const ids = [id];
      store.dispatch('search/selectEntities', { ids });
    };
    const openPublication = (pub: SearchResponse) => {
      publication.value = pub;
      publicationVisible.value = true;
      detailsVisible.value = false;
    };
    const retractResult = () => {
      detailsVisible.value = false;
    };
    const authorsShort = (result: SearchResponse) => {
      let res = '';
      let authlength = result.entity.authors.length;

      let count = 0;
      for (let j = 0; j < authlength; j++) {
        if (j < 3) {
          res += result.entity.authors[j].name;
          if (j < authlength - 1) res += ', ';
        }
        count++;
      }
      if (count > 4) {
        res += ' ... ';
        res += result.entity.authors[authlength - 1].name;
        res += ' +' + count + ' more';
      }
      return res;
    };
    const authorsLong = (result: SearchResponse) => {
      let res = '';
      let authlength = result.entity.authors.length;
      for (let i = 0; i < authlength; i++) {
        res += result.entity.authors[i].name;
        if (i < authlength - 2) res += ', ';
        if (i == authlength - 2) res += ' and ';
      }
      return res;
    };

    const closePublication = () => {
      publicationVisible.value = false;
    };

    onBeforeUnmount(() => {
      filteredResults.value = [];
      resultListContainer.value = null;
      const ids: number[] = [];
      store.dispatch('search/filterEntities', { ids });
    });

    return {
      resultListContainer,
      resultListElements,
      isActive,
      detailsVisible,
      details,
      publicationVisible,
      publication,
      filteredResults,
      fullRecs,
      filterTmp,
      isAbstractReading,
      citAndRefs,
      authorsShort,
      authorsLong,
      scrollToTop,
      filterList,
      sortByYear,
      sortByTitle,
      sortByPublisher,
      sortByCitationCount,
      removeFilter,
      reverseResultList,
      closePublication,
      openPublication,
      expandResult,
      retractResult,
      selectResult,
    };
  },
});
</script>
