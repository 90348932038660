
import { computed, defineComponent } from 'vue';
import AppSkeletonMenu from '@/components/Skeleton/AppSkeletonMenu.vue';
import CollectionsContainer from '@/components/Collections/CollectionsContainer.vue';
import LoginPopUp from '@/components/PopUps/LoginPopUp.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Collections',
  components: {
    AppSkeletonMenu,
    CollectionsContainer,
    LoginPopUp,
  },
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
    return { isLoggedIn };
  },
});
