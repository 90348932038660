
import { computed, defineComponent, ref } from 'vue';
import AppSkeletonMenu from '@/components/Skeleton/AppSkeletonMenu.vue';
import LoginPopUp from '@/components/PopUps/LoginPopUp.vue';
import PublicationList from '@/components/Details/PublicationList.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Collection',
  components: {
    AppSkeletonMenu,
    PublicationList,
    LoginPopUp,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    const collection = ref([
      { id: 3006354146, title: 'Publication' },
      { id: 3006354146, title: 'Publication' },
    ]);
    const openDetailPage = (id: number) => {
      router.push({ path: '/details/publication/' + id });
    };
    return { collection, openDetailPage, isLoggedIn };
  },
});
