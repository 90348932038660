import store from '@/store';
import Keycloak from 'keycloak-js';

const initOptions: Keycloak.KeycloakInitOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: true,
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
};
export const keycloak: Keycloak.KeycloakInstance = Keycloak('/keycloak.json');

export function initKeycloak(): void {
  keycloak.init(initOptions).catch(function () {
    alert('failed to initialize');
  });

  //Token Refresh
  setInterval(() => {
    if (store.getters['auth/isLoggedIn'])
      keycloak.updateToken(90000).catch(() => {
        if (store.getters['auth/isLoggedIn']) {
          store.dispatch('auth/refreshFailed');
        }
      });
  }, 90000);

  keycloak.onAuthSuccess = () => {
    store.dispatch('auth/loggedIn', true);
  };
  keycloak.onAuthLogout = () => {
    store.dispatch('auth/loggedOut');
  };
  keycloak.onAuthError = () => {
    store.dispatch('auth/loggedIn', false);
  };
}
