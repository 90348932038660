export interface Review {
  id: string;
  date: string;
  name: string;
  description?: string;
  creatorId: number;
  creatorName: string;
  contributors: Contributor[];
  publications?: {
    id: number;
    title: string;
    abstractReview: number;
    fullTextReview: number;
    customFields: Record<string, string>;
  }[];
  classifier?: Classifier;
  projectHasAlgorithms?: boolean;
  projectHasDataset?: boolean;
  projectHasPriorKnowledge?: boolean;
  projectInitReady?: boolean;
  reviewFinished?: boolean;
}

// will be deleted later;
export interface asreviewproject {
  id: number;
  name: string;
  created_at_unix: number;
  description: string;
  creatorId: number;
  creatorName: string;
  contributors: string;
  authors: string;
  date: string;
}

export interface ReviewProgress {
  n_excluded: number;
  n_included: number;
  n_papers: number;
  n_pool: number;
  n_since_last_inclusion: number;
}

export interface ReviewProgress {
  authors: string;
  created_at_unix: number;
  dataset_path: string;
  description: string;
  id: string;
  n_excluded: number;
  n_included: number;
  n_papers: number;
  n_pool: number;
  n_since_last_inclusion: number;
  name: number;
  projectInitReady: boolean;
  reviewFinished: boolean;
  version: string;
}

export interface Contributor {
  id: number;
  name: string;
  isAdmin: boolean;
  workflowSteps: WorkflowStep[];
}

export interface Publication {
  title: string;
  abstract: string;
  authors: string;
  id: number;
  included: number;
  keywords: [];
}

export enum WorkflowStep {
  COLLECTIONSETUP = 'Set up Collection',
  PRIORSCREENING = 'Prior Screening',
  ABSTRACTSCREENING = 'Abstract Screening',
  FULLTEXTREVIEW = 'Full Text Review',
  DATAEXTRACTION = 'Data Extraction',
  QUALITYASSESSMENT = 'Quality Assessment',
}

// machine lerning model used to compute the relevance scores provided by ASReview
export const enum Classifier {
  BAYES = 'Naive Bayes',
  SVM = 'Support Vector Machine',
  REGRESSION = 'Logistic Regression',
  RANDOMFOREST = 'Random Forest',
}
