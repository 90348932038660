<template>
  <button
    @click="handleLogout"
    class="
      text-yellow-400
      border
      p-1
      border-yellow-400
      rounded-md
      font-semibold
      hover:text-yellow-500
    "
  >
    <LogoutIcon class="h-5 w-5 inline" />
    Logout
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { LogoutIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'LoginButton',
  components: {
    LogoutIcon,
  },
  setup() {
    const store = useStore();

    const handleLogout = () => {
      store.dispatch('auth/logout');
    };
    return { handleLogout };
  },
});
</script>
