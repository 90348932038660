
import { defineComponent } from 'vue';
import { UserIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'ProfileButton',
  components: {
    UserIcon,
  },
});
