
import { defineComponent } from 'vue';
import { CodeIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'Cite',
  components: { CodeIcon },
  props: {
    publicationid: Number,
    text: String,
  },
  setup() {
    const cite = () => {
      return; // todo
    };
    return { cite };
  },
});
