<template>
  <div>
    <AppSkeletonMenu>
      <template v-slot>
        <ReviewButton class="back2overview" />
        <div id="main-content" class="w-2/3 mx-auto mt-16">
          <h2 class="text-2xl font-bold text-gray-900">{{ asreview.name }}</h2>
          <div class="text-gray-700">
            Created by: {{ asreview.authors }} <br />
            At: {{ stringedDate }}
          </div>
          <ProgressBar :steps="workflowsteps" :project="asreview" />
          <div class="mt-5">
            <button
              class="p-1 hover:text-yellow-500 border border-yellow-400 rounded"
              v-if="!asreview.projectHasDataset"
              @click="settingUpCollection = !settingUpCollection"
            >
              Set up Collection
            </button>
            <button
              class="p-1 hover:text-yellow-500 border border-yellow-400 rounded"
              v-else-if="!asreview.selectingPriorKnowledge"
              @click="selectingPriorKnowledge = !selectingPriorKnowledge"
            >
              Prepare for Abstract Review
            </button>
            <button
              v-else-if="!asreview.reviewFinished"
              @click="settingUpCollection = !settingUpCollection"
              class="p-1 hover:text-yellow-500 border border-yellow-400 rounded"
            >
              Continue Review
            </button>
          </div>
          <CollectionSetUp v-if="settingUpCollection"></CollectionSetUp>
          <PriorKnowledgeDialog
            v-if="asreview.projectHasDataset && !asreview.selectingPriorKnowledge"
            :projectId="asreview.id"
            :showDialog="selectingPriorKnowledge"
            @hide="selectingPriorKnowledge = false"
          />
          <!--div class="atbottom">JSON: {{ asreview }}</div-->
        </div>
      </template>
    </AppSkeletonMenu>
    <LoginPopUp v-if="!isLoggedIn" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import AppSkeletonMenu from '@/components/Skeleton/AppSkeletonMenu.vue';
import CollectionSetUp from '@/components/Review/CollectionSetUp.vue';
import ProgressBar from '@/components/Review/ProgressBar.vue';
import PriorKnowledgeDialog from '@/components/Review/PriorKnowledge.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { WorkflowStep } from '@/models/Review';
import ReviewButton from '@/components/Buttons/Reviews.vue';

export default defineComponent({
  name: 'Review',
  components: {
    AppSkeletonMenu,
    CollectionSetUp,
    ProgressBar,
    PriorKnowledgeDialog,
    ReviewButton,
  },
  props: {
    projectId: String,
  },
  data() {
    return {
      workflowsteps: [
        //used for timeline which showa the progress
        { name: WorkflowStep.COLLECTIONSETUP, propName: 'projectHasDataset' },
        { name: WorkflowStep.PRIORSCREENING, propName: 'projectHasPriorKnowledge' },
        { name: WorkflowStep.ABSTRACTSCREENING, propName: 'reviewFinished' },
        { name: WorkflowStep.FULLTEXTREVIEW },
      ],

      // show/hide Dialogs
      settingUpCollection: false,
      selectingPriorKnowledge: false,
    };
  },
  setup(props) {
    const store = useStore();
    const router = useRoute();

    store.dispatch('review/fetch', { getReview: true, projectId: props.projectId });
    const asreview = computed(() => store.getters['review/currentreview']);

    const stringedDate = computed(() => {
      var date = new Date(asreview.value.created_at_unix * 1000);
      return date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear();
    });

    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    return { asreview, router, stringedDate, store, isLoggedIn };
  },
  watch: {},
});
</script>

<style lang="scss" scoped>
.custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
  background-color: #fbc02d;
}

.centered {
  margin: auto;
}

.atbottom {
  position: fixed;
  bottom: 67px;
  width: 100%;
}

.back2overview {
  float: left;
}
</style>
