<template>
  <router-link
    :to="{ name: 'Profile' }"
    class="
      bg-yellow-400
      rounded-md
      px-2
      py-1
      text-white
      font-semibold
      inline-block
      align-text-bottom
      m-1
    "
  >
    <div>
      <UserIcon class="w-5 h-5 mr-1 bg-white text-yellow-400 rounded-full inline" />
      Profile
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { UserIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'ProfileButton',
  components: {
    UserIcon,
  },
});
</script>
