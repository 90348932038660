
import { defineComponent } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import AmbaInput from '@/components/Input/AmbaInput.vue';
import CollectionList from '@/components/Collections/CollectionList.vue';
import { SearchIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'CollectionSetup',
  components: {
    TabView,
    TabPanel,
    AmbaInput,
    CollectionList,
    SearchIcon,
  },
  data() {
    return {
      keywordSearch: '',
    };
  },
  setup() {
    const startKeyWordSearch = () => {
      console.log('search for keyword');
    };

    return { startKeyWordSearch };
  },
});
