
import { ref, PropType, Ref, defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default defineComponent({
  name: 'PublicationList',
  components: {
    DataTable,
    Column,
  },
  props: {
    publicationList: {
      type: Object as PropType<{ id: number; title: string }[]>,
      required: true,
    },
    name: {
      type: String,
      default: 'Publication List',
    },
  },
  setup(props, { emit }) {
    const publications = ref(props.publicationList);
    const listName = ref(props.name);
    const selectedPublication: Ref<{ id: number; title: string } | undefined> = ref();
    const onRowSelect = (event: { data: { id: number } }) => {
      openNewPublication(event.data.id);
    };
    const openNewPublication = (id: number) => {
      emit('selectPublication', id);
    };
    return { publications, listName, onRowSelect, selectedPublication };
  },
});
