import { SearchResponse } from '@/models/Search';
import { SerializedEdge } from 'graphology-types';
import WebpackWorker from 'worker-loader!*';

// *-------------------------------------------------------------------------------------
// *                  MODELS/INTERFACES TO USE IN CLIENT AND WORKER
// *-------------------------------------------------------------------------------------

export enum WorkerAction {
  DATA = 'DATA',
}

export interface ClientDataMessage {
  action: WorkerAction.DATA;
  data: SearchResponse[];
}

export type ClientMessage = ClientDataMessage;

export enum MessageType {
  ERROR = 'ERROR',
  DATA = 'DATA',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
}

export interface WorkerErrorMessage {
  type: MessageType.ERROR;
  data: string;
}

export interface WorkerDataMessage {
  type: MessageType.DATA;
  data: SerializedEdge[];
}

export interface WorkerRunningMessage {
  type: MessageType.RUNNING;
}

export interface WorkerFinishedMessage {
  type: MessageType.FINISHED;
}

export type WorkerMessage =
  | WorkerErrorMessage
  | WorkerDataMessage
  | WorkerRunningMessage
  | WorkerFinishedMessage;

// *                                    END
// *-------------------------------------------------------------------------------------

// *-------------------------------------------------------------------------------------
// *                            WORKER IMPLEMENTATION
// *-------------------------------------------------------------------------------------
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ctx: WebpackWorker<ClientMessage, WorkerMessage> = self as any;

const CHUNK_SIZE = 100;

const process = (results: SearchResponse[]) => {
  ctx.postMessage({
    type: MessageType.RUNNING,
  });

  results.forEach(({ entity }) => {
    if (entity.refs) {
      entity.refs.reduce((prev, ref, i) => {
        prev.push({
          source: entity.id.toString(),
          target: ref.toString(),
          attributes: {
            weight: 1,
            color: '#999999',
          },
        });

        if (i === entity.refs.length - 1 || prev.length >= CHUNK_SIZE) {
          ctx.postMessage({
            type: MessageType.DATA,
            data: prev,
          });
          return [];
        }
        return prev;
      }, [] as SerializedEdge[]);
    }

    if (entity.citations) {
      const citations = entity.citations;
      citations.reduce((prev, citation, i) => {
        prev.push({
          source: citation.toString(),
          target: entity.id.toString(),
          attributes: {
            weight: 1,
            color: '#999999',
          },
        });

        if (i === citations.length - 1 || prev.length >= CHUNK_SIZE) {
          ctx.postMessage({
            type: MessageType.DATA,
            data: prev,
          });
          return [];
        }
        return prev;
      }, [] as SerializedEdge[]);
    }
  });

  ctx.postMessage({
    type: MessageType.FINISHED,
  });
};

ctx.addEventListener('message', (event) => {
  if (event.data.action === WorkerAction.DATA) {
    process(event.data.data);
  }
});
// *                                    END
// *-------------------------------------------------------------------------------------
