<template>
  <div class="border rounded my-1 border-gray-300">
    <TabView>
      <TabPanel header="Collectionlist"> <collection-list></collection-list> </TabPanel>
      <TabPanel header="User library"> Select from User Library </TabPanel>
      <TabPanel header="Create new">
        <AmbaInput
          autocomplete="off"
          v-on:enter="startKeyWordSearch"
          list="filterRecommendations"
          v-model="keywordSearch"
          placeholder="Keyword..."
        >
          <SearchIcon />
        </AmbaInput>
      </TabPanel>
    </TabView>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import AmbaInput from '@/components/Input/AmbaInput.vue';
import CollectionList from '@/components/Collections/CollectionList.vue';
import { SearchIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'CollectionSetup',
  components: {
    TabView,
    TabPanel,
    AmbaInput,
    CollectionList,
    SearchIcon,
  },
  data() {
    return {
      keywordSearch: '',
    };
  },
  setup() {
    const startKeyWordSearch = () => {
      console.log('search for keyword');
    };

    return { startKeyWordSearch };
  },
});
</script>
