<template>
  <button
    class="
      has-tooltip
      p-2
      hover:bg-gray-100
      bg-white
      rounded-lg
      shadow-md
      block
      w-full
      h-full
      backdrop-filter backdrop-blur-sm
      bg-opacity-50
    "
    :class="{
      'bg-yellow-400': active && !disabled,
      'hover:bg-yellow-200': active && !disabled,
      disabled: disabled,
    }"
    :disabled="disabled"
  >
    <span v-if="description && showTooltip" class="tooltip tooltip-style">{{ description }}</span>
    <component :is="inactiveIcon" v-if="!active && inactiveIcon" />
    <component :is="icon" v-else />
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType, VNode } from 'vue';

export default defineComponent({
  name: 'ControlButton',
  props: {
    description: String,
    showTooltip: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      // ? Unfortunately the only type annotation that works for VNodes
      type: Function as PropType<() => VNode>,
      required: true,
    },
    inactiveIcon: {
      // ? Unfortunately the only type annotation that works for VNodes
      type: Function as PropType<() => VNode>,
      required: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.tooltip-style {
  @apply absolute right-10 text-xs whitespace-nowrap rounded-lg shadow-md bg-gray-200 text-black px-2 py-1 box-content;
}

.disabled {
  @apply bg-gray-500 hover:bg-gray-500 hover:bg-opacity-50 bg-opacity-50 cursor-default;
}
</style>
