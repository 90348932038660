import { keycloak } from './../../services/keycloak';
import { RootState } from '@/store';
import { Module } from 'vuex';
import router from '@/router';

enum AuthAction {
  LOGINSUCCESS = 'LOGINSUCCESS',
  LOGINFAILURE = 'LOGINFAILURE',
  LOGOUT = 'LOGOUT',
  REFRESHFAILED = 'REFRESHFAILED',
  REGISTERSUCCESS = 'REGISTERSUCCESS',
  REGISTERFAILURE = 'REGISTERFAILURE',
  UPDATELOGGEDIN = 'UPDATELOGGEDIN',
  LOADUSER = 'LOADUSER',
  LOADING = 'LOADING',
}

export interface AuthState {
  loggedIn: boolean;
  user: Keycloak.KeycloakProfile | null;
  loading: boolean;
  abstractReading: boolean;
}

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state: {
    loggedIn: false,
    user: null,
    loading: false,
    abstractReading: false,
  },
  getters: {
    isLoggedIn(state: AuthState) {
      return state.loggedIn;
    },
    isLoading(state: AuthState) {
      return state.loading;
    },
    getUser(state: AuthState) {
      return state.user;
    },
    isAbstractReading(state: AuthState) {
      return state.abstractReading;
    },
  },
  actions: {
    login({ commit }, redirect: boolean) {
      if (redirect) {
        const route = window.location.origin + '/home';
        router.push({ name: 'Login' });
        keycloak.login({ scope: 'openid profile', redirectUri: route });
        commit(AuthAction.LOADING);
      } else {
        keycloak.login({ scope: 'openid profile' });
        commit(AuthAction.LOADING);
      }
    },
    logout({ commit }) {
      const route = window.location.origin + '/logout';
      keycloak.logout({ redirectUri: route });
      commit(AuthAction.LOADING);
    },
    refreshFailed({ commit }) {
      commit(AuthAction.REFRESHFAILED);
    },
    setLogin({ commit }, val: boolean) {
      commit(AuthAction.UPDATELOGGEDIN, val);
    },
    loadUser({ commit }) {
      if (this.state.auth?.loggedIn)
        keycloak
          .loadUserProfile()
          .then((user) => {
            if (user) {
              commit(AuthAction.LOADUSER, user);
            } else alert('loadUser failed');
          })
          .catch((error) => {
            alert('Failed to load User Profile: ' + error);
          });
      else alert('tried to get user while not logged in.');
    },
    loggedOut({ commit }) {
      commit(AuthAction.LOGOUT);
    },
    loggedIn({ commit }, success: boolean) {
      success ? commit(AuthAction.LOGINSUCCESS) : commit(AuthAction.LOGINFAILURE);
    },
  },
  mutations: {
    [AuthAction.LOGINSUCCESS](state: AuthState) {
      state.loggedIn = true;
      state.loading = false;
    },
    [AuthAction.LOGINFAILURE](state: AuthState) {
      state.loggedIn = false;
      state.user = null;
      state.loading = false;
      //todo react to failure?
    },
    [AuthAction.LOGOUT](state: AuthState) {
      state.loggedIn = false;
      state.user = null;
      state.loading = false;
    },
    [AuthAction.REFRESHFAILED](state: AuthState) {
      state.loggedIn = false;
      state.user = null;
    },
    [AuthAction.LOADING](state: AuthState) {
      state.loading = true;
    },
    [AuthAction.UPDATELOGGEDIN](state: AuthState, loggedIn: boolean) {
      if (loggedIn == undefined) return;
      state.loggedIn = loggedIn;
      if (!loggedIn) state.user = null;
    },
    [AuthAction.LOADUSER](state: AuthState, user: Keycloak.KeycloakProfile) {
      if (user == undefined) return;
      if (state.loggedIn) state.user = user;
    },
  },
};
