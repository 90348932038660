<template>
  <div id="main-content" class="grid grid-cols-3 p-3 text-gray-900">
    <button
      class="absolute right-1/3 text-gray-400 rounded focus:outline-none hover:text-yellow-400"
      @click="closeStatistics"
      v-if="statisticsVisible"
    >
      <span class="font-semibold">Hide Analysis</span>
      <ChevronDoubleRightIcon class="h-5 w-5 inline" />
    </button>
    <button
      class="absolute right-0 mr-12 text-gray-200 rounded focus:outline-none"
      @click="openStatistics"
      v-if="!statisticsVisible"
    >
      <span class="font-semibold">View Analysis</span>
      <ChevronDoubleLeftIcon class="h-5 w-5 inline" />
    </button>
    <div id="main-content" class="col-span-3 mt-9">
      <div class="lg:text-2xl md:text-xl sm:text-base p-2 font-semibold bg-gray-300 rounded">
        {{ author.name }}
      </div>
      <div class="p-2 md:text-base sm:text-sm text-gray-600">
        <div v-if="author.pubCount">
          <span class="font-semibold">Publication Count:</span> {{ author.pubCount }}
        </div>
        <div v-if="author.citationCount">
          <span class="font-semibold">Citation Count:</span> {{ author.citationCount }}
        </div>
        <div v-if="author.affiliation" @click="viewAffiliation(author.affiliation.id)">
          <span class="font-semibold">Affiliation:</span> {{ author.affiliation.name }}
        </div>
      </div>
    </div>

    <div v-if="statisticsVisible" class="col-span-1 mt-6 p-3">
      <div class="border border-gray-300 rounded h-1/3">Keywords</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/vue/solid';
import { Author, DetailType } from '@/models/Details';

export default defineComponent({
  name: 'AuthorPage',
  components: {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    // constants reactive to consider a possible change of the displayed publication
    const statisticsVisible = ref(false);
    const author = ref(Object as unknown as Author);

    // TODO: fetch author using existing axios
    const fetchAuthor = (id?: number) => {
      if (id || props.id) {
        let reqId: number;
        if (id) reqId = id;
        else if (props.id) reqId = props.id;
        else {
          emit('fetchFailed');
          return;
        }
        emit('loading');
        fetch('https://api.ambalytics.cloud/entities', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Content-Length': '142' },
          body: `{"query":"query getAuthor($id: ID!) { author(id: $id) {id, name, normalizedName, pubCount, citationCount,  affiliation{ id, name}}}" ,"variables":{"id":"${reqId}"}}`,
        })
          .then((res) => res.json())
          .then((res) => {
            if (!res.data.author) return; //TODO: reaction to author == null
            author.value = { ...res.data.author };
            emit('stopLoading');
          })
          .catch((reason) => {
            emit('fetchFailed');
            console.error('fetch failed because ' + reason);
          });
      } else {
        emit('fetchFailed');
      }
    };

    const viewAffiliation = (id: number) => {
      emit('viewNewDetail', id, DetailType.AFFILIATION);
    };

    onMounted(() => {
      fetchAuthor();
    });

    const closeStatistics = () => {
      statisticsVisible.value = false;
      document.getElementById('main-content')?.classList.add('col-span-3');
      document.getElementById('main-content')?.classList.remove('col-span-2');
    };
    const openStatistics = () => {
      // ToDo: enable as soon as statistics has content + add hover:text-yellow-400 to main-content
      // statisticsVisible.value = true;
      // document.getElementById('main-content')?.classList.remove('col-span-3');
      // document.getElementById('main-content')?.classList.add('col-span-2');
    };

    return {
      statisticsVisible,
      closeStatistics,
      openStatistics,
      author,
      viewAffiliation,
    };
  },
});
</script>
