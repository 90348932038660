
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onUnmounted,
  PropType,
  ref,
  toRefs,
  watch,
} from 'vue';
import { WebGraph, NodeType, LabelSelector } from '@ambalytics/webgraph';
import Graph, { Attributes, SerializedNode } from 'graphology-types';
import { DirectedGraph } from 'graphology';
import {
  AnalysisResponse,
  ClusterMapping,
  GraphDrawingLayout,
  PublicationAnalysisType,
  SearchResponse,
} from '@/models/Search';
import { useStore } from 'vuex';
import {
  SideMenu,
  SideMenuButton,
  SideMenuItems,
  SideMenuItem,
  SideMenuResize,
} from '@/components/Graphs/SideMenu';
import ClusterLegend from '@/components/Graphs/ClusterLegend.vue';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/vue/outline';
import { ActiveLoader, PluginApi as Loading } from 'vue-loading-overlay';
import Chart from 'primevue/chart';
import { ChartData } from 'chart.js';
import { color } from 'd3-color';
import {
  getClusterColors,
  GraphViewEmitter,
  layoutConfigurations,
} from '@/components/Graphs/utils/graphConfiguration';
import { addEntities, addEntitiesRefs, addAnalysis } from '@/components/Graphs/utils/data';
import { ToastSeverity } from '@/models/Toaster';
import { AppMode, IGraphConfiguration } from '@ambalytics/webgraph/lib/Configuration';
import PublicationInfoBox, { NodeInfoBox } from '@/components/Graphs/PublicationInfoBox.vue';
import TypedEmitter from 'typed-emitter';
import { WebGLSettings } from 'sigma/types/renderers/webgl/settings';

interface ContextMenu {
  x: number;
  y: number;
  id: number;
  label: string;
  citationCount?: number;
  refCount?: number;
  cluster: {
    id: number;
    name: string;
    keywords: string[];
  };
}

interface LayoutEvents {
  applyLayout: (layout: GraphDrawingLayout) => void;
}

export type LayoutEmitter = TypedEmitter<LayoutEvents>;

const offset = (attr: Attributes) => {
  const size = attr.size ?? 5;

  return {
    x: -20 - size,
    y: 5 + size,
  };
};

export default defineComponent({
  name: 'ExploreGraph',
  props: {
    entities: {
      type: Object as PropType<SearchResponse[]>,
      required: true,
    },
    analysis: {
      type: Object as PropType<AnalysisResponse>,
      required: false,
    },
    backdropActive: {
      type: Boolean,
      default: false,
    },
    allEdges: {
      type: Boolean,
      default: false,
    },
    clusters: {
      type: Object as PropType<ClusterMapping>,
      default: () => ({}),
    },
    activeCluster: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    layoutEventEmitter: {
      type: Object as PropType<LayoutEmitter>,
      required: false,
    },
    layout: {
      type: String as PropType<GraphDrawingLayout>,
      default: GraphDrawingLayout.FORCEATLAS2,
    },
    viewEventEmitter: {
      type: Object as PropType<GraphViewEmitter>,
      required: true,
    },
    disableEdges: {
      type: Boolean,
      default: false,
    },
    disableBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SideMenu,
    SideMenuButton,
    SideMenuItems,
    SideMenuItem,
    SideMenuResize,
    ChevronRightIcon,
    ChevronLeftIcon,
    ClusterLegend,
    Chart,
    PublicationInfoBox,
  },
  emits: ['update:activeCluster', 'mouseenterNode', 'clickNode', 'expand'],
  setup(props, { emit }) {
    const {
      entities,
      analysis,
      clusters,
      backdropActive,
      allEdges,
      layoutEventEmitter,
      viewEventEmitter,
      disableEdges,
      disableBackdrop,
      layout,
    } = toRefs(props);

    const store = useStore();
    const app = getCurrentInstance();
    const loading = app?.appContext.config.globalProperties.$loading as Loading;
    const loader = ref<ActiveLoader | null>(null);
    const webGraphContainer = ref<null | HTMLDivElement>(null);

    const nodeLimits = ref<number[]>([]);

    const webGraph = ref<null | WebGraph>(null);
    const graph = ref(new DirectedGraph());

    const isLegendOpen = ref(true);
    const isInitialLoad = ref(true);

    const clusterColors = computed(() =>
      getClusterColors(analysis.value?.analysisType, clusters.value)
    );

    const internalBackdropActive = ref(backdropActive.value);
    watch(
      () => backdropActive.value,
      (newValue) => (internalBackdropActive.value = newValue)
    );
    watch(
      () => clusterColors.value,
      (newValue) => {
        webGraph.value?.toggleNodeBackdropRendering(newValue, internalBackdropActive.value);
        webGraph.value?.camera.animate({});
      }
    );

    const showLoader = () => {
      if (!loader.value && webGraphContainer.value) {
        loader.value = loading.show({
          isFullPage: false,
          container: webGraphContainer.value,
        });
      }
    };
    const hideLoader = () => {
      loader.value?.hide();
      loader.value = null;
    };

    const nodeInfoBox = ref<NodeInfoBox | null>(null);
    const contextMenu = ref<ContextMenu | null>(null);

    const initGraph = (container: HTMLElement, newGraph: Graph): WebGraph => {
      const sigmaSettings: Partial<WebGLSettings> = {
        renderLabels: true,
        labelFontColor: '#8e8e8e',
        defaultEdgeType: 'line',
        renderJustImportantEdges: !allEdges.value,
      };

      const config: Partial<IGraphConfiguration> = {
        highlightSubGraphOnHover: true,
        includeImportantNeighbors: false,
        importantNeighborsBidirectional: true,
        subGraphHighlightColor: '#ffc107',
        importantNeighborsColor: '#ff9800',
        defaultNodeType: NodeType.CIRCLE,
        labelSelector: LabelSelector.ALL,
        suppressContextMenu: false,
        sigmaSettings: sigmaSettings,
        appMode: AppMode.DYNAMIC,
      };

      const newWebGraph = new WebGraph(container, newGraph, config);

      newWebGraph.on('click', ({ node, event }) => {
        nodeInfoBox.value = null;
        emit('clickNode', parseInt(node.toString()));
        switch (event.original.button) {
          case 0: {
            event.original.stopPropagation();
            event.original.preventDefault();
            const cluster = graph.value?.getNodeAttribute(node, 'cluster');
            emit(
              'update:activeCluster',
              typeof cluster !== 'undefined' ? cluster.toString() : undefined
            );
            break;
          }
          case 2: {
            event.original.stopPropagation();
            event.original.preventDefault();
            const attr = graph.value?.getNodeAttributes(node);

            if (!attr) {
              store.dispatch('toaster/showToast', {
                severity: ToastSeverity.ERROR,
                message: 'Entity not found!',
              });
              return;
            }

            const entity = graph.value?.getNodeAttributes(node);
            const clusterId = entity?.cluster ?? -1;
            const entityData = entities.value.find(
              ({ entity }) => entity.id === parseInt(node.toString())
            );

            contextMenu.value = {
              x: event?.x - 5,
              y: event?.y + 5,
              id: parseInt(node.toString()),
              label: entity?.label ?? '',
              cluster: {
                id: clusterId,
                name: clusters.value[clusterId].name,
                keywords: clusters.value[clusterId].keywords,
              },
              refCount: entityData?.entity.refs.length,
              citationCount: entityData?.entity.citationCount,
            };

            const mouseLeaveListener = () => {
              contextMenu.value = null;
              webGraph.value?.removeListener('mouseleave', mouseLeaveListener);
            };

            webGraph.value?.addListener('mouseleave', mouseLeaveListener);
            break;
          }
        }
      });

      newWebGraph.on('mouseenter', ({ node, event }) => {
        const found = entities.value.find(({ entity }) => entity.id === parseInt(node.toString()));
        const attr = graph.value?.getNodeAttributes(node);

        if (!found || !attr) {
          store.dispatch('toaster/showToast', {
            severity: ToastSeverity.ERROR,
            message: 'Entity not found!',
          });
          return;
        }

        const { entity } = found;

        emit('mouseenterNode', entity.id);

        const nodeOffset = offset(attr);

        nodeInfoBox.value = {
          x: event?.x + nodeOffset.x,
          y: event?.y + nodeOffset.y,
          id: entity.id,
          title: entity.title,
          publisher: entity.publisher,
          year: entity.year,
          authors: entity.authors.map(({ name }) => name),
          citationCount: entity.citationCount,
          refs: entity.refs,
          doi: entity.doi,
        };

        const mouseLeaveListener = () => {
          nodeInfoBox.value = null;
          webGraph.value?.removeListener('mouseleave', mouseLeaveListener);
        };

        webGraph.value?.addListener('mouseleave', mouseLeaveListener);
      });

      newWebGraph.render();

      newWebGraph.camera.animatedUnzoom(1.1);

      return newWebGraph;
    };

    const destroy = () => {
      webGraph.value?.destroy();
    };

    onUnmounted(destroy);

    const applyEdges = async () => {
      if (!graph.value || !analysis.value) return hideLoader();

      if (analysis.value?.analysisType === PublicationAnalysisType.CITATIONNETWORK) {
        await addEntitiesRefs(entities.value, graph.value);
      } else {
        webGraph.value?.toggleNodeBackdropRendering(clusterColors.value, false);
        await addAnalysis(analysis.value, graph.value);

        webGraph.value?.toggleNodeBackdropRendering(
          clusterColors.value,
          internalBackdropActive.value
        );
      }

      webGraph.value?.toggleEdgeRendering(disableEdges.value);

      await webGraph.value?.setAndApplyLayout(
        layoutConfigurations[layout.value].layout,
        layoutConfigurations[layout.value].options
      );
    };

    watch(
      [entities, analysis, webGraphContainer],
      async ([newEntities, , newContainer], [oldEntities, , oldContainer]) => {
        try {
          if (!newContainer) return;
          showLoader();

          if (newEntities.length !== oldEntities.length || !oldContainer) {
            webGraph.value?.destroy();
            webGraph.value = null;

            if (newEntities.length === 0) return hideLoader();

            const newWebGraph = initGraph(newContainer, graph.value);
            webGraph.value = newWebGraph;

            const manipulateNode = (node: SerializedNode, existing: boolean) => {
              if (node.attributes) {
                node.attributes.color = existing ? 'rgb(255, 237, 179)' : 'rgb(255, 195, 0)';
              }
              return node;
            };

            nodeLimits.value = await addEntities(newEntities, graph.value, manipulateNode);
          }

          if (graph.value?.order === newEntities.length) {
            await applyEdges();
          }

          hideLoader();

          if (isInitialLoad.value) {
            setTimeout(() => {
              isLegendOpen.value = false;
            }, 2000);
          }

          isInitialLoad.value = false;
        } catch (e) {
          console.error(e);
          store.dispatch('toaster/showToast', {
            severity: ToastSeverity.ERROR,
            message: e,
          });
        }
      }
    );

    const unmount = () => {
      webGraphContainer.value = null;
    };

    onBeforeUnmount(unmount);

    watch(
      () => internalBackdropActive.value,
      (newValue) => {
        webGraph.value?.toggleNodeBackdropRendering(clusterColors.value, newValue);
        webGraph.value?.camera.animate({});
      }
    );

    watch(
      () => allEdges.value,
      (newValue) => {
        webGraph.value?.toggleJustImportantEdgeRendering(!newValue);
      }
    );

    const hoverCluster = (id?: string) => {
      graph.value?.forEachNode((node) => {
        const attributes = graph.value?.getNodeAttributes(node) || {};
        graph.value?.mergeNode(node, {
          ...attributes,
          hidden: id !== undefined ? parseInt(attributes.cluster) !== parseInt(id) : false,
        });
      });
    };

    const clusterDistribution = computed(() =>
      Object.entries(clusters.value).reduce(
        (prev, [id, c]) => {
          const labels = prev.labels?.concat(c.name);
          const data = prev.datasets[0].data.concat(c.size);
          const backgroundColor = prev.datasets[0].backgroundColor as string[];
          const hoverBackgroundColor = prev.datasets[0].hoverBackgroundColor as string[];
          const clusterColor = clusterColors.value[parseInt(id)];
          return {
            labels,
            datasets: [
              {
                ...prev.datasets[0],
                data,
                backgroundColor: backgroundColor.concat(clusterColor),
                hoverBackgroundColor: hoverBackgroundColor.concat(
                  color(clusterColor)?.brighter(0.5).formatHex() ?? clusterColor
                ),
              },
            ],
          };
        },
        {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [] as string[],
              hoverBackgroundColor: [] as string[],
              borderColor: '#E8E8E8',
            },
          ],
        } as ChartData<'doughnut'>
      )
    );

    watch(
      () => analysis.value,
      async (newAnalysis, oldAnalysis) => {
        if (!newAnalysis || !oldAnalysis) return;

        if (internalBackdropActive.value && disableBackdrop.value) {
          internalBackdropActive.value = false;
        }

        if (!internalBackdropActive.value && disableBackdrop.value) {
          internalBackdropActive.value = true;
        }

        if (!disableEdges.value && allEdges.value) {
          webGraph.value?.toggleJustImportantEdgeRendering(false);
        }
      }
    );

    const setViewEventListener = (newEmitter: GraphViewEmitter) => {
      newEmitter.on('zoomIn', () => webGraph.value?.camera.animatedUnzoom(0.75));
      newEmitter.on('reset', () => webGraph.value?.camera.animate({ ratio: 1.1, x: 0.5, y: 0.5 }));
      newEmitter.on('zoomOut', () => webGraph.value?.camera.animatedZoom(0.75));
    };
    watch(
      () => viewEventEmitter.value,
      (newEmitter, oldEmitter) => {
        setViewEventListener(newEmitter);

        oldEmitter.removeAllListeners();
      }
    );
    setViewEventListener(viewEventEmitter.value);

    const setLayoutEventListener = (newEmitter: LayoutEmitter) => {
      newEmitter.on('applyLayout', (layout: GraphDrawingLayout) => {
        webGraph.value?.setAndApplyLayout(
          layoutConfigurations[layout].layout,
          layoutConfigurations[layout].options
        );
      });
    };
    watch(
      () => layoutEventEmitter.value,
      (newEmitter, oldEmitter) => {
        if (newEmitter) setLayoutEventListener(newEmitter);

        oldEmitter?.removeAllListeners();
      }
    );
    if (layoutEventEmitter.value) setLayoutEventListener(layoutEventEmitter.value);

    const expand = (by: 'node' | 'cluster' | 'graph', id?: number) => {
      if (by !== 'graph' && typeof id === 'undefined') return;

      contextMenu.value = null;
      switch (by) {
        case 'node': {
          emit('expand', [id]);
          break;
        }
        case 'cluster': {
          if (!graph.value) return;

          const ids = [] as number[];
          for (const [node, attr] of graph.value?.nodeEntries()) {
            if (attr.cluster?.toString() === id?.toString()) ids.push(parseInt(node.toString()));
          }
          emit('expand', ids);
          break;
        }
        case 'graph': {
          if (!graph.value) return;

          const ids = graph.value.nodes().map((node) => parseInt(node.toString()));
          emit('expand', ids);
          break;
        }
      }
    };

    return {
      clusterColors,
      nodeLimits,
      webGraphContainer,
      hoverCluster,
      isLegendOpen,
      clusterDistribution,
      nodeInfoBox,
      contextMenu,
      expand,
    };
  },
});
