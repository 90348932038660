
import { defineComponent } from 'vue';
import AppSkeletonMenu from '@/components/Skeleton/AppSkeletonMenu.vue';
import LoginButton from '@/components/Buttons/LoginButton.vue';

export default defineComponent({
  name: 'Logout',
  components: {
    AppSkeletonMenu,
    LoginButton,
  },
});
