<template>
  <div class="w-full h-full bg-gray-50">
    <div class="absolute inset-x-1/3 top-16 w-1/3 h-auto bg-white border border-gray-300 rounded">
      <div class="text-xl p-4 font-light text-gray-500">
        Redirecting to the ambalytics login page ...
      </div>
      <ProgressSpinner class="p-4" />
    </div>
  </div>
</template>

<script lang="ts">
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: 'Login',
  components: {
    ProgressSpinner,
  },
};
</script>
