
import { computed, defineComponent, PropType, ref, toRefs } from 'vue';
import OpenDoi from '@/components/Buttons/OpenDoi.vue';

export interface NodeInfoBox {
  x: number;
  y: number;
  id: number;
  title: string;
  publisher: string;
  year: number;
  authors: string[];
  citationCount: number;
  refs: number[];
  doi: string;
}

export default defineComponent({
  name: 'PublicationInfoBox',
  props: {
    data: {
      type: Object as PropType<NodeInfoBox>,
      required: true,
    },
  },
  components: {
    OpenDoi,
  },
  setup(props) {
    const { data } = toRefs(props);
    const nodeInfoBox = ref<HTMLDivElement | null>(null);

    const positionedX = computed(() => {
      const x = data.value.x;
      const boxWidth = nodeInfoBox.value?.clientWidth;
      const parentWidth = nodeInfoBox.value?.parentElement?.clientWidth;

      if (!boxWidth || !parentWidth) {
        return x;
      }

      if (x < 0) {
        return 0;
      }
      if (parentWidth < x + boxWidth) {
        return parentWidth - boxWidth;
      }
      return x;
    });

    const positionedY = computed(() => {
      const y = data.value.y;
      const boxHeight = nodeInfoBox.value?.clientHeight;
      const parentHeight = nodeInfoBox.value?.parentElement?.clientHeight;

      if (!boxHeight || !parentHeight) {
        return y;
      }

      if (y < 0) {
        return 0;
      }
      if (parentHeight < y + boxHeight) {
        return parentHeight - boxHeight;
      }
      return y;
    });

    return {
      nodeInfoBox,
      positionedX,
      positionedY,
    };
  },
});
