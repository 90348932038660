<template>
  <div>
    <div class="top-4 right-4 absolute w-8 text-gray-800">
      <ControlButtonGroup :controls="viewControls" @click="(e) => $emit('manipulateView', e)" />
      <ControlMenuButton
        class="mt-2"
        menuTitle="Switch graph drawing algorithm"
        :menuIcon="PhotographIcon"
        :menuItems="layoutControls"
        :selected="currentLayout"
        @menuCick="(e) => $emit('changeLayout', e)"
      />
      <ControlButton
        class="mt-2"
        description="Show backdrop"
        @click="$emit('toggleBackdrop')"
        :active="backdrop.active"
        :disabled="backdrop.disabled"
        :icon="SunIcon"
      />
      <ControlButton
        class="mt-2"
        description="Show all edges"
        @click="$emit('toggleAllEdges')"
        :active="allEdges.active"
        :disabled="allEdges.disabled"
        :icon="CubeIcon"
        :inactiveIcon="CubeTransparentIcon"
      />
      <ControlMenuButton
        class="mt-2"
        :disabled="disableAnalysis"
        :menuTitle="`Switch network analysis${
          disableAnalysis ? ' \n Please choose a graph with at least 20 nodes' : ''
        }`"
        :menuIcon="VariableIcon"
        :menuItems="analysisControls"
        :selected="currentAnalysis"
        @menuCick="(e) => $emit('changeAnalysis', e)"
      />
      <ControlButton
        class="mt-2"
        description="Start explore graph"
        @click="openExplore"
        :icon="GlobeIcon"
      />
      <NodeSelectionDialog
        :entities="entities"
        v-model:isOpen="isNodeSelectorOpen"
        :clusters="clusters"
        :analysis="analysis"
        @select="(e) => $emit('explore', e)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import {
  ZoomInIcon,
  ZoomOutIcon,
  HomeIcon,
  PhotographIcon,
  SunIcon,
  CubeIcon,
  CubeTransparentIcon,
  VariableIcon,
  GlobeIcon,
} from '@heroicons/vue/outline';
import {
  ControlButton,
  ControlButtonGroup,
  ControlMenuButton,
} from '@/components/Graphs/GraphControls';
import NodeSelectionDialog from '@/components/Graphs/NodeSelectionDialog.vue';
import {
  AnalysisResponse,
  AnalysisType,
  AuthorAnalysisType,
  ClusterMapping,
  PublicationAnalysisType,
  SearchResponse,
} from '@/models/Search';

interface ButtonOptions {
  disabled: boolean;
  active: boolean;
}

export default defineComponent({
  name: 'GraphControls',
  props: {
    currentLayout: String,
    currentAnalysis: String as PropType<AnalysisType>,
    backdrop: {
      type: Object as PropType<ButtonOptions>,
    },
    allEdges: {
      type: Object as PropType<ButtonOptions>,
    },
    disableAnalysis: {
      type: Boolean,
      default: false,
    },
    entities: {
      type: Object as PropType<SearchResponse[]>,
      required: true,
    },
    analysis: {
      type: Object as PropType<AnalysisResponse>,
      required: false,
    },
    clusters: {
      type: Object as PropType<ClusterMapping>,
      required: false,
    },
  },
  components: {
    ControlButtonGroup,
    ControlMenuButton,
    ControlButton,
    NodeSelectionDialog,
  },
  emits: [
    'explore',
    'changeAnalysis',
    'toggleAllEdges',
    'manipulateView',
    'changeLayout',
    'toggleBackdrop',
  ],
  setup() {
    const isNodeSelectorOpen = ref(false);

    const viewControls = [
      {
        key: 'zoomIn',
        description: 'Zoom in',
        icon: ZoomInIcon,
      },
      {
        key: 'reset',
        description: 'Reset view',
        icon: HomeIcon,
      },
      {
        key: 'zoomOut',
        description: 'Zoom out',
        icon: ZoomOutIcon,
      },
    ];

    const layoutControls = [
      {
        key: 'forceAtlas2',
        description: 'Forceatlas2',
      },
      {
        key: 'fruchtermanReingold',
        description: 'Fruchterman-Reingold',
      },
      {
        key: 'circlepack',
        description: 'Circlepack',
      },
      {
        key: 'circular',
        description: 'Circular',
      },
      {
        key: 'random',
        description: 'Random',
      },
    ];

    const analysisControls = [
      {
        key: PublicationAnalysisType.HYBRIDFRONTS,
        description: 'Hybrid fronts',
      },
      {
        key: PublicationAnalysisType.CITATIONNETWORK,
        description: 'Citation network',
      },
      {
        key: PublicationAnalysisType.KNOWLEDGEBASES,
        description: 'Knowledge bases',
      },
      {
        key: PublicationAnalysisType.RESEARCHFRONTS,
        description: 'Research fronts',
      },
      {
        key: AuthorAnalysisType.COAUTHORNETWORK,
        description: 'Co-Author network',
      },
    ];

    const openExplore = () => {
      isNodeSelectorOpen.value = true;
    };

    return {
      viewControls,
      layoutControls,
      analysisControls,
      PhotographIcon,
      SunIcon,
      CubeIcon,
      CubeTransparentIcon,
      VariableIcon,
      GlobeIcon,
      openExplore,
      isNodeSelectorOpen,
    };
  },
});
</script>
