<template>
  <div class="border rounded my-1 border-gray-300">
    <DataTable
      :value="publications"
      :paginator="true"
      :rows="7"
      v-model:selection="selectedPublication"
      selectionMode="single"
      dataKey="id"
      @rowSelect="onRowSelect"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[7, 10, 20, 50]"
      responsiveLayout="scroll"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    >
      <template #header>
        <h5 class="text-2xl text-gray-700">{{ listName }}</h5>
      </template>
      <Column field="title" header="Title" class=""></Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { ref, PropType, Ref, defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default defineComponent({
  name: 'PublicationList',
  components: {
    DataTable,
    Column,
  },
  props: {
    publicationList: {
      type: Object as PropType<{ id: number; title: string }[]>,
      required: true,
    },
    name: {
      type: String,
      default: 'Publication List',
    },
  },
  setup(props, { emit }) {
    const publications = ref(props.publicationList);
    const listName = ref(props.name);
    const selectedPublication: Ref<{ id: number; title: string } | undefined> = ref();
    const onRowSelect = (event: { data: { id: number } }) => {
      openNewPublication(event.data.id);
    };
    const openNewPublication = (id: number) => {
      emit('selectPublication', id);
    };
    return { publications, listName, onRowSelect, selectedPublication };
  },
});
</script>
