
import { computed, defineComponent } from 'vue';
import AppSkeletonMenu from '@/components/Skeleton/AppSkeletonMenu.vue';
import CollectionSetUp from '@/components/Review/CollectionSetUp.vue';
import ProgressBar from '@/components/Review/ProgressBar.vue';
import PriorKnowledgeDialog from '@/components/Review/PriorKnowledge.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { WorkflowStep } from '@/models/Review';
import ReviewButton from '@/components/Buttons/Reviews.vue';

export default defineComponent({
  name: 'Review',
  components: {
    AppSkeletonMenu,
    CollectionSetUp,
    ProgressBar,
    PriorKnowledgeDialog,
    ReviewButton,
  },
  props: {
    projectId: String,
  },
  data() {
    return {
      workflowsteps: [
        //used for timeline which showa the progress
        { name: WorkflowStep.COLLECTIONSETUP, propName: 'projectHasDataset' },
        { name: WorkflowStep.PRIORSCREENING, propName: 'projectHasPriorKnowledge' },
        { name: WorkflowStep.ABSTRACTSCREENING, propName: 'reviewFinished' },
        { name: WorkflowStep.FULLTEXTREVIEW },
      ],

      // show/hide Dialogs
      settingUpCollection: false,
      selectingPriorKnowledge: false,
    };
  },
  setup(props) {
    const store = useStore();
    const router = useRoute();

    store.dispatch('review/fetch', { getReview: true, projectId: props.projectId });
    const asreview = computed(() => store.getters['review/currentreview']);

    const stringedDate = computed(() => {
      var date = new Date(asreview.value.created_at_unix * 1000);
      return date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear();
    });

    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    return { asreview, router, stringedDate, store, isLoggedIn };
  },
  watch: {},
});
