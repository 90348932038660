<template>
  <h1 class="text-">
    <span class="font-normal text-black">ambalytics</span>
    <i class="font-bold text-black">MIRA</i>
  </h1>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StandardLogo',
  setup() {
    return {};
  },
});
</script>

<style scoped></style>
