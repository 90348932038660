
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import AmbaInput from '@/components/Input/AmbaInput.vue';
import StandardLogo from '@/components/Logos/StandardLogo.vue';
import { SearchIcon } from '@heroicons/vue/solid';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Home',
  components: {
    AmbaInput,
    SearchIcon,
    StandardLogo,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
    const isLoading = computed(() => store.getters['auth/isLoading']);
    const searchInput = ref('');
    const search = () => {
      if (searchInput.value === '') return;
      router.push({ name: 'Search', query: { q: searchInput.value } });
    };
    return { searchInput, search, isLoggedIn, isLoading };
  },
});
