
import { ref, Ref, defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default defineComponent({
  name: 'Reviewlist',
  components: {
    DataTable,
    Column,
  },
  props: {
    reviews: {
      type: Array,
      default: function () {
        return [{ id: 0, name: 'Review One', date: 'gestern', description: 'hello' }];
      },
    },
  },
  setup(props, { emit }) {
    const selectedReview: Ref<{ id: string } | undefined> = ref();
    const onRowSelect = (event: { data: { id: string } }) => {
      openReview(event.data.id);
    };
    const openReview = (id: string) => {
      emit('selectReview', id);
    };
    return { onRowSelect, openReview, selectedReview };
  },
});
