<template>
  <div>
    <Dialog header="Create New Project" modal="true" dismissableMask="true">
      <span
        >Title
        <AmbaInput autocomplete="off" v-model="newRewTitle" placeholder="Title of Review">
        </AmbaInput>
      </span>
      <span
        >Description <AmbaInput autocomplete="off" v-model="newRewDescr" placeholder="Description"
      /></span>
      <div class="mt-10">
        <div>Include Workflowsteps:</div>
        <div v-for="step of steps" :key="step.key" class="p-field-checkbox">
          <Checkbox
            :id="step.key"
            name="category"
            :value="step.value"
            v-model="selectedCategories"
          />
          <label :for="step.key" class="mr-5">{{ step.value }}</label>
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" />
        <Button label="Create Project" @click="createNewProject" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AmbaInput from '@/components/Input/AmbaInput.vue';
import Checkbox from 'primevue/checkbox';
import { WorkflowStep } from '@/models/Review/index';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'NewReview',
  components: {
    AmbaInput,
    Checkbox,
    Dialog,
    Button,
  },
  props: {
    addingProject: Boolean,
  },
  data() {
    return {
      newRewTitle: '',
      newRewDescr: '',
      steps: [
        { key: 'AS', value: 'Abstract Screening' },
        { key: 'FTR', value: 'Full Text Review' },
        { key: 'QA', value: 'Quality Assessment' },
      ],
      selectedCategories: ['Abstract Screening', 'Full Text Review'],
    };
  },
  methods: {
    createNewReview() {
      console.log('New Review');

      const store = useStore();
      store.dispatch('review/create', { title: this.newRewTitle, description: this.newRewDescr });
    },
  },
  setup() {
    // get array of enum Workflowstep
    const workflowsteps = Object.values(WorkflowStep);

    return { workflowsteps };
  },
});
</script>

<style scoped>
.p-dialog {
  width: 60%;
}
</style>
