import { core, entities } from '@/api';
import {
  AnalysisParameters,
  AnalysisResponse,
  PublicationAnalysisType,
  SearchResponse,
} from '@/models/Search';
import { ToastSeverity } from '@/models/Toaster';
import { RootState } from '@/store';
import { v4 } from 'uuid';
import { Module } from 'vuex';

// State
export interface ExploreState {
  entities: SearchResponse[];
  currentAnalysis: PublicationAnalysisType;
  analysis: {
    [PublicationAnalysisType.CITATIONNETWORK]?: AnalysisResponse;
    [PublicationAnalysisType.HYBRIDFRONTS]?: AnalysisResponse;
    [PublicationAnalysisType.KNOWLEDGEBASES]?: AnalysisResponse;
    [PublicationAnalysisType.RESEARCHFRONTS]?: AnalysisResponse;
  };
  loading: boolean;
  details: {
    loading: boolean;
    entities: { [id: number]: SearchResponse };
  };
  expand: {
    entities: SearchResponse[];
    analysis: AnalysisResponse | undefined;
  };
  error?: string;
}

enum ExploreAction {
  SET_ENTITIES = 'SET_ENTITIES',
  FETCH_ANALYSIS = 'FETCH_ANALYSIS',
  SET_ERROR = 'SET_ERROR',
  SWITCH_ANALYSIS = 'SWITCH_ANALYSIS',
  RESET_ENTITIES = 'RESET_ENTITIES',
  RESET_ANALYSIS = 'RESET_ANALYSIS',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR_ANALYSIS = 'SET_ERROR_ANALYSIS',
  SET_ERROR_DETAILS = 'SET_ERROR_DETAILS',
  FETCH_DETAILS = 'FETCH_DETAILS',
  SET_DETAILS_LOADING = 'SET_DETAILS_LOADING',
  SET_EXPAND_ENTITIES = 'SET_EXPAND_ENTITIES',
  SET_EXPAND_ANALYSIS = 'SET_EXPAND_ANALYSIS',
  RESET_EXPAND = 'RESET_EXPAND',
  RESET_DETAILS = 'RESET_DETAILS',
}

export const explore: Module<ExploreState, RootState> = {
  namespaced: true,
  state: {
    entities: [],
    currentAnalysis: PublicationAnalysisType.CITATIONNETWORK,
    analysis: {},
    loading: false,
    details: {
      loading: false,
      entities: {},
    },
    expand: {
      entities: [],
      analysis: undefined,
    },
  },
  getters: {
    entities(state: ExploreState): SearchResponse[] {
      return state.entities;
    },
    analysis(state: ExploreState): AnalysisResponse | undefined {
      return state.analysis[state.currentAnalysis];
    },
    currentAnalysis(state: ExploreState): PublicationAnalysisType {
      return state.currentAnalysis;
    },
    loading(state: ExploreState): boolean {
      return state.loading;
    },
    detailsLoading(state: ExploreState): boolean {
      return state.details.loading;
    },
    details(state: ExploreState): { [id: number]: SearchResponse } {
      return state.details.entities;
    },
    expandEntities(state: ExploreState): SearchResponse[] {
      return state.expand.entities;
    },
    expandAnalysis(state: ExploreState): AnalysisResponse | undefined {
      return state.expand.analysis;
    },
  },
  actions: {
    async setEntities({ commit, dispatch, state }, { ids }: { ids: number[] }) {
      commit(ExploreAction.SET_LOADING, true);
      commit(ExploreAction.RESET_ENTITIES);
      commit(ExploreAction.RESET_ANALYSIS);

      await dispatch('fetchDetails', { ids });

      const entities: SearchResponse[] = ids.map((id) => {
        const details = state.details.entities[id];

        return details;
      });

      commit(ExploreAction.SET_ENTITIES, entities);

      dispatch('fetchAnalysis', {});
      commit(ExploreAction.SET_LOADING, false);
    },
    async addEntities({ commit, dispatch, state }, { ids }: { ids: number[] }) {
      commit(ExploreAction.RESET_ANALYSIS);

      const existingIds = state.entities.map(({ entity }) => entity.id);

      const newIds = ids.filter((id) => !existingIds.includes(id));

      await dispatch('fetchDetails', { ids: newIds });

      const entities: SearchResponse[] = newIds.map((id) => {
        const details = state.details.entities[id];

        return details;
      });

      commit(ExploreAction.SET_ENTITIES, state.entities.concat(entities));

      dispatch('fetchAnalysis', {});
    },
    async fetchAnalysis(
      { dispatch, commit, state },
      { type, force = false }: { type?: PublicationAnalysisType; force?: boolean }
    ) {
      try {
        commit(ExploreAction.SET_LOADING, true);
        const params: AnalysisParameters = { detectCommunities: true, expandGraph: false };

        const fetchType = type ? type : state.currentAnalysis;

        switch (fetchType) {
          case PublicationAnalysisType.HYBRIDFRONTS:
            params.hybridSimilarityRatio = 0.6;
            params.maxEdges = 10;
        }

        // Only re-fetch analysis if forced or empty (not loaded)
        if (force || !state.analysis[fetchType]) {
          // We don't want to fetch citation networks from the server as parsing the refs in the FE is quicker
          if (fetchType !== PublicationAnalysisType.CITATIONNETWORK) {
            if (state.entities.length >= 20) {
              const analysis = await core.analysis({
                analysisType: fetchType,
                analysisParameters: params,
                entityIds: state.entities.map(({ entity }) => entity.id),
              });

              commit(ExploreAction.FETCH_ANALYSIS, {
                type: fetchType,
                results: analysis.results[0],
              });
            } else {
              dispatch('switchAnalysis', { type: PublicationAnalysisType.CITATIONNETWORK });
              commit(ExploreAction.FETCH_ANALYSIS, { type: fetchType });
            }
          } else {
            commit(ExploreAction.FETCH_ANALYSIS, { type: fetchType });
          }
        }
        commit(ExploreAction.SET_LOADING, false);
      } catch (e) {
        console.error(e);
        commit(ExploreAction.SET_ERROR_ANALYSIS, 'Error while fetching result!');
        commit(ExploreAction.SET_LOADING, false);
        dispatch(
          'toaster/showToast',
          {
            severity: ToastSeverity.ERROR,
            message: 'Error while fetching result!',
          },
          { root: true }
        );
      }
    },
    async switchAnalysis({ dispatch, commit, state }, { type }: { type: PublicationAnalysisType }) {
      if (!state.analysis[type]) {
        await dispatch('fetchAnalysis', { type });
      }

      commit(ExploreAction.SWITCH_ANALYSIS, type);
    },
    async fetchDetails(
      { dispatch, commit, state },
      { ids, force = false }: { ids: number[]; force: boolean }
    ) {
      commit(ExploreAction.SET_DETAILS_LOADING, true);
      try {
        // Only re-fetch all entities if forced otherwise fetch only empty ids
        const idsToFetch = force ? ids : ids?.filter((id) => !state.details.entities[id]);

        if (idsToFetch?.length > 0) {
          const details = await entities.publications(idsToFetch);

          commit(ExploreAction.FETCH_DETAILS, details);
          commit(ExploreAction.SET_DETAILS_LOADING, false);
        }
      } catch (e) {
        console.error(e);
        commit(ExploreAction.SET_ERROR_DETAILS, 'Error while fetching result!');
        commit(ExploreAction.SET_DETAILS_LOADING, false);
        dispatch(
          'toaster/showToast',
          {
            severity: ToastSeverity.ERROR,
            message: 'Error while fetching result!',
          },
          { root: true }
        );
      }
    },
    async expand({ commit, dispatch, state }, { ids }: { ids: number[] }) {
      try {
        await dispatch('fetchDetails', { ids });

        const expandedIds = ids.reduce((prev, id) => {
          const { entity } = state.details.entities[id];

          return [...prev, id, ...entity.refs, ...(entity.citations ?? [])];
        }, [] as number[]);

        if (expandedIds.length < 20) {
          throw new Error('Please choose more nodes!');
        }

        await dispatch('fetchDetails', { ids: expandedIds });

        const expandedEntities: SearchResponse[] = expandedIds.map((id) => {
          const details = state.details.entities[id];

          return details;
        });

        commit(ExploreAction.SET_EXPAND_ENTITIES, expandedEntities);

        const analysis = await core.analysis({
          analysisType: PublicationAnalysisType.HYBRIDFRONTS,
          analysisParameters: {
            detectCommunities: true,
            hybridSimilarityRatio: 0.6,
            maxEdges: 10,
          },
          entityIds: expandedIds,
        });

        commit(ExploreAction.SET_EXPAND_ANALYSIS, analysis.results[0]);
      } catch (e) {
        console.error(e);
        commit(ExploreAction.RESET_EXPAND);
        commit(ExploreAction.SET_ERROR_ANALYSIS, e);
        dispatch(
          'toaster/showToast',
          {
            severity: ToastSeverity.ERROR,
            message: e,
          },
          { root: true }
        );
      }
    },
    reset({ commit }) {
      commit(ExploreAction.RESET_ANALYSIS);
      commit(ExploreAction.RESET_DETAILS);
      commit(ExploreAction.RESET_EXPAND);
    },
  },
  mutations: {
    [ExploreAction.SET_ENTITIES](state: ExploreState, payload: SearchResponse[]) {
      state.error = undefined;
      state.entities = payload;
    },
    [ExploreAction.FETCH_ANALYSIS](
      state: ExploreState,
      { type, results }: { type: PublicationAnalysisType; results?: AnalysisResponse }
    ) {
      state.error = undefined;

      if (results)
        results.analysisType = results.analysisType.toLowerCase() as PublicationAnalysisType;

      if (type !== PublicationAnalysisType.CITATIONNETWORK) {
        state.analysis[type] = results;
      } else {
        state.analysis[type] = {
          analysisId: v4(),
          analysisType: PublicationAnalysisType.CITATIONNETWORK,
          entities: [],
          links: [],
          communities: [],
        };
      }
    },
    [ExploreAction.SET_ERROR](state: ExploreState, payload: string) {
      state.entities = [];
      state.error = payload;
    },
    [ExploreAction.SET_ERROR_ANALYSIS](state: ExploreState, payload: string) {
      state.analysis = {};
      state.error = payload;
    },
    [ExploreAction.SET_ERROR_DETAILS](state: ExploreState, payload: string) {
      state.details = {
        loading: false,
        entities: [],
      };
      state.error = payload;
    },
    [ExploreAction.SWITCH_ANALYSIS](state: ExploreState, type: PublicationAnalysisType) {
      state.currentAnalysis = type;
    },
    [ExploreAction.RESET_ENTITIES](state: ExploreState) {
      state.error = undefined;
      state.entities = [];
    },
    [ExploreAction.RESET_ANALYSIS](
      state: ExploreState,
      payload?: { types?: PublicationAnalysisType[] }
    ) {
      state.error = undefined;
      if (!payload?.types) {
        state.analysis = {};
      } else {
        state.analysis = {
          ...state.analysis,
          ...payload.types.reduce(
            (prev, t) => ({ ...prev, [t]: undefined }),
            {} as { [key in PublicationAnalysisType]: AnalysisResponse | undefined }
          ),
        };
      }
    },
    [ExploreAction.SET_LOADING](state: ExploreState, payload: boolean) {
      state.loading = payload;
    },
    [ExploreAction.SET_DETAILS_LOADING](state: ExploreState, payload: boolean) {
      state.details.loading = payload;
    },
    [ExploreAction.FETCH_DETAILS](state: ExploreState, payload: SearchResponse[]) {
      const existingIds = Object.keys(state.details.entities);
      const entities = payload.reduce((prev, cur) => {
        if (!existingIds.includes(cur.entity.id.toString())) prev[cur.entity.id] = cur;
        return prev;
      }, {} as { [id: number]: SearchResponse });

      state.details.entities = {
        ...state.details.entities,
        ...entities,
      };
    },
    [ExploreAction.SET_EXPAND_ENTITIES](state: ExploreState, payload: SearchResponse[]) {
      state.expand.entities = payload;
    },
    [ExploreAction.SET_EXPAND_ANALYSIS](state: ExploreState, payload?: AnalysisResponse) {
      state.expand.analysis = payload;
    },
    [ExploreAction.RESET_EXPAND](state: ExploreState) {
      state.expand.entities = [];
      state.expand.analysis = undefined;
    },
    [ExploreAction.RESET_DETAILS](state: ExploreState) {
      state.details.entities = [];
      state.details.loading = false;
    },
  },
};
