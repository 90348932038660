
import { defineComponent } from 'vue';
import { ArrowCircleLeftIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'ReviewButton',
  components: {
    ArrowCircleLeftIcon,
  },
});
