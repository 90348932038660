
import { computed, defineComponent, getCurrentInstance, ref } from 'vue';
import { useStore } from 'vuex';
import ErrorModal from '@/components/PopUps/ErrorModal.vue';
import ExploreGraph, { LayoutEmitter } from '@/components/Graphs/ExploreGraph/ExploreGraph.vue';
import NodeSelectionDialog from '@/components/Graphs/NodeSelectionDialog.vue';
import {
  AnalysisResponse,
  AnalysisType,
  GraphDrawingLayout,
  SearchResponse,
} from '@/models/Search';
import { getClusters } from '@/components/Graphs/utils/data';
import GraphControls from '@/components/Graphs/ExploreGraph/GraphControls.vue';
import EventEmitter from 'events';
import {
  GraphViewEmitter,
  analysisConfigurations,
} from '@/components/Graphs/utils/graphConfiguration';
import { ActiveLoader, PluginApi as Loading } from 'vue-loading-overlay';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Explore',
  components: {
    ErrorModal,
    ExploreGraph,
    GraphControls,
    NodeSelectionDialog,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isNodeSelectorOpen = ref(false);

    store.dispatch('explore/reset');

    const app = getCurrentInstance();
    const loading = app?.appContext.config.globalProperties.$loading as Loading;
    const loader = ref<ActiveLoader | null>(null);
    const activeLoaders = ref<number[]>([]);
    const showLoader = (): number => {
      if (!loader.value) {
        loader.value = loading.show({
          isFullPage: true,
        });
      }
      const id = Date.now();
      activeLoaders.value.push(id);
      return id;
    };
    const hideLoader = (id: number) => {
      activeLoaders.value = activeLoaders.value.filter((i) => i !== id);
      if (activeLoaders.value.length === 0) {
        loader.value?.hide();
        loader.value = null;
      }
    };

    const entities = computed(() => store.getters['explore/entities'] as SearchResponse[]);
    const analysis = computed(
      () => store.getters['explore/analysis'] as AnalysisResponse | undefined
    );
    const currentAnalysis = computed(
      () => store.getters['explore/currentAnalysis'] as AnalysisType
    );
    const clusters = computed(() =>
      analysis.value
        ? getClusters(analysis.value, entities.value.length)
        : {
            [-1]: { name: 'Unassigned', keywords: [], size: entities.value.length },
          }
    );
    const isLoading = computed(() => store.getters['explore/loading'] as boolean);

    if (!analysis.value && !isLoading.value) store.dispatch('explore/fetchAnalysis', {});

    const backdropActive = ref(true);
    const allEdges = ref(true);
    const activeCluster = ref<string | undefined>(undefined);
    const layout = ref<GraphDrawingLayout | undefined>(GraphDrawingLayout.FORCEATLAS2);

    const viewEventEmitter = new EventEmitter() as GraphViewEmitter;

    const manipulateView = (action: 'zoomIn' | 'reset' | 'zoomOut') => {
      switch (action) {
        case 'zoomIn':
          viewEventEmitter.emit('zoomIn');
          break;
        case 'reset':
          viewEventEmitter.emit('reset');
          break;
        case 'zoomOut':
          viewEventEmitter.emit('zoomOut');
          break;
      }
    };

    const switchAnalysis = (newAnalysis: AnalysisType) => {
      store.dispatch('explore/switchAnalysis', { type: newAnalysis });
    };

    const expandEntities = computed(
      () => store.getters['explore/expandEntities'] as SearchResponse[]
    );
    const expandAnalysis = computed(
      () => store.getters['explore/expandAnalysis'] as AnalysisResponse | undefined
    );
    const expandClusters = computed(() =>
      expandAnalysis.value ? getClusters(expandAnalysis.value, expandEntities.value.length) : {}
    );

    const expand = async (ids: number[]) => {
      const loaderId = showLoader();
      await store.dispatch('explore/expand', { ids });
      if (expandEntities.value.length > 0) {
        isNodeSelectorOpen.value = true;
      }
      hideLoader(loaderId);
    };

    const selectExpand = async (e: number[]) => {
      const loaderId = showLoader();
      isNodeSelectorOpen.value = false;
      await store.dispatch('explore/reset');
      await store.dispatch('explore/addEntities', { ids: e });
      hideLoader(loaderId);
    };

    const layoutEventEmitter = new EventEmitter() as LayoutEmitter;

    const changeLayout = (newLayout: GraphDrawingLayout) => {
      layoutEventEmitter.emit('applyLayout', newLayout);
      layout.value = newLayout;
    };

    const back = () => {
      if (window.history.state.position > 1) {
        router.back();
      } else {
        router.replace({ name: 'Home' });
      }
    };

    return {
      entities,
      analysis,
      clusters,
      backdropActive,
      allEdges,
      activeCluster,
      layout,
      layoutEventEmitter,
      manipulateView,
      currentAnalysis,
      switchAnalysis,
      analysisConfigurations,
      viewEventEmitter,
      expand,
      isNodeSelectorOpen,
      expandEntities,
      expandAnalysis,
      expandClusters,
      selectExpand,
      isLoading,
      changeLayout,
      back,
    };
  },
});
