<template>
  <div class="flex h-full">
    <div class="container m-auto flex flex-col items-center">
      <div>
        <div class="text-5xl sm:text-7xl m-1">
          <standard-logo class="pb-3" />
        </div>
        <div class="text-base font-light text-gray-800 m-1">
          <p>Find. Explore. Analyse. Science.</p>
        </div>
        <div class="flex max-w-2xl w-full my-3">
          <form @submit="search" class="w-full">
            <AmbaInput
              v-model="searchInput"
              placeholder="Search..."
              class="border border-gray-300 rounded-md focus:outline-none"
            >
              <SearchIcon />
            </AmbaInput>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import AmbaInput from '@/components/Input/AmbaInput.vue';
import StandardLogo from '@/components/Logos/StandardLogo.vue';
import { SearchIcon } from '@heroicons/vue/solid';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Home',
  components: {
    AmbaInput,
    SearchIcon,
    StandardLogo,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
    const isLoading = computed(() => store.getters['auth/isLoading']);
    const searchInput = ref('');
    const search = () => {
      if (searchInput.value === '') return;
      router.push({ name: 'Search', query: { q: searchInput.value } });
    };
    return { searchInput, search, isLoggedIn, isLoading };
  },
});
</script>
