
import { defineComponent } from 'vue';
import LoginButton from '@/components/Buttons/LoginButton.vue';

export default defineComponent({
  name: 'LoginPopUp',
  components: {
    LoginButton,
  },
});
