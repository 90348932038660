import { AnalysisResponse, ClusterMapping, SearchResponse } from '@/models/Search';
import * as EntitiesWorkerNS from './entities.worker';
import EntitiesWorker from 'worker-loader!./entities.worker';
import * as EntitiesRefsWorkerNS from './entitiesRefs.worker';
import EntitiesRefsWorker from 'worker-loader!./entitiesRefs.worker';
import * as AnalysisWorkerNS from './analysis.worker';
import AnalysisWorker from 'worker-loader!./analysis.worker';
import Graph from 'graphology';
import { SerializedEdge, SerializedNode } from 'graphology-types';
import { NodeType } from '@ambalytics/webgraph';

export const addEntities = (
  entities: SearchResponse[],
  graph: Graph,
  manipulateNode?: (node: SerializedNode, existing: boolean) => SerializedNode
): Promise<number[]> => {
  return new Promise<number[]>((resolve) => {
    // convert the proxy object (returned by vuex and looks like a plain object :/ ) to a plain object for cloning to the worker
    const copiedEntities = JSON.parse(JSON.stringify(entities)) as SearchResponse[];

    // TODO: As soon as we get normalized values we can set the limit for the score to 0 and 1.
    const limits = entities.reduce(
      ({ score: scoreLimits, year: yearLimits }, { score, entity }) => {
        return {
          score: [Math.min(scoreLimits[0], score), Math.max(scoreLimits[1], score)],
          year: [Math.min(yearLimits[0], entity.year), Math.max(yearLimits[1], entity.year)],
        };
      },
      {
        score: [copiedEntities[0].score, copiedEntities[0].score],
        year: [copiedEntities[0].entity.year, copiedEntities[0].entity.year],
      }
    );

    const worker = new EntitiesWorker<
      EntitiesWorkerNS.WorkerMessage,
      EntitiesWorkerNS.ClientMessage
    >();

    worker.addEventListener('message', (event) => {
      switch (event.data.type) {
        case EntitiesWorkerNS.MessageType.FINISHED:
          worker.terminate();
          resolve(limits.year);
          break;
        case EntitiesWorkerNS.MessageType.DATA:
          event.data.data.forEach((node) => {
            const existing = graph.hasNode(node.key);
            if (existing && node.attributes) {
              const attr = graph.getNodeAttributes(node.key);
              node.attributes.x = attr.x;
              node.attributes.y = attr.y;
            }

            const manipulatedNode = manipulateNode ? manipulateNode(node, existing) : node;

            graph.mergeNode(manipulatedNode.key, manipulatedNode.attributes);
          });
      }
    });

    // Send data
    worker.postMessage({
      action: EntitiesWorkerNS.WorkerAction.DATA,
      data: {
        limits,
        data: copiedEntities,
      },
    });

    graph.forEachNode((node) => {
      const entity = entities.find((e) => e.entity.id === parseInt(node.toString()));

      if (!entity) {
        graph.dropNode(node);
      }
    });
  });
};

export const addEntitiesRefs = (entities: SearchResponse[], graph: Graph): Promise<void> => {
  return new Promise<void>((resolve) => {
    // convert the proxy object (returned by vuex and looks like a plain object :/ ) to a plain object for cloning to the worker
    const copiedEntities = JSON.parse(JSON.stringify(entities)) as SearchResponse[];

    const worker = new EntitiesRefsWorker<
      EntitiesRefsWorkerNS.WorkerMessage,
      EntitiesRefsWorkerNS.ClientMessage
    >();

    graph.clearEdges();

    worker.addEventListener('message', (event) => {
      switch (event.data.type) {
        case EntitiesRefsWorkerNS.MessageType.FINISHED: {
          worker.terminate();
          resolve();
          break;
        }
        case EntitiesRefsWorkerNS.MessageType.DATA: {
          const edgeSet = event.data.data.reduce((prev, e) => {
            if (graph.hasNode(e.source) && graph.hasNode(e.target)) {
              prev.add({
                ...e,
                attributes: {
                  ...e.attributes,
                  important: graph.getNodeAttribute(e.target, 'citationCount') > 200,
                },
              });
            }
            return prev;
          }, new Set<SerializedEdge>());
          edgeSet.forEach((edge) => graph.mergeEdge(edge.source, edge.target, edge.attributes));
        }
      }
    });

    // Send data
    worker.postMessage({
      action: EntitiesRefsWorkerNS.WorkerAction.DATA,
      data: copiedEntities,
    });
  });
};

export const addAnalysis = (analysis: AnalysisResponse, graph: Graph): Promise<void> => {
  return new Promise<void>((resolve) => {
    // convert the proxy object (returned by vuex and looks like a plain object :/ ) to a plain object for cloning to the worker
    const copiedAnalysis = JSON.parse(JSON.stringify(analysis)) as AnalysisResponse;

    graph.clearEdges();

    const worker = new AnalysisWorker<
      AnalysisWorkerNS.WorkerMessage,
      AnalysisWorkerNS.ClientMessage
    >();

    worker.addEventListener('message', (event) => {
      switch (event.data.type) {
        case AnalysisWorkerNS.MessageType.FINISHED:
          worker.terminate();
          resolve();
          break;
        case AnalysisWorkerNS.MessageType.DATA:
          if (event.data.data.type === 'nodes') {
            const nodes = event.data.data.data.reduce((prev, { parsed, original }) => {
              if (!graph.hasNode(parsed.key)) {
                parsed.attributes = {
                  ...parsed.attributes,
                  x: Math.random(),
                  y: Math.random(),
                  size: 4,
                  label: original.displayName,
                  type: original.type === 'author' ? NodeType.TRIANGLE : NodeType.CIRCLE,
                  color: '#c2c2c2',
                };
              }
              return [...prev, parsed];
            }, [] as SerializedNode[]);
            nodes.forEach((node) => graph.mergeNode(node.key, node.attributes));
          } else if (event.data.data.type === 'edges') {
            const edgeSet = event.data.data.data.reduce((prev, { parsed }) => {
              if (graph.hasNode(parsed.source) && graph.hasNode(parsed.target)) {
                prev.add({
                  ...parsed,
                  attributes: {
                    ...parsed.attributes,
                    important:
                      graph.getNodeAttribute(parsed.source, 'citationCount') > 200 ||
                      graph.getNodeAttribute(parsed.target, 'citationCount') > 200,
                  },
                });
              }
              return prev;
            }, new Set<SerializedEdge>());
            edgeSet.forEach((edge) => graph.mergeEdge(edge.source, edge.target, edge.attributes));
          }
      }
    });

    // Send data
    worker.postMessage({
      action: AnalysisWorkerNS.WorkerAction.DATA,
      data: {
        data: copiedAnalysis,
      },
    });
  });
};

export const getClusters = (analysis: AnalysisResponse, entityCount: number): ClusterMapping => {
  return analysis.communities.length === 0
    ? {
        [-1]: { name: 'Unassigned', keywords: [], size: entityCount },
      }
    : analysis.communities.reduce(
        (prev, { id, displayName, properties: { keywords, size } }) => {
          if (size > 1) {
            prev[parseInt(id)] = {
              name: displayName,
              keywords: keywords?.sort((a, b) => b[1] - a[1])?.map((el) => el[0]) ?? [],
              size,
            };
            prev[-1].size = prev[-1].size - size;
          }
          return prev;
        },
        {
          [-1]: { name: 'Unassigned', keywords: [], size: entityCount },
        } as ClusterMapping // ? Using negative ids for not existing clusters
      );
};
