//import { SearchState } from './search';
import { ToastSeverity } from '@/models/Toaster/index';
import { core } from '@/api';
import {
  AnalysisParameters,
  AnalysisResponse,
  AnalysisType,
  AuthorAnalysisType,
  PublicationAnalysisType,
  SearchResponse,
} from '@/models/Search';
import { RootState } from '@/store';
import { Module } from 'vuex';
import { v4 } from 'uuid';

// State
export interface SearchState {
  entities: SearchResponse[];
  currentAnalysis: AnalysisType;
  analysis: { [key in AnalysisType]?: AnalysisResponse };
  selectedEntityIds: number[];
  hoverEntityIds: number[];
  filterEntityIds: number[];
  error?: string;
  loading: boolean;
}

enum SearchAction {
  FETCH_SEARCH = 'FETCH_SEARCH',
  RESET_SEARCH = 'RESET_SEARCH',
  SET_ERROR = 'SET_ERROR',
  SET_SELECTED_ENTITY_IDS = 'SET_SELECTED_ENTITY_IDS',
  SET_HOVER_ENTITY_IDS = 'SET_HOVER_ENTITY_IDS',
  SET_FILTER_ENTITY_IDS = 'SET_FILTER_ENTITY_IDS',
  FETCH_ANALYSIS = 'FETCH_ANALYSIS',
  RESET_ANALYSIS = 'RESET_ANALYSIS',
  SWITCH_ANALYSIS = 'SWITCH_ANALYSIS',
  SET_LOADING = 'SET_LOADING',
}

export const search: Module<SearchState, RootState> = {
  namespaced: true,
  state: {
    entities: [],
    currentAnalysis: PublicationAnalysisType.HYBRIDFRONTS,
    analysis: {},
    selectedEntityIds: [],
    hoverEntityIds: [],
    filterEntityIds: [],
    error: undefined,
    loading: false,
  },
  getters: {
    entities(state: SearchState): SearchResponse[] {
      return state.entities;
    },
    currentAnalysis(state: SearchState): AnalysisType {
      return state.currentAnalysis;
    },
    analysis:
      (state: SearchState) =>
      (analysisType?: AnalysisType): AnalysisResponse | undefined => {
        return analysisType ? state.analysis[analysisType] : state.analysis[state.currentAnalysis];
      },
    selectedEntityIds(state: SearchState): number[] {
      return state.selectedEntityIds;
    },
    hoverEntityIds(state: SearchState): number[] {
      return state.hoverEntityIds;
    },
    filterEntityIds(state: SearchState): number[] {
      return state.filterEntityIds;
    },
    isLoading(state: SearchState): boolean {
      return state.loading;
    },
  },
  actions: {
    async fetch({ dispatch, commit, state }, { query }: { query: string }) {
      commit(SearchAction.SET_LOADING, true);
      commit(SearchAction.RESET_SEARCH);
      commit(SearchAction.RESET_ANALYSIS);
      try {
        const entities = await core.searchPublications({
          q: query,
          count: 500,
        });

        commit(SearchAction.FETCH_SEARCH, entities.results);

        commit(SearchAction.SET_LOADING, false);
        dispatch('fetchAnalysis', { type: state.currentAnalysis });
      } catch (e) {
        console.error(e);
        commit(SearchAction.SET_ERROR, 'Error while fetching result!');
        dispatch(
          'toaster/showToast',
          {
            severity: ToastSeverity.ERROR,
            message: 'Error while fetching result!',
          },
          { root: true }
        );
        commit(SearchAction.SET_LOADING, false);
      }
    },
    async fetchAnalysis(
      { dispatch, commit, state },
      { type, force }: { type: AnalysisType; force: boolean }
    ) {
      try {
        commit(SearchAction.SET_LOADING, true);
        const params: AnalysisParameters = { detectCommunities: true };

        switch (type) {
          case PublicationAnalysisType.HYBRIDFRONTS:
            params.hybridSimilarityRatio = 0.6;
            params.maxEdges = 10;
            params.expandGraph = false;
            break;
          case AuthorAnalysisType.COAUTHORNETWORK:
            break;
          default:
            params.expandGraph = false;
            break;
        }

        // Only re-fetch analysis if forced or empty (not loaded)
        if (force || !state.analysis[type]) {
          // We don't want to fetch citation networks from the server as parsing the refs in the FE is quicker
          if (type !== PublicationAnalysisType.CITATIONNETWORK) {
            if (state.entities.length >= 20) {
              const analysis = await core.analysis({
                analysisType: type,
                analysisParameters: params,
                entityIds: state.entities.map(({ entity }) => entity.id),
              });

              commit(SearchAction.FETCH_ANALYSIS, { type, results: analysis.results[0] });
            } else {
              dispatch('switchAnalysis', { type: PublicationAnalysisType.CITATIONNETWORK });
              commit(SearchAction.FETCH_ANALYSIS, { type });
            }
          } else {
            commit(SearchAction.FETCH_ANALYSIS, { type });
          }
        }
        commit(SearchAction.SET_LOADING, false);
      } catch (e) {
        console.error(e);
        commit(SearchAction.SET_ERROR, 'Error while fetching result!');
        dispatch(
          'toaster/showToast',
          {
            severity: ToastSeverity.ERROR,
            message: 'Error while fetching result!',
          },
          { root: true }
        );
        commit(SearchAction.SET_LOADING, false);
      }
    },
    selectEntities({ commit }, { ids }: { ids: number[] }) {
      commit(SearchAction.SET_SELECTED_ENTITY_IDS, ids);
    },
    filterEntities({ commit }, { ids }: { ids: number[] }) {
      commit(SearchAction.SET_FILTER_ENTITY_IDS, ids);
    },
    async switchAnalysis({ dispatch, commit, state }, { type }: { type: AnalysisType }) {
      if (!state.analysis[type]) {
        await dispatch('fetchAnalysis', { type });
      }

      commit(SearchAction.SWITCH_ANALYSIS, type);
    },
  },
  mutations: {
    [SearchAction.FETCH_SEARCH](state: SearchState, payload: SearchResponse[]) {
      state.error = undefined;
      state.entities = payload;
    },
    [SearchAction.RESET_SEARCH](state: SearchState) {
      state.error = undefined;
      state.entities = [];
    },
    [SearchAction.FETCH_ANALYSIS](
      state: SearchState,
      payload: { type: AnalysisType; results?: AnalysisResponse }
    ) {
      state.error = undefined;

      if (payload.results)
        payload.results.analysisType = payload.results.analysisType.toLowerCase() as AnalysisType;

      if (payload.type !== PublicationAnalysisType.CITATIONNETWORK) {
        state.analysis[payload.type] = payload.results;
      } else {
        state.analysis[payload.type] = {
          analysisId: v4(),
          analysisType: PublicationAnalysisType.CITATIONNETWORK,
          entities: [],
          links: [],
          communities: [],
        };
      }
    },
    [SearchAction.RESET_ANALYSIS](state: SearchState, payload?: { types?: AnalysisType[] }) {
      state.error = undefined;
      if (!payload?.types) {
        state.analysis = {};
      } else {
        payload.types.forEach((t) => (state.analysis[t] = undefined));
      }
    },
    [SearchAction.SET_ERROR](state: SearchState, payload: string) {
      state.entities = [];
      state.error = payload;
    },
    [SearchAction.SET_SELECTED_ENTITY_IDS](state: SearchState, ids: number[]) {
      state.selectedEntityIds = [...ids];
    },
    [SearchAction.SET_HOVER_ENTITY_IDS](state: SearchState, ids: number[]) {
      state.hoverEntityIds = [...ids];
    },
    [SearchAction.SET_FILTER_ENTITY_IDS](state: SearchState, ids: number[]) {
      state.filterEntityIds = [...ids];
    },
    [SearchAction.SWITCH_ANALYSIS](state: SearchState, type: AnalysisType) {
      state.currentAnalysis = type;
    },
    [SearchAction.SET_LOADING](state: SearchState, isLoading: boolean) {
      state.loading = isLoading;
    },
  },
};
