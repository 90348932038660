<template>
  <Dialog class="fixed inset-0 z-10" :open="open">
    <div class="flex items-center justify-center h-screen">
      <DialogOverlay class="fixed inset-0 bg-black opacity-30" />
      <div class="relative max-w-3xl max-h-2/3 mx-auto bg-white rounded-2xl p-8 flex items-center">
        <div class="mr-4">
          <ExclamationIcon class="h-20 text-red-600" />
        </div>
        <div class="flex flex-col">
          <DialogTitle class="font-medium text-lg leading-6 text-gray-800 flex-initial">
            {{ title }}
          </DialogTitle>
          <div class="mt-2" v-if="message && message.length > 0">
            <p class="text-base text-gray-500">
              {{ message }}
            </p>
          </div>
          <div class="mt-4">
            <button
              type="button"
              class="
                inline-flex
                justify-center
                px-4
                py-2
                text-sm
                font-medium
                text-red-900
                bg-red-200
                border border-transparent
                rounded-md
                hover:bg-red-300
                focus:outline-none
                focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500
                float-right
              "
              @click="$emit('update:open', false)"
            >
              {{ button && button.length > 0 ? button : 'Confirm' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue';
import { ExclamationIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'ErrorModal',
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    ExclamationIcon,
  },
  props: {
    open: Boolean,
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
    },
    button: {
      type: String,
      required: false,
    },
  },
  emits: ['update:open'],
});
</script>

<style></style>
