import { createStore } from 'vuex';
import { review } from './modules/review';
import { search, SearchState } from './modules/search';
import { toaster, ToasterState } from './modules/toaster';
import { explore, ExploreState } from './modules/explore';
import VuexPersistence from 'vuex-persist';
import { auth, AuthState } from './modules/auth';

export interface RootState {
  toaster?: ToasterState;
  search?: SearchState;
  explore?: ExploreState;
  auth?: AuthState;
}

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.sessionStorage,
  reducer: (state) => ({ explore: { entities: state.explore?.entities } }),
});

const isProduction = process.env.NODE_ENV === 'production';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    toaster: toaster,
    search: search,
    review: review,
    auth: auth,
    explore: explore,
  },
  strict: !isProduction,
  devtools: !isProduction,
  plugins: [vuexLocal.plugin],
});
