<template>
  <div>
    <AppSkeletonMenu query="">
      <template v-slot>
        <div class="w-full h-full bg-gray-50">
          <div
            class="absolute inset-x-1/3 top-16 w-1/3 h-1/3 bg-white border border-gray-300 rounded"
          >
            <div v-if="displayedInfo" class="m-6">
              <div class="pb-6 text-left text-2xl text-gray-900">
                {{ displayedInfo.firstName }} {{ displayedInfo.lastName }}
              </div>
              <div class="text-left text-xl font-light text-gray-500">
                <span class="font-normal">Username: </span> {{ displayedInfo.username }}
              </div>
              <div class="text-left text-xl font-light text-gray-500">
                <span class="font-normal">Email: </span> {{ displayedInfo.email }}
              </div>
              <button
                class="
                  p-1
                  px-2
                  my-6
                  float-left
                  text-gray-900
                  bg-gray-300
                  rounded-md
                  hover:text-white
                  focus:outline-none
                "
              >
                <a href="https://auth.ambalytics.com/auth/realms/ambalytics/account/#/"
                  >Change User Information here</a
                >
              </button>
            </div>
            <div v-else class="m-6 text-light font-light text-xl text-center">
              Profile could not be loaded
            </div>
            <LogoutButton class="absolute bottom-0 right-0 m-6" />
          </div>
        </div>
      </template>
    </AppSkeletonMenu>
    <LoginPopUp v-if="!isLoggedIn" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, Ref, ref, watch } from 'vue';
import AppSkeletonMenu from '@/components/Skeleton/AppSkeletonMenu.vue';
import LogoutButton from '@/components/Buttons/LogoutButton.vue';
import LoginPopUp from '@/components/PopUps/LoginPopUp.vue';
import { useStore } from 'vuex';
import { KeycloakProfile } from 'keycloak-js';

export default defineComponent({
  name: 'Profile',
  components: {
    AppSkeletonMenu,
    LogoutButton,
    LoginPopUp,
  },
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
    const profileInfo = computed(() => store.getters['auth/getUser'] as KeycloakProfile);
    const displayedInfo: Ref<KeycloakProfile | null> = ref(profileInfo.value);
    onBeforeMount(() => {
      if (isLoggedIn.value) store.dispatch('auth/loadUser');
    });
    watch(isLoggedIn, (newIsLoggedIn, oldIsLoggedIn) => {
      if (newIsLoggedIn && !oldIsLoggedIn) store.dispatch('auth/loadUser');
    });
    watch(
      profileInfo,
      (newProfileInfo: KeycloakProfile) => {
        displayedInfo.value = newProfileInfo;
      },
      { immediate: true }
    );
    return { isLoggedIn, displayedInfo };
  },
});
</script>
