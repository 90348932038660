
import PanelMenu from 'primevue/panelmenu';
import { PrimeIcons } from 'primevue/api';

import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'Menu',
  components: {
    PanelMenu,
  },
  setup() {
    const items = ref([
      {
        label: 'Home',
        icon: PrimeIcons.HOME,
        to: { name: 'Home' },
      },
      {
        label: 'Explore',
        icon: PrimeIcons.SEARCH,
      },
      {
        label: 'Library',
        items: [
          {
            label: 'My Library',
            icon: PrimeIcons.LIST,
            to: { name: 'MyLibrary' },
          },
          {
            label: 'Collections',
            icon: PrimeIcons.TAGS,
            to: { name: 'Collections' },
          },
        ],
      },
      {
        label: 'Reviews',
        icon: PrimeIcons.BOOK,
        to: { name: 'Reviews' },
      },
      {
        label: 'Reports',
        icon: PrimeIcons.CHART_BAR,
        to: { name: 'Reports' },
      },
    ]);
    return { items };
  },
});
