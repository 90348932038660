<template>
  <div class="rounded-lg shadow-md">
    <ControlButton
      v-for="(control, index) in controls"
      class="shadow-none"
      :class="{ 'rounded-t-none': index !== 0, 'rounded-b-none': index !== controls.length - 1 }"
      :key="control.key"
      :description="control.description"
      @click="$emit('click', control.key)"
      :icon="control.icon"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, VNode } from 'vue';
import ControlButton from '@/components/Graphs/GraphControls/ControlButton.vue';

interface Control {
  key: string;
  description: string;
  icon: VNode;
}

export default defineComponent({
  name: 'ControlButtonGroup',
  components: {
    ControlButton,
  },
  props: {
    controls: {
      type: Array as PropType<Array<Control>>,
      required: true,
    },
  },
});
</script>

<style></style>
