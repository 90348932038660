<template>
  <div class="w-full h-full bg-white overflow-auto">
    <button class="absolute right-0 m-3 top-0 mr-5 focus:outline-none">
      <XIcon
        class="h-7 w-7 p-1 rounded-full bg-white hover:text-yellow-400"
        @click="$emit('close')"
      />
    </button>
    <div
      v-if="hasFetchFailed"
      class="
        absolute
        text-center
        m-3
        mr-5
        p-5
        inset-1/3
        w-1/3
        h-20
        border-l-4 border-red-500
        bg-red-200
        rounded-lg
        text-xl text-red-500
        font-semibold
      "
    >
      <ExclamationCircleIcon class="h-8 w-8 inline-blog float-left" />
      Requested content could not be retrieved.
    </div>
    <div
      v-if="isLoading"
      class="
        absolute
        text-center
        m-3
        mr-5
        p-5
        inset-1/3
        w-1/3
        h-20
        bg-white
        rounded
        bg-opacity-75
        text-xl text-gray-700
        font-semibold
      "
    >
      Loading...
    </div>

    <div>
      <PublicationPage
        v-if="isPublication && currentSearchres"
        :searchres="searchres"
        @loading="loading"
        @stopLoading="stopLoading"
        @close="$emit('close')"
        @fetchFailed="fetchFailed"
        @viewNewDetail="viewNewDetail"
      />
    </div>
    <div>
      <PublicationPage
        v-if="isPublication && currentId"
        :id="currentId"
        @loading="loading"
        @stopLoading="stopLoading"
        @close="$emit('close')"
        @fetchFailed="fetchFailed"
        @viewNewDetail="viewNewDetail"
      />
    </div>
    <div>
      <AuthorPage
        v-if="isAuthor"
        :id="currentId"
        @loading="loading"
        @stopLoading="stopLoading"
        @close="$emit('close')"
        @fetchFailed="fetchFailed"
        @viewNewDetail="viewNewDetail"
      />
    </div>
    <div>
      <AffiliationPage
        v-if="isAffiliation"
        :id="currentId"
        @loading="loading"
        @stopLoading="stopLoading"
        @close="$emit('close')"
        @fetchFailed="fetchFailed"
        @viewNewDetail="viewNewDetail"
      />
    </div>
    <div>
      <FieldOfStudyPage
        v-if="isFieldOfStudy"
        :id="currentId"
        @loading="loading"
        @stopLoading="stopLoading"
        @close="$emit('close')"
        @fetchFailed="fetchFailed"
        @viewNewDetail="viewNewDetail"
      />
    </div>
  </div>
</template>

<script lang="ts">
import PublicationPage from '@/components/Details/PublicationPage.vue';
import FieldOfStudyPage from '@/components/Details/FieldOfStudyPage.vue';
import AuthorPage from '@/components/Details/AuthorPage.vue';
import AffiliationPage from '@/components/Details/AffiliationPage.vue';
import { defineComponent, PropType, ref, watch } from 'vue';
import { SearchResponse } from '@/models/Search';
import { XIcon, ExclamationCircleIcon } from '@heroicons/vue/solid';
import { DetailType } from '@/models/Details';

export default defineComponent({
  // Container for DetailPages for Search(publications, authors, ...)
  // TODO for state in menu/details the pages need to be reachable by URL
  name: 'Details',
  components: {
    PublicationPage,
    AuthorPage,
    AffiliationPage,
    FieldOfStudyPage,
    XIcon,
    ExclamationCircleIcon,
  },
  props: {
    searchres: {
      type: Object as PropType<SearchResponse>,
      required: false,
    },
    content: {
      type: Object as PropType<{
        id: number;
        type: DetailType;
      }>,
      required: false,
    },
  },
  setup(props) {
    // provides 'Loading' Element while the content of the component (PublicationPage, AuthorPage... is built)
    const isLoading = ref(true);
    const isPublication = ref(false);
    const isAuthor = ref(false);
    const isAffiliation = ref(false);
    const isFieldOfStudy = ref(false);
    const hasFetchFailed = ref(false);
    const currentId = ref(props.content?.id);
    const currentSearchres = ref(props.searchres);
    if (
      props &&
      (props.searchres != undefined ||
        (props.content && props.content.type == DetailType.PUBLICATION))
    ) {
      isPublication.value = true;
    }
    if (props.content && props.content.type == DetailType.AUTHOR) {
      isAuthor.value = true;
    }
    if (!props.content && !props.searchres) {
      isLoading.value = false;
      hasFetchFailed.value = true;
    }
    watch(
      () => props.searchres,
      (newVal, oldVal) => {
        if (newVal != oldVal) {
          isPublication.value = true;
          currentId.value = undefined;
          currentSearchres.value = newVal;
        }
        if (!newVal) hasFetchFailed.value = true;
      }
    );
    const loading = () => {
      isLoading.value = true;
      hasFetchFailed.value = false;
    };
    const stopLoading = () => {
      isLoading.value = false;
    };
    const fetchFailed = () => {
      isLoading.value = false;
      hasFetchFailed.value = true;
    };
    const viewNewDetail = (id: number, type: DetailType) => {
      console.log('viewNewDetail');
      if (type == DetailType.PUBLICATION) {
        currentId.value = id;
        currentSearchres.value = undefined;
        isPublication.value = true;
        isAuthor.value = false;
        isFieldOfStudy.value = false;
        isAffiliation.value = false;
      } else if (type == DetailType.AUTHOR) {
        currentId.value = id;
        isPublication.value = false;
        isAffiliation.value = false;
        isFieldOfStudy.value = false;
        isAuthor.value = true;
      } else if (type == DetailType.AFFILIATION) {
        currentId.value = id;
        isPublication.value = false;
        isAuthor.value = false;
        isFieldOfStudy.value = false;
        isAffiliation.value = true;
      } else if (type == DetailType.FIELDOFSTUDY) {
        console.log('view Field of Study');
        currentId.value = id;
        isPublication.value = false;
        isAuthor.value = false;
        isAffiliation.value = false;
        isFieldOfStudy.value = true;
      }
    };
    return {
      currentId,
      currentSearchres,
      isLoading,
      isPublication,
      isAuthor,
      isAffiliation,
      isFieldOfStudy,
      loading,
      stopLoading,
      fetchFailed,
      hasFetchFailed,
      viewNewDetail,
    };
  },
});
</script>
