<template>
  <div>
    <Menu>
      <MenuButton
        class="
          inline-block
          px-2
          py-1
          lg:w-24
          md:w-18
          lg:text-base
          md:text-sm
          sm:text-xs
          align-middle
          font-medium
          text-gray-900
          bg-gray-300
          rounded-md
          hover:text-white
          focus:outline-none
        "
      >
        Sort&nbsp;by<ChevronDownIcon class="h-5 w-5 align-middle inline-block" />
      </MenuButton>
      <button
        class="
          inline-block
          align-top
          lg:h-8 lg:w-8
          md:h-7 md:w-7
          sm:w-6 sm:h-6
          p-1
          mx-1
          font-medium
          text-gray-900
          bg-gray-300
          rounded-md
          hover:text-white
          focus:outline-none
        "
      >
        <SwitchVerticalIcon @click="$emit('reverseResultList')" />
      </button>
      <MenuItems>
        <MenuItem>
          <button
            class="
              mt-1
              block
              text-left
              px-2
              lg:w-24
              md:w-18
              sm:text-xs
              lg:text-base
              md:text-sm
              text-gray-900
              border border-gray-300
              rounded-md
              hover:bg-gray-300
              focus:outline-none
            "
            @click="$emit('sortByTitle')"
          >
            Title
          </button>
        </MenuItem>
        <MenuItem>
          <button
            class="
              mt-1
              block
              text-left
              px-2
              lg:w-24
              md:w-18
              lg:text-base
              md:text-sm
              sm:text-xs
              text-gray-900
              border border-gray-300
              rounded-md
              hover:bg-gray-300
              focus:outline-none
            "
            @click="$emit('sortByYear')"
          >
            Year
          </button>
        </MenuItem>
        <MenuItem>
          <button
            class="
              mt-1
              block
              text-left
              px-2
              lg:w-24
              md:w-18
              lg:text-base
              md:text-sm
              sm:text-xs
              text-gray-900
              border border-gray-300
              rounded-md
              hover:bg-gray-300
              focus:outline-none
            "
            @click="$emit('sortByPublisher')"
          >
            Publisher
          </button>
        </MenuItem>
        <MenuItem>
          <button
            class="
              mt-1
              block
              text-left
              px-2
              lg:w-24
              md:w-18
              lg:text-base
              md:text-sm
              sm:text-xs
              text-gray-900
              border border-gray-300
              rounded-md
              hover:bg-gray-300
              focus:outline-none
            "
            @click="$emit('sortByCitationCount')"
          >
            Citations
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ChevronDownIcon, SwitchVerticalIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';

export default defineComponent({
  name: 'SortByButton',
  components: {
    ChevronDownIcon,
    SwitchVerticalIcon,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
});
</script>
