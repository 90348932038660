
import { defineComponent } from 'vue';
import { ChevronDownIcon, SwitchVerticalIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';

export default defineComponent({
  name: 'SortByButton',
  components: {
    ChevronDownIcon,
    SwitchVerticalIcon,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
});
