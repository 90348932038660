<template>
  <div>
    <AppSkeletonMenu query="">
      <template v-slot>
        <div>
          Here a library for the user containing every saved entry (publication, author, ...) will
          be displayed
        </div>
      </template>
    </AppSkeletonMenu>
    <LoginPopUp v-if="!isLoggedIn" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import AppSkeletonMenu from '@/components/Skeleton/AppSkeletonMenu.vue';
import LoginPopUp from '@/components/PopUps/LoginPopUp.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'MyLibrary',
  components: {
    AppSkeletonMenu,
    LoginPopUp,
  },
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
    return { isLoggedIn };
  },
});
</script>
