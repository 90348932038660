
import { computed, defineComponent } from 'vue';
import DetailContainer from '@/components/Details/DetailContainer.vue';
import AppSkeletonMenu from '@/components/Skeleton/AppSkeletonMenu.vue';
import LoginPopUp from '@/components/PopUps/LoginPopUp.vue';
import { DetailType } from '@/models/Details';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Details',
  components: {
    AppSkeletonMenu,
    DetailContainer,
    LoginPopUp,
  },
  setup() {
    const content = { id: 3006354146, type: DetailType.PUBLICATION };
    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    return { content, isLoggedIn };
  },
});
