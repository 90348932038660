<template>
  <div>
    <Dialog
      header="Select Prior Knowledge"
      :visible="showDialog"
      closable="false"
      :style="{ width: '70vw', height: '80vh' }"
    >
      <p>
        In this step you are providing prior knowledge which is the basis for the active learning
        model to start. <br />
        You should at least select one relevant and one irrelevant record. You can select relevant
        publications by searching for keywords, authors or titles. For selecting irrelevant
        publications you can decide on publications which are randomly drawn from the dataset. If
        you don't have relevant publications on hand yet you can go for the randomized process as
        well and choose from there.
      </p>
      <!--Button
        label="Search"
        class="p-button-raised p-button-secondary"
        @click="searching4pub = !searching4pub"
      />
      <Button
        label="Select from Collection"
        class="p-button-raised p-button-secondary"
        @click="showingRandomized = !showingRandomized"
      / -->
      <TabView v-model:activeIndex="activeTab">
        <TabPanel header="Search"
          ><AmbaInput
            autocomplete="off"
            v-on:enter="onSearch"
            list="filterRecommendations"
            v-model="searchQuery"
            placeholder="Keyword, title, author"
          >
            <SearchIcon />
          </AmbaInput>
          <div v-if="publications.length > 0">
            <Listbox
              v-model="priorPublications"
              :options="publications"
              :multiple="true"
              listStyle="max-height:250px"
            >
              <template #option="slotProps">
                <div>
                  {{ slotProps.option.title }}
                </div>
              </template>
            </Listbox>
          </div>
        </TabPanel>
        <TabPanel header="Random">
          <b>Title:</b> {{ randomPublication.title }} <br />
          <b>Abstract:</b> {{ randomPublication.abstract }} <br />
        </TabPanel>
      </TabView>
      <template #footer>
        <Button
          label="Close"
          class="p-button-raised"
          icon="pi pi-check"
          @click="onClose"
          autofocus
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Listbox from 'primevue/listbox';

import AmbaInput from '@/components/Input/AmbaInput.vue';
import { SearchIcon } from '@heroicons/vue/solid';
import * as reviewservice from '@/api/reviewservice';

export default defineComponent({
  name: 'PriorKnowledgeDialog',
  components: {
    Dialog,
    Button,
    TabView,
    TabPanel,
    Listbox,
    AmbaInput,
    SearchIcon,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    showDialog: Boolean,
  },
  data() {
    return {
      publications: [],
      searchQuery: '',
      priorPublications: [],
      randomPublication: {},
      activeTab: 0,

      // showing boxes for each process of selecting
      searching4pub: false,
      showingRandomized: false,
    };
  },
  setup() {
    return {};
  },
  methods: {
    onClose() {
      this.$emit('hide');
    },
    /**onSearch() {
      reviewservice
        .searchDataSet(this.projectId, this.searchQuery)
        .then((response) => (this.publications = response));
    },*/
    onShowRandom() {
      this.fetchRandom();
    },
    fetchRandom() {
      reviewservice
        .getRandomPublication(this.projectId)
        .then((response) => (this.randomPublication = response));
    },
  },
  watch: {
    activeTab(oldT) {
      if (oldT == 1) {
        this.onShowRandom();
      }
    },
  },
});
</script>
