
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onUnmounted,
  PropType,
  ref,
  toRefs,
  watch,
} from 'vue';
import { WebGraph, NodeType, LabelSelector } from '@ambalytics/webgraph';
import Graph, { Attributes } from 'graphology-types';
import { DirectedGraph, UndirectedGraph } from 'graphology';
import {
  AnalysisResponse,
  AuthorAnalysisType,
  ClusterMapping,
  GraphDrawingLayout,
  PublicationAnalysisType,
  SearchResponse,
} from '@/models/Search';
import { useStore } from 'vuex';
import {
  SideMenu,
  SideMenuButton,
  SideMenuItems,
  SideMenuItem,
  SideMenuResize,
} from '@/components/Graphs/SideMenu';
import ClusterLegend from '@/components/Graphs/ClusterLegend.vue';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/vue/outline';
import { ActiveLoader, PluginApi as Loading } from 'vue-loading-overlay';
import Chart from 'primevue/chart';
import { ChartData } from 'chart.js';
import { color } from 'd3-color';
import { addAnalysis, addEntities, addEntitiesRefs } from '@/components/Graphs/utils/data';
import {
  getClusterColors,
  GraphViewEmitter,
  layoutConfigurations,
} from '@/components/Graphs/utils/graphConfiguration';
import { ToastSeverity } from '@/models/Toaster';
import PublicationInfoBox, { NodeInfoBox } from '@/components/Graphs/PublicationInfoBox.vue';

const offset = (attr: Attributes) => {
  const size = attr.size ?? 5;

  return {
    x: -5 - size,
    y: 5 + size,
  };
};

export default defineComponent({
  name: 'SearchGraph',
  props: {
    entities: {
      type: Object as PropType<SearchResponse[]>,
      required: true,
    },
    analysis: {
      type: Object as PropType<AnalysisResponse>,
      required: false,
    },
    backdropActive: {
      type: Boolean,
      default: false,
    },
    allEdges: {
      type: Boolean,
      default: false,
    },
    clusters: {
      type: Object as PropType<ClusterMapping>,
      default: () => ({}),
    },
    activeCluster: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    layout: {
      type: String as PropType<GraphDrawingLayout>,
      default: GraphDrawingLayout.FORCEATLAS2,
    },
    viewEventEmitter: {
      type: Object as PropType<GraphViewEmitter>,
      required: true,
    },
    selected: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    filtered: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    disableEdges: {
      type: Boolean,
      default: false,
    },
    disableBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SideMenu,
    SideMenuButton,
    SideMenuItems,
    SideMenuItem,
    SideMenuResize,
    ChevronRightIcon,
    ChevronLeftIcon,
    ClusterLegend,
    Chart,
    PublicationInfoBox,
  },
  emits: ['update:activeCluster', 'filter', 'clickNode', 'mouseleaveNode'],
  setup(props, { emit }) {
    const {
      entities,
      analysis,
      clusters,
      backdropActive,
      allEdges,
      layout,
      viewEventEmitter,
      selected,
      filtered,
      activeCluster,
      disableEdges,
      disableBackdrop,
    } = toRefs(props);

    const store = useStore();
    const app = getCurrentInstance();
    const loading = app?.appContext.config.globalProperties.$loading as Loading;
    const loader = ref<ActiveLoader | null>(null);
    const webGraphContainer = ref<null | HTMLDivElement>(null);

    const nodeLimits = ref<number[]>([]);

    const webGraph = ref<null | WebGraph>(null);
    const graph = ref<null | Graph>(null);
    const nodeInfoBox = ref<NodeInfoBox | null>(null);

    const isLegendOpen = ref(true);
    const isInitialLoad = ref(true);

    const clusterColors = computed(() =>
      getClusterColors(analysis.value?.analysisType, clusters.value)
    );

    const internalBackdropActive = ref(backdropActive.value);
    watch(
      () => backdropActive.value,
      (newValue) => (internalBackdropActive.value = newValue)
    );
    watch(
      () => clusterColors.value,
      (newValue) => {
        webGraph.value?.toggleNodeBackdropRendering(newValue, internalBackdropActive.value);
        webGraph.value?.camera.animate({});
      }
    );

    const showLoader = () => {
      if (!loader.value && webGraphContainer.value) {
        loader.value = loading.show({
          isFullPage: false,
          container: webGraphContainer.value,
        });
      }
    };
    const hideLoader = () => {
      loader.value?.hide();
      loader.value = null;
    };

    const initGraph = (container: HTMLElement, newGraph: Graph): WebGraph => {
      const sigmaSettings = {
        renderLabels: true,
        labelFontColor: '#8e8e8e',
        defaultEdgeType: 'line',
        renderJustImportantEdges: true,
      };

      const config = {
        highlightSubGraphOnHover: true,
        includeImportantNeighbors: false,
        importantNeighborsBidirectional: true,
        subGraphHighlightColor: '#ffc107',
        importantNeighborsColor: '#ff9800',
        defaultNodeType: NodeType.CIRCLE,
        labelSelector: LabelSelector.SIGMA,
        suppressContextMenu: true,
        showNodeInfoBoxOnClick: true,
        sigmaSettings: sigmaSettings,
      };

      const newWebGraph = new WebGraph(container, newGraph, config);

      newWebGraph.on('click', (event) => {
        const attr = graph.value?.exportNode(event.node);

        if (!attr) {
          store.dispatch('toaster/showToast', {
            severity: ToastSeverity.ERROR,
            message: 'Entity not found!',
          });
          return;
        }
        emit('clickNode', { ...event, node: attr });
        nodeInfoBox.value = null;
        const cluster = graph.value?.getNodeAttribute(event.node, 'cluster');
        emit(
          'update:activeCluster',
          typeof cluster !== 'undefined' ? cluster.toString() : undefined
        );
      });

      newWebGraph.on('mouseenter', ({ node, event }) => {
        const found = entities.value.find(({ entity }) => entity.id === parseInt(node.toString()));
        if (!found) return;
        const attr = graph.value?.getNodeAttributes(node);

        if (!attr) {
          store.dispatch('toaster/showToast', {
            severity: ToastSeverity.ERROR,
            message: 'Entity not found!',
          });
          return;
        }

        const { entity } = found;

        const nodeOffset = offset(attr);

        nodeInfoBox.value = {
          x: event?.x + nodeOffset.x,
          y: event?.y + nodeOffset.y,
          id: entity.id,
          title: entity.title,
          publisher: entity.publisher,
          year: entity.year,
          authors: entity.authors.map(({ name }) => name),
          citationCount: entity.citationCount,
          refs: entity.refs,
          doi: entity.doi,
        };

        const mouseLeaveListener = () => {
          nodeInfoBox.value = null;
          webGraph.value?.removeListener('mouseleave', mouseLeaveListener);
        };

        webGraph.value?.addListener('mouseleave', mouseLeaveListener);
      });

      newWebGraph.on('mouseleave', (event) => {
        const attr = graph.value?.exportNode(event.node);

        if (!attr) {
          store.dispatch('toaster/showToast', {
            severity: ToastSeverity.ERROR,
            message: 'Entity not found!',
          });
          return;
        }
        emit('mouseleaveNode', { ...event, node: attr });
      });

      newWebGraph.render();

      newWebGraph.camera.animatedUnzoom(1.1);

      return newWebGraph;
    };

    const destroy = () => {
      webGraph.value?.destroy();
    };

    onUnmounted(destroy);

    const applyEdges = async () => {
      if (!graph.value || !analysis.value || !webGraphContainer.value) return hideLoader();

      if (analysis.value?.analysisType === PublicationAnalysisType.CITATIONNETWORK) {
        await addEntitiesRefs(entities.value, graph.value);
      } else {
        webGraph.value?.toggleNodeBackdropRendering(clusterColors.value, false);
        if (analysis.value.analysisType === AuthorAnalysisType.COAUTHORNETWORK) {
          graph.value?.clear();
          graph.value?.clearEdges();
          webGraph.value?.destroy();

          graph.value = new UndirectedGraph();
          webGraph.value = initGraph(webGraphContainer.value, graph.value);
        }
        await addAnalysis(analysis.value, graph.value);

        webGraph.value?.toggleNodeBackdropRendering(
          clusterColors.value,
          internalBackdropActive.value
        );
      }

      webGraph.value?.toggleEdgeRendering(disableEdges.value);

      await webGraph.value?.setAndApplyLayout(
        layoutConfigurations[layout.value].layout,
        layoutConfigurations[layout.value].options
      );
    };

    watch(
      [entities, analysis, webGraphContainer],
      async (
        [newEntities, newAnalysis, newContainer],
        [oldEntities, oldAnalysis, oldContainer]
      ) => {
        try {
          if (!newContainer) return;
          showLoader();

          if (
            newEntities.length !== oldEntities.length ||
            !oldContainer ||
            (oldAnalysis?.analysisType === AuthorAnalysisType.COAUTHORNETWORK &&
              newAnalysis?.analysisType !== AuthorAnalysisType.COAUTHORNETWORK)
          ) {
            webGraph.value?.destroy();
            webGraph.value = null;
            graph.value?.clear();
            graph.value?.clearEdges();
            graph.value = null;

            if (newEntities.length === 0) return hideLoader();

            graph.value = new DirectedGraph();

            const newWebGraph = initGraph(newContainer, graph.value);
            webGraph.value = newWebGraph;

            nodeLimits.value = await addEntities(newEntities, graph.value);
          }

          if (graph.value?.order === newEntities.length) {
            await applyEdges();
          }

          hideLoader();

          if (isInitialLoad.value) {
            setTimeout(() => {
              isLegendOpen.value = false;
            }, 2000);
          }

          isInitialLoad.value = false;
        } catch (e) {
          console.error(e);
          store.dispatch('toaster/showToast', {
            severity: ToastSeverity.ERROR,
            message: e,
          });
        }
      }
    );

    watch(
      () => selected.value,
      ([newId], [oldId]) => {
        if (oldId) {
          webGraph.value?.unhighlightNode(oldId);
        }
        if (newId) {
          webGraph.value?.highlightNode(newId);
        }
      }
    );

    const unmount = () => {
      webGraphContainer.value = null;
    };

    onBeforeUnmount(unmount);

    watch(
      () => layout.value,
      async (newLayout) => {
        await webGraph.value?.setAndApplyLayout(
          layoutConfigurations[newLayout].layout,
          layoutConfigurations[newLayout].options
        );
      }
    );

    watch(
      () => internalBackdropActive.value,
      (newValue) => {
        webGraph.value?.toggleNodeBackdropRendering(clusterColors.value, newValue);
        webGraph.value?.camera.animate({});
      }
    );

    watch(
      () => allEdges.value,
      (newValue) => {
        webGraph.value?.toggleJustImportantEdgeRendering(!newValue);
      }
    );

    watch(
      () => filtered.value,
      (newIds) => {
        graph.value?.forEachNode((node) => {
          const attributes = graph.value?.getNodeAttributes(node) || {};
          graph.value?.mergeNode(node, {
            ...attributes,
            hidden: !newIds.includes(parseInt(node)),
          });
        });
      }
    );

    const hoverCluster = (id?: string) => {
      graph.value?.forEachNode((node) => {
        const attributes = graph.value?.getNodeAttributes(node) || {};
        const isFiltered =
          filtered.value.length === 0 ? false : !filtered.value.includes(parseInt(node));
        graph.value?.mergeNode(node, {
          ...attributes,
          hidden:
            isFiltered ||
            (id !== undefined ? parseInt(attributes.cluster) !== parseInt(id) : isFiltered),
        });
      });
    };

    const clickCluster = (id: string) => {
      if (id !== activeCluster.value) {
        const filterNodes = graph.value
          ?.nodes()
          .reduce(
            (prev, node) =>
              graph.value?.getNodeAttribute(node, 'cluster') === parseInt(id)
                ? [...prev, parseInt(node)]
                : prev,
            [] as number[]
          );

        emit('filter', filterNodes || []);
        emit('update:activeCluster', id);
      } else {
        emit('filter', graph.value?.nodes().map((n) => parseInt(n)) || []);
        emit('update:activeCluster', undefined);
      }
    };

    const clusterDistribution = computed(() =>
      Object.entries(clusters.value).reduce(
        (prev, [id, c]) => {
          const labels = prev.labels?.concat(c.name);
          const data = prev.datasets[0].data.concat(c.size);
          const backgroundColor = prev.datasets[0].backgroundColor as string[];
          const hoverBackgroundColor = prev.datasets[0].hoverBackgroundColor as string[];
          const clusterColor = clusterColors.value[parseInt(id)];
          return {
            labels,
            datasets: [
              {
                ...prev.datasets[0],
                data,
                backgroundColor: backgroundColor.concat(clusterColor),
                hoverBackgroundColor: hoverBackgroundColor.concat(
                  color(clusterColor)?.brighter(0.5).formatHex() ?? clusterColor
                ),
              },
            ],
          };
        },
        {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [] as string[],
              hoverBackgroundColor: [] as string[],
              borderColor: '#E8E8E8',
            },
          ],
        } as ChartData<'doughnut'>
      )
    );

    watch(
      () => disableBackdrop.value,
      (newDisableBackdrop) => {
        if (internalBackdropActive.value && newDisableBackdrop) {
          internalBackdropActive.value = false;
        }

        if (!internalBackdropActive.value && !newDisableBackdrop) {
          internalBackdropActive.value = true;
        }
      }
    );

    watch(
      () => analysis.value,
      async (newAnalysis, oldAnalysis) => {
        if (!newAnalysis || !oldAnalysis) return;

        if (!disableEdges.value && allEdges.value) {
          webGraph.value?.toggleJustImportantEdgeRendering(false);
        }
      }
    );

    viewEventEmitter.value.on('zoomIn', () => webGraph.value?.camera.animatedUnzoom(0.75));
    viewEventEmitter.value.on('reset', () =>
      webGraph.value?.camera.animate({ ratio: 1.1, x: 0.5, y: 0.5 })
    );
    viewEventEmitter.value.on('zoomOut', () => webGraph.value?.camera.animatedZoom(0.75));

    return {
      clusterColors,
      nodeLimits,
      webGraphContainer,
      hoverCluster,
      isLegendOpen,
      clusterDistribution,
      clickCluster,
      nodeInfoBox,
    };
  },
});
