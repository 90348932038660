
import { defineComponent } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Listbox from 'primevue/listbox';

import AmbaInput from '@/components/Input/AmbaInput.vue';
import { SearchIcon } from '@heroicons/vue/solid';
import * as reviewservice from '@/api/reviewservice';

export default defineComponent({
  name: 'PriorKnowledgeDialog',
  components: {
    Dialog,
    Button,
    TabView,
    TabPanel,
    Listbox,
    AmbaInput,
    SearchIcon,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    showDialog: Boolean,
  },
  data() {
    return {
      publications: [],
      searchQuery: '',
      priorPublications: [],
      randomPublication: {},
      activeTab: 0,

      // showing boxes for each process of selecting
      searching4pub: false,
      showingRandomized: false,
    };
  },
  setup() {
    return {};
  },
  methods: {
    onClose() {
      this.$emit('hide');
    },
    /**onSearch() {
      reviewservice
        .searchDataSet(this.projectId, this.searchQuery)
        .then((response) => (this.publications = response));
    },*/
    onShowRandom() {
      this.fetchRandom();
    },
    fetchRandom() {
      reviewservice
        .getRandomPublication(this.projectId)
        .then((response) => (this.randomPublication = response));
    },
  },
  watch: {
    activeTab(oldT) {
      if (oldT == 1) {
        this.onShowRandom();
      }
    },
  },
});
