<template>
  <div class="relative text-gray-400 focus-within:text-gray-600 bg-gray-50 rounded-md">
    <input
      type="search"
      name="search"
      class="w-full p-1 focus:outline-none text-lg text-gray-600 pl-12 bg-gray-50 rounded-md"
      :class="{ 'hide-clear': hideClear }"
      :placeholder="placeholder"
      autocomplete="on"
      @focus="$event.target.select()"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @keyup.enter="$emit('enter')"
      :list="list"
    />
    <span class="absolute inset-y-0 left-0 flex items-center pl-2 focus:outline-none">
      <button type="submit" class="p-1 w-8 h-8">
        <slot />
      </button>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AmbaInput',
  props: {
    modelValue: String,
    placeholder: String,
    hideClear: Boolean,
    list: String,
  },
});
</script>

<style lang="scss" scoped>
/* Hide the cancel button */
.hide-clear::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

::-webkit-search-cancel-button {
  cursor: pointer;
}

/* Hide the magnifying glass */
.hide-clear::-webkit-search-results-button {
  -webkit-appearance: none;
}

::-webkit-search-results-button {
  cursor: pointer;
}
</style>
