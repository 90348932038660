export interface Publication {
  id: number;
  type: PublicationType;
  doi: string;
  abstract: string;
  pubDate: string;
  publisher: string;
  citationCount: number;
  title: string;
  normalizedTitle: string;
  year: number;
  citations: { id: number; title: string }[];
  refs: { id: number; title: string }[];
  authors: { id: number; name: string }[];
  fieldsOfStudy: { id: number; name: string }[];
  bookTitle?: string;
  volume?: string;
  issue?: string;
  firstPage?: string;
  lastPage?: string;
}

export enum PublicationType {
  UNKNOWN = 'unknown',
  BOOK = 'book',
  BOOK_CHAPTER = 'book_chapter',
  CONFERENCE_PAPER = 'conference_paper',
  DATASET = 'dataset',
  JOURNAL_ARTICLE = 'journal_article',
  PATENT = 'patent',
  REPOSITORY = 'repository',
  BOOK_REFERENCE_ENTRY = 'book_reference_entry',
  THESIS = 'thesis',
}

export interface Author {
  id: number;
  name: string;
  normalizedName: string;
  pubCount: number;
  publications: Publication[];
  citationCount: number;
  affiliation: Affiliation;
}

export interface FieldOfStudy {
  id: number;
  score: number;
  name: string;
  normalizedName: string;
  level: number;
  citationCount: number;
  parents: FieldOfStudy[];
  children: FieldOfStudy[];
}

export interface Affiliation {
  id: number;
  name: string;
  normalizedName: string;
  pubCount: number;
  latitude: number;
  longitude: number;
  officialPageUrl: string;
  wikiPageUrl: string;
}

export interface ConferenceSeries {
  id: number;
  name: string;
  normalizedName: string;
  pubCount: number;
  citationCount: number;
}

export interface Journal {
  id: number;
  name: string;
  issn: string;
  pubCount: number;
  citationCount: number;
}

export const enum DetailType {
  PUBLICATION = 'Publication',
  AUTHOR = 'Author',
  PUBLISHER = 'Publisher',
  FIELDOFSTUDY = 'FieldOfStudy',
  AFFILIATION = 'Affiliation',
}
