
import { defineComponent, computed } from 'vue';
import ReviewList from '@/components/Review/ReviewList.vue';
import { useRouter } from 'vue-router';
import AppSkeletonMenu from '@/components/Skeleton/AppSkeletonMenu.vue';
import axios from 'axios';
import { asreviewproject, Review } from '@/models/Review';
import NewReview from '@/components/Review/NewReview.vue';
import { useStore } from 'vuex';
import LoginPopUp from '@/components/PopUps/LoginPopUp.vue';

export default defineComponent({
  name: 'Reviews',
  components: {
    AppSkeletonMenu,
    ReviewList,
    NewReview,
    LoginPopUp,
  },
  data() {
    return {
      projects: [] as Review[],
      addingProject: false,
    };
  },
  setup() {
    const router = useRouter();

    const openReview = (id: string) => {
      router.push({ name: 'Review', params: { projectId: id } });
    };

    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    return { openReview, isLoggedIn };
  },
  mounted() {
    axios.get('http://localhost:5000/api/projects').then((response) =>
      response.data.result.forEach((r: asreviewproject) => {
        var date = new Date(r.created_at_unix * 1000);
        var createdAt = date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear();

        this.$data.projects.push({
          id: r.id,
          name: r.name,
          date: createdAt,
          description: r.description,
          creatorId: 78977,
          creatorName: r.authors,
          contributors: 'none yet',
        } as unknown as Review);

        r.date = 'some time';
      })
    );
  },
});
