
import { computed, defineComponent, onMounted, ref } from 'vue';
import AmbaInput from '@/components/Input/AmbaInput.vue';
import { SearchIcon } from '@heroicons/vue/solid';
import { useRouter } from 'vue-router';
import Menu from '@/components/Navigation/Menu.vue';
import WelcomeButton from '@/components/Buttons/WelcomeButton.vue';
import MenuButton from '@/components/Buttons/MenuButton.vue';
import LoginButton from '@/components/Buttons/LoginButton.vue';
import ProfileButton from '@/components/Buttons/ProfileButton.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AppBar',
  components: {
    AmbaInput,
    SearchIcon,
    Menu,
    WelcomeButton,
    MenuButton,
    LoginButton,
    ProfileButton,
  },
  props: {
    query: String,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const searchInput = ref<string>();
    const menuVisible = ref(false);
    //router doesn't need to be watched since the component is destroyed and remounted everytime the route changes
    const isSearchView = ref(router.currentRoute.value.name === 'Search');

    const loggedIn = computed(() => store.getters['auth/isLoggedIn']);

    const search = (e: Event) => {
      e.preventDefault();
      if (searchInput.value === '') return;
      router.push({ name: 'Search', query: { q: searchInput.value } });
    };
    const toggleMenu = () => {
      menuVisible.value = !menuVisible.value;
    };

    onMounted(() => {
      searchInput.value = props.query;
    });

    return { searchInput, search, toggleMenu, menuVisible, loggedIn, isSearchView };
  },
});
