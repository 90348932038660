
import { defineComponent } from 'vue';
import StandardLogo from '@/components/Logos/StandardLogo.vue';

export default defineComponent({
  name: 'WelcomeButton',
  components: {
    StandardLogo,
  },
});
