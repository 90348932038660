import { Review, Publication, ReviewProgress } from '@/models/Review/index';
import axios from 'axios';

export interface ResponseWrapper<T> {
  modelVersion?: number;
  time: number;
  results: T;
}

interface ASRAlgorithms {
  balance_strategy: string;
  config_file: Record<string, unknown>;
  embedding_fp: Record<string, unknown>;
  feature_extraction: string;
  model: string;
  n_instances: number;
  query_strategy: string;
  seed: null;
}

const reviewservice = axios.create({
  baseURL: 'http://localhost:5000/api',
});

export const getReviews = async (): Promise<ResponseWrapper<Review[]>> => {
  const response = await reviewservice.get<ResponseWrapper<Review[]>>('/projects');
  return response.data;
};

export const createReview = async (
  title: string,
  author: string,
  description: string
): Promise<ResponseWrapper<Review>> => {
  const response = await reviewservice.post<ResponseWrapper<Review>>('/project/info', {
    params: { name: title, authors: author, description: description },
  });
  return response.data;
};

export const getReview = async (projectId: string): Promise<ResponseWrapper<Review>> => {
  const response = await reviewservice.get<ResponseWrapper<Review>>(
    '/project/' + projectId + '/info',
    {
      params: projectId,
    }
  );
  return response.data;
};

export const getProgress = async (projectId: string): Promise<ResponseWrapper<ReviewProgress>> => {
  const response = await reviewservice.get<ResponseWrapper<ReviewProgress>>(
    '/project/' + projectId + '/progress',
    {
      params: projectId,
    }
  );
  return response.data;
};

export const searchDataSet = (
  projectId: string,
  query: string
): Promise<ResponseWrapper<Publication[]>> => {
  return reviewservice.get('project/' + projectId + '/search', {
    params: { q: query, n_max: 10 },
  });
};

export const getRandomPublication = (projectId: string): Promise<ResponseWrapper<Publication>> => {
  return reviewservice.get('project/' + projectId + '/prior_random');
};

export const updateReview = async (projectId: string): Promise<ResponseWrapper<Review>> => {
  const response = await reviewservice.put<ResponseWrapper<Review>>('/project/' + projectId, {
    params: projectId,
  });
  return response.data;
};

export const getAlgorithms = async (projectId: string): Promise<ResponseWrapper<ASRAlgorithms>> => {
  const response = await reviewservice.put<ResponseWrapper<ASRAlgorithms>>(
    '/project/' + projectId + '/algorithms'
  );
  return response.data;
};

export const labelItem = async (
  projectId: string,
  pubId: number,
  label: boolean,
  isPrior: boolean
): Promise<ResponseWrapper<boolean>> => {
  const response = await reviewservice.post<ResponseWrapper<boolean>>(
    '/project/' + projectId + '/entries/' + pubId + 'label',
    {
      label: label,
      isPrior: isPrior,
    }
  );
  return response.data;
};
