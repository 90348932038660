<template>
  <div class="border rounded my-1 border-gray-300">
    <data-table
      :value="reviews"
      selectionMode="single"
      dataKey="id"
      responsiveLayout="scroll"
      v-model:selection="selectedReview"
      @rowSelect="onRowSelect"
    >
      <template #header>
        <h5 class="text-2xl text-gray-700">Reviews</h5>
      </template>
      <Column field="name" header="Title" class=""></Column>
      <Column field="creatorName" header="Created By" class=""></Column>
      <Column field="date" header="Date" class=""></Column>
      <Column field="description" header="Description" class=""></Column>
    </data-table>
  </div>
</template>

<script lang="ts">
import { ref, Ref, defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default defineComponent({
  name: 'Reviewlist',
  components: {
    DataTable,
    Column,
  },
  props: {
    reviews: {
      type: Array,
      default: function () {
        return [{ id: 0, name: 'Review One', date: 'gestern', description: 'hello' }];
      },
    },
  },
  setup(props, { emit }) {
    const selectedReview: Ref<{ id: string } | undefined> = ref();
    const onRowSelect = (event: { data: { id: string } }) => {
      openReview(event.data.id);
    };
    const openReview = (id: string) => {
      emit('selectReview', id);
    };
    return { onRowSelect, openReview, selectedReview };
  },
});
</script>
